import { call, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';
import api from 'src/services/authApi';
import {
  actions,
  REQUEST_CREATE_OUTPUT,
  REQUEST_DELETE_OUTPUT,
  REQUEST_GET_OUTPUTS,
  REQUEST_UPDATE_OUTPUT,
} from '../reducers/output';
import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { NOTIFY } from '../reducers/notification';

type OutputApiResponse = void | { data: Output; status: number };
function* createOutput(
  action: PayloadAction<{ data: Partial<Output>; navigate?: NavigateFunction }>
): Generator<CallEffect | PutEffect, void, OutputApiResponse> {
  try {
    const { navigate, data } = action.payload;
    const response = yield call(api.post, `/output`, data);

    if (response?.data) {
      yield put(actions.receiveCreateOutput());
      navigate && navigate('/settings/workspace/outputs');
      yield put({
        type: NOTIFY,
        payload: {
          variant: 'success',
          title: 'Output created',
        },
      });
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(
      actions.failedCreateOutput(((error as Error)?.message as string) || 'Something went wrong')
    );
  }
}

function* deleteOutput({
  payload,
}: PayloadAction<{ id: string }>): Generator<CallEffect | PutEffect, void> {
  try {
    const { id } = payload;
    const response = yield call(api.delete, `/output/${id}`);
    const apiResponse = response as OutputApiResponse;
    if (apiResponse?.status === 204) {
      yield put(actions.receiveDeleteOutput(id));

      yield put({
        type: NOTIFY,
        payload: {
          variant: 'info',
          title: 'Output deleted',
        },
      });
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(
      actions.failedDeleteOutput(((error as Error)?.message as string) || 'Something went wrong')
    );
  }
}

type OutputsApiResponse = void | { data: Output[]; status: number };
function* getOutputs(): Generator<
  PutEffect | CallEffect<OutputsApiResponse>,
  void,
  OutputsApiResponse
> {
  try {
    const response = yield call(api.get, `/output`);
    const apiResponse = response as OutputsApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetOutputs(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetOutputs(((error as Error)?.message as string) || 'Something went wrong')
    );
  }
}

function* updateOutput({
  payload,
}: PayloadAction<{ id: string; data: Partial<Output>; navigate?: NavigateFunction }>): Generator<
  CallEffect | PutEffect,
  void,
  OutputApiResponse
> {
  try {
    const { data, id, navigate } = payload;
    const response = yield call(api.patch, `/output/${id}`, data);

    if (response?.data) {
      yield put(actions.receiveUpdateOutput());
      yield put(actions.requestGetOutputs());

      navigate && navigate('/settings/workspace/outputs');
      yield put({
        type: NOTIFY,
        payload: {
          variant: 'success',
          title: 'Output updated',
        },
      });
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(
      actions.failedUpdateOutput(((error as Error)?.message as string) || 'Something went wrong')
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_OUTPUT, createOutput);
  yield takeLatest(REQUEST_DELETE_OUTPUT, deleteOutput);
  yield takeLatest(REQUEST_GET_OUTPUTS, getOutputs);
  yield takeLatest(REQUEST_UPDATE_OUTPUT, updateOutput);
}
