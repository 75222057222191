import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OutputStateType, OUTPUT } from './types';

const outputInitialState: OutputStateType = {
  collection: {
    data: null,
    loading: true, // start with loading state to avoid flash
    error: null,
  },
  detail: {
    data: null,
    loading: false,
    submitting: false,
    error: null,
  },
};

export const outputSlice = createSlice({
  name: OUTPUT,
  initialState: outputInitialState,
  // Apply state changes
  reducers: {
    requestCreateOutput: (output: OutputStateType) => {
      output.detail.submitting = true;
    },
    receiveCreateOutput: (output: OutputStateType) => {
      output.detail.submitting = false;
    },
    failedCreateOutput: (output: OutputStateType, { payload: error }: PayloadAction<string>) => {
      output.detail.error = error;
      output.detail.submitting = false;
    },
    requestDeleteOutput: (output: OutputStateType) => {
      output.collection.loading = true;
    },
    receiveDeleteOutput: (output: OutputStateType, { payload }: PayloadAction<string>) => {
      if (output.collection.data) {
        output.collection.data = output.collection.data.filter((output) => output.id !== payload);
      }
      output.collection.loading = false;
    },
    failedDeleteOutput: (output: OutputStateType, { payload: error }: PayloadAction<string>) => {
      output.collection.error = error;
      output.collection.loading = false;
    },
    requestGetOutputs: (output: OutputStateType) => {
      output.collection.loading = true;
    },
    receiveGetOutputs: (output: OutputStateType, { payload }: PayloadAction<Output[]>) => {
      output.collection.loading = false;
      output.collection.data = payload;
    },
    failedGetOutputs: (output: OutputStateType, { payload: error }: PayloadAction<string>) => {
      output.collection.loading = false;
      output.collection.error = error;
    },
    requestUpdateOutput: (output: OutputStateType) => {
      output.collection.loading = true;
    },
    receiveUpdateOutput: (output: OutputStateType) => {
      output.collection.loading = false;
    },
    failedUpdateOutput: (output: OutputStateType, { payload: error }: PayloadAction<string>) => {
      output.collection.error = error;
      output.collection.loading = false;
    },
  },
});

const actions = outputSlice.actions;
const outputReducer = outputSlice.reducer;

export { actions, outputReducer };
