import { FixturesResponse } from 'src/types/statsperform/fixtures';
import { MatchStatsResponse } from 'src/types/statsperform/matchStats';
import { StandingResponse } from 'src/types/statsperform/standings';
import { TournamentCalendarResponse } from 'src/types/statsperform/tournamentCalendar';
import { TournamentScheduleResponse } from 'src/types/statsperform/tournamentSchedule';

export type SportDataStateType = {
  tournamentCalendar: {
    data: TournamentCalendarResponse | null;
    loading: boolean;
    error: string | null;
  };
  standings: {
    data: {
      tournamentCalendarId: string | null;
      standingsData: StandingResponse | null;
    };
    loading: boolean;
    error: string | null;
  };
  schedule: {
    data: TournamentScheduleResponse | null;
    loading: boolean;
    error: string | null;
  };
  fixtures: {
    data: FixturesResponse | null;
    loading: boolean;
    error: string | null;
  };
  matchData: {
    data: {
      fixtureId: string | null;
      fixtureData: MatchStatsResponse | null;
    };
    loading: boolean;
    error: string | null;
  };
};

export const SPORT_DATA = 'sportData';
export type SPORT_DATA = typeof SPORT_DATA;

export const REQUEST_GET_SPORT_DATA_STANDINGS = `${SPORT_DATA}/requestStandings`;
export type REQUEST_GET_SPORT_DATA_STANDINGS = typeof REQUEST_GET_SPORT_DATA_STANDINGS;

export const REQUEST_GET_SPORT_DATA_SCHEDULE = `${SPORT_DATA}/requestSchedule`;
export type REQUEST_GET_SPORT_DATA_SCHEDULE = typeof REQUEST_GET_SPORT_DATA_SCHEDULE;

export const REQUEST_GET_SPORT_DATA_FIXTURES = `${SPORT_DATA}/requestFixtures`;
export type REQUEST_GET_SPORT_DATA_FIXTURES = typeof REQUEST_GET_SPORT_DATA_FIXTURES;

export const REQUEST_GET_SPORT_DATA_MATCH_STATS = `${SPORT_DATA}/requestMatchData`;
export type REQUEST_GET_SPORT_DATA_MATCH_STATS = typeof REQUEST_GET_SPORT_DATA_MATCH_STATS;

export const REQUEST_GET_SPORT_DATA_TOURNAMENT_CALENDAR = `${SPORT_DATA}/requestTournamentCalendar`;
export type REQUEST_GET_SPORT_DATA_TOURNAMENT_CALENDAR =
  typeof REQUEST_GET_SPORT_DATA_TOURNAMENT_CALENDAR;
