import { ChangeEvent } from 'react';
import { FormGroup, Input } from '@southfields-digital/mpxlive-components';

import styles from './MatchStats.module.scss';

type FixtureInputProps = {
  fixtureId: string;
  onChange: (value: string) => void;
};

const FixtureInput = ({ fixtureId, onChange }: FixtureInputProps) => (
  <FormGroup label="Match Id" htmlFor="matchId">
    <Input
      id="matchId"
      value={fixtureId}
      classNameWrapper={styles.InputWrapper}
      placeholder="Statsperform Match Id"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
    />
  </FormGroup>
);

export default FixtureInput;
