import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest } from 'redux-saga/effects';
import { REQUEST_NOTIFY_GO_LIVE_RUNDOWN } from '../reducers/desktopApp';

function* notifyGoLiveRundown(
  action: PayloadAction<{ data: Rundown }>
): Generator<void, void, void> {
  const rundown = action.payload.data;

  const outputUrls = rundown.canvases.map((canvas) => {
    return {
      name: canvas.output?.name || '',
      url: canvas.output?.liveUrl || '',
    };
  });

  const apiWindow = window as unknown as Window & {
    mxmzDesktopApi: {
      notifyOutputUrls: (outputUrls: { name: string; url: string }[]) => void;
      notifyGoLiveRundown: (rundown: Rundown) => void;
    };
  };

  if (apiWindow.mxmzDesktopApi && apiWindow.mxmzDesktopApi.notifyOutputUrls) {
    apiWindow.mxmzDesktopApi.notifyOutputUrls(outputUrls);
  }

  if (apiWindow.mxmzDesktopApi && apiWindow.mxmzDesktopApi.notifyGoLiveRundown) {
    apiWindow.mxmzDesktopApi.notifyGoLiveRundown(rundown);
  }
}

export default function* root() {
  yield takeLatest(REQUEST_NOTIFY_GO_LIVE_RUNDOWN, notifyGoLiveRundown);
}
