import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router';
import { Params } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import { REQUEST_GET_CANVASES, REQUEST_GET_CANVAS_BACKGROUNDS } from 'src/redux/reducers/canvas';
import {
  REQUEST_CREATE_RUNDOWN_TEMPLATE,
  REQUEST_GET_RUNDOWN_TEMPLATE,
  REQUEST_UPDATE_RUNDOWN_TEMPLATE,
} from 'src/redux/reducers/rundownTemplate';
import { REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID } from 'src/redux/reducers/graphic';
import { REQUEST_GET_GRAPHIC_PACKAGES } from 'src/redux/reducers/graphicPackage';
import RundownTemplateForm from 'src/pages/Settings/RundownTemplates/Form';
import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_MATCH_CONTROL_TYPES } from 'src/redux/reducers/matchControls';

const mapStateToProps = (state: StateType, { params }: { params?: Readonly<Params<string>> }) => ({
  rundownTemplateId: params?.rundownTemplateId,
  rundownTemplateData: state.rundownTemplate.detail.data || {},
  graphicPackagesData: state.graphicPackage.collection.data || [],
  matchControlTypesData: state.matchControls.categories.data || [],
  canvasesData: state.canvas.collection.data || [],
  graphicsData: state.graphic.allFromPackage.data || [],
  canvasBackgroundsData: state.canvas.backgroundCollection.data || [],
  submitting: state.rundownTemplate.edit.submitting,
  loadingRundownTemplateData: state.rundownTemplate.detail.loading,
  error: state.rundownTemplate.detail.error,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params }: { params?: Readonly<Params<string>> }
) => ({
  handleSubmit: (payload: Partial<RundownTemplate>, navigate?: NavigateFunction) => {
    dispatch({
      type: params?.rundownTemplateId
        ? REQUEST_UPDATE_RUNDOWN_TEMPLATE
        : REQUEST_CREATE_RUNDOWN_TEMPLATE,
      payload: { data: payload, navigate: navigate },
    });
  },
  getCanvases: () => dispatch({ type: REQUEST_GET_CANVASES }),
  getCanvasBackgrounds: () => dispatch({ type: REQUEST_GET_CANVAS_BACKGROUNDS }),
  getGraphicPackages: (deprecated: boolean) =>
    dispatch({ type: REQUEST_GET_GRAPHIC_PACKAGES, payload: { deprecated } }),
  getGraphicsByGraphicPackageId: (graphicPackageId: string) =>
    dispatch({
      type: REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID,
      payload: { graphicPackageId },
    }),
  getRundownTemplate: () =>
    dispatch({
      type: REQUEST_GET_RUNDOWN_TEMPLATE,
      payload: { id: params?.rundownTemplateId },
    }),
  getMatchControlTypes: () => dispatch({ type: REQUEST_GET_MATCH_CONTROL_TYPES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RundownTemplateForm);
