import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GraphicPackagesResponseType, GraphicPackageStateType, GRAPHIC_PACKAGE } from './types';

const graphicPackageInitialState: GraphicPackageStateType = {
  collection: {
    data: null,
    loading: true, // start with loading state to avoid flash
    error: null,
  },
};

export const graphicPackageSlice = createSlice({
  name: GRAPHIC_PACKAGE,
  initialState: graphicPackageInitialState,
  // Apply state changes
  reducers: {
    requestGetGraphicPackages: (graphicPackage: GraphicPackageStateType) => {
      graphicPackage.collection.loading = true;
    },
    receiveGetGraphicPackages: (
      graphicPackage: GraphicPackageStateType,
      { payload }: PayloadAction<GraphicPackagesResponseType>
    ) => {
      graphicPackage.collection.loading = false;
      graphicPackage.collection.data = payload;
    },
    failedGetGraphicPackages: (
      graphicPackage: GraphicPackageStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      graphicPackage.collection.loading = false;
      graphicPackage.collection.error = error;
    },
  },
});

const actions = graphicPackageSlice.actions;
const graphicPackageReducer = graphicPackageSlice.reducer;

export { actions, graphicPackageReducer };
