import { useState } from 'react';
import { FormGroup, Input } from '@southfields-digital/mpxlive-components';
import styles from './InputTimer.module.scss';

const TimerInput = ({
  id,
  name,
  value,
  onChange,
}: {
  id: string;
  name: string;
  value: number;
  onChange: (value: number) => void;
}) => {
  const timerInputValue = value ? value / 1000 : 0;

  const [minutes, setMinutes] = useState(Math.floor(timerInputValue / 60));
  const [seconds, setSeconds] = useState(timerInputValue % 60);

  const syncInputs = () => {
    setMinutes(Math.floor(timerInputValue / 60));
    setSeconds(timerInputValue % 60);
  };

  return (
    <FormGroup
      label={name}
      key={id}
      className={styles.TimerWrapperGroup}
      inputClassName={styles.TimerWrapper}
    >
      <Input
        key={`${id}-minutes`}
        type="number"
        id={`${id}-minutes`}
        name={`${id}-minutes`}
        label="Minutes"
        value={minutes}
        onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
          setMinutes(parseFloat(e.currentTarget.value) || 0);
          onChange(parseFloat(e.currentTarget.value) * 60000 + seconds * 1000 || 0);
        }}
        onBlur={syncInputs}
        classNameWrapper={styles.ElementWrapper}
      />
      <Input
        key={`${id}-seconds`}
        type="number"
        id={`${id}-seconds`}
        name={`${id}-seconds`}
        label="Seconds"
        value={seconds}
        onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
          setSeconds(parseFloat(e.currentTarget.value) || 0);
          onChange(minutes * 6000 + parseFloat(e.currentTarget.value) * 1000 || 0);
        }}
        onBlur={syncInputs}
        classNameWrapper={styles.ElementWrapper}
      />
    </FormGroup>
  );
};

export default TimerInput;
