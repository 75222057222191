import { Provider } from 'react-redux';
import { routeMap } from 'src/config/routes';
import store from 'src/config/store';

import { ToastContainer } from '@southfields-digital/mpxlive-components';
import { Routes } from 'src/components/Routes';

import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary.tsx';
import InternalServerError from 'src/pages/InternalServerError/InternalServerError';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={InternalServerError}>
        <Routes routeMap={routeMap} />
        <ToastContainer autoClose={3000} position="bottom-right" hideProgressBar newestOnTop />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
