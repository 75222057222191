type Score = SoccerScore | BasketballScore | TennisScore | BaseScore;

const TENNIS_POINTS = ['0', '15', '30', '40', 'AD'] as const;
type TennisPoint = (typeof TENNIS_POINTS)[number];

type BaseScore = {
  points: number;
};
type SoccerScore = BaseScore;
type BasketballScore = BaseScore;
type HockeyScore = BaseScore;

type TennisScore = {
  id: string;
  currentScore: {
    contestant1: TennisPoint;
    contestant2: TennisPoint;
  };
  games: {
    contestant1: number;
    contestant2: number;
  };
  tiebreak: {
    contestant1: number;
    contestant2: number;
  };
  isTiebreak: boolean;
};

type VolleyballScore = {
  id: string;
  games: {
    contestant1: number;
    contestant2: number;
  };
  winner?: 'contestant1' | 'contestant2';
};

export type {
  Score,
  SoccerScore,
  BasketballScore,
  TennisScore,
  VolleyballScore,
  BaseScore,
  HockeyScore,
  TennisPoint,
};

export { TENNIS_POINTS };
