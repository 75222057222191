const updatePlaylistGraphics = (update: PlaylistGraphic[], current: PlaylistGraphic[] | null) => {
  if (!current) return current;
  const result = current.map((graphic) => {
    const updatedGraphic = update.find((g) => g.id === graphic.id);
    if (updatedGraphic) {
      return updatedGraphic;
    }
    return graphic;
  });

  return result;
};

export { updatePlaylistGraphics };
