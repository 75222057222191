import { toDatetimeLocal } from 'src/utils';

import { Card, Icon, Text } from '@southfields-digital/mpxlive-components';
import LivePulse from 'src/components/LivePulse';

import styles from '../RundownCalendar.module.scss';

type Props = {
  date: Date;
  rundown: Rundown;
  handleClickDelete: () => void;
  handleClickCard: () => void;
};

const RundownCard = ({ rundown, date, handleClickDelete, handleClickCard }: Props) => {
  return (
    <div onClick={handleClickCard} className={styles.Wrapper}>
      <Card
        headerContent={rundown.name}
        className={styles.Card}
        popoverPosition={date.getDay() === 0 ? 'right' : 'left'}
        actionItems={[
          {
            to: `/rundowns/edit/${rundown.id}`,
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
            },
            value: 'edit',
          },
          {
            as: 'button',
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              e.preventDefault();
              handleClickDelete();
            },
            value: 'delete',
          },
        ]}
      >
        <div className={styles.Content}>
          {rundown.isLocked && (
            <div className={styles.StatusRow}>
              <Text as="span">Rundown is in use</Text>
              <Icon icon="x-circle" />
            </div>
          )}
          <div className={styles.StatusRow}>
            <Text as="span">{toDatetimeLocal(rundown.start).time.slice(0, 5)}</Text>
            {rundown.isLive && <LivePulse />}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RundownCard;
