import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { Text } from '@southfields-digital/mpxlive-components';
import { getFormattedTime } from '../utils';
import { useTimer } from '../hooks';
import { Control } from 'src/types/matchControl/control';
import { Clock } from 'src/types/matchControl/clock';
import type { MatchControlVariant, Precision } from '../types';

import MatchControlIcon from './MatchControlIcon';
import MatchControlButton from './MatchControlButton';
import ClockStart from './ClockStart';
import ClockIn from './ClockIn';

import styles from '../MatchControl.module.scss';

const MAIN_CLOCK_ID = 'clock';

type Props = {
  clock: Clock;
  matchControlGraphics: Control[];
  handleDispatchControl: (control?: Control) => void;
  handleStartTime: (clock: Clock) => void;
  handleStopTime: (clock: Clock) => void;
  extraTime?: number;
  showExtraTime?: boolean;
  showControls?: boolean;
  disabled?: boolean;
  precision?: Precision;
  handleChangeTime?: (time: number) => void;
  variant?: MatchControlVariant;
};

const ClockDisplay = ({
  variant = 'default',
  clock,
  matchControlGraphics,
  handleDispatchControl,
  handleStartTime,
  handleStopTime,
  extraTime,
  showExtraTime = true,
  showControls = true,
  disabled = false,
  precision = 'minutes',
  handleChangeTime,
}: Props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [minutesInput, setMinutesInput] = useState<number | undefined>(0);
  const [secondsInput, setSecondsInput] = useState<number | undefined>(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { time, setTimer } = useTimer(100, clock.clockTime, clock.clockCountDirection);
  const formatted = useMemo(() => getFormattedTime(time, precision), [time, precision]);

  // useEffect to update the timer with offset when the clock changes -> make hook to account for offset time server
  useEffect(() => {
    if (!clock) return;

    const serverTimeOffset = clock.serverTimeUpdate
      ? new Date(clock.serverTimeUpdate).getTime() - Date.now()
      : 0;

    const startedAt = clock.clockTimeUpdatedAt
      ? new Date(clock.clockTimeUpdatedAt).getTime() - serverTimeOffset
      : null;

    setTimer(clock.clockTime || 0, startedAt ? new Date(startedAt) : null);
  }, [clock, clock.clockTimeUpdatedAt, clock.clockTime]);

  const handleAddSeconds = (amount: number) => {
    if (editing) {
      if (secondsInput! + amount >= 60) {
        setMinutesInput(minutesInput! + 1);
        setSecondsInput((secondsInput! + amount) % 60);
      } else {
        setSecondsInput(secondsInput! + amount);
      }
      return;
    }
    handleChangeTime?.(amount * 1000);
  };

  const handleSubtractSeconds = (amount: number) => {
    if (editing) {
      if (secondsInput! - amount < 0) {
        setMinutesInput(minutesInput! - 1);
        setSecondsInput(60 - (amount - secondsInput!));
      } else {
        setSecondsInput(secondsInput! - amount);
      }
      return;
    }
    handleChangeTime?.(-amount * 1000);
  };

  const handleToggleEditingTime = () => {
    if (!editing) {
      setMinutesInput(Math.floor(time / 60000));
      setSecondsInput(Math.floor((time % 60000) / 1000));
    } else {
      const timeDifference = minutesInput! * 60000 + secondsInput! * 1000 - time;
      handleChangeTime?.(timeDifference);
    }
    setEditing(!editing);
  };

  useOutsideClick(containerRef, () => {
    if (editing) {
      handleToggleEditingTime();
    }
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setInput: React.Dispatch<React.SetStateAction<number | undefined>>
  ) => {
    const { value } = e.currentTarget;

    if (!value.length) {
      setInput(undefined);
      return;
    }

    setInput(+value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        handleToggleEditingTime();
        break;
      case 'Escape':
        setEditing(false);
        break;
      default:
        break;
    }
  };

  const getButtonContent = (variant: MatchControlVariant, num: number, add: boolean) => {
    const actionText = add ? 'Add' : 'Subtract';
    const operator = add ? '+' : '-';
    const timeText = `${num} ${num === 1 ? 'second' : 'seconds'}`;

    if (variant === 'default') {
      return `${operator}${num}`;
    }

    return (
      <>
        {actionText}
        <br />
        {timeText}
      </>
    );
  };

  return (
    <div className={styles.TimeInWrapper}>
      <div className={styles.TimeInHeaderWrapper}>
        {editing ? (
          <MatchControlIcon
            iconProps={{ icon: 'x', color: '#5B5D70' }}
            onClick={handleToggleEditingTime}
          />
        ) : (
          <MatchControlIcon
            iconProps={{ icon: 'pencil', color: '#5B5D70', weight: 'fill' }}
            onClick={handleToggleEditingTime}
          />
        )}

        {editing ? (
          <div ref={containerRef} className="flex w-[90px] mx-auto">
            <input
              type="number"
              value={minutesInput}
              min={0}
              onChange={(e) => handleInputChange(e, setMinutesInput)}
              onKeyDown={handleInputKeyDown}
              className="flex-1 w-[45px] bg-transparent text-white font-bold text-xl"
            />
            <input
              type="number"
              value={secondsInput}
              maxLength={2}
              onChange={(e) => handleInputChange(e, setSecondsInput)}
              onKeyDown={handleInputKeyDown}
              min={0}
              className="flex-1 w-[45px] bg-transparent text-white font-bold text-xl"
            />
          </div>
        ) : (
          <Text as="h2" color="light" className="mx-auto">
            {formatted}
          </Text>
        )}

        {showExtraTime && (
          <>
            <div className="w-[2px] h-[24px] bg-[#4D4D5B]" />
            <Text as="h2" color="light" className="mx-auto">
              + {extraTime ? extraTime / 1000 : 0}
            </Text>
          </>
        )}
      </div>
      {showControls && (
        <div className={classNames(styles.TimeInBodyWrapper, 'flex justify-between')}>
          <div>
            <div className="flex flex-row items-center justify-between gap-2 col-span-1 [&>div]:w-full">
              <ClockIn
                variant="icon"
                clockLive={Boolean(
                  matchControlGraphics?.find(
                    (control) => control.controlId === MAIN_CLOCK_ID && control.live
                  )
                )}
                handleToggleGraphic={() =>
                  handleDispatchControl(
                    matchControlGraphics?.find((c) => c.controlId === MAIN_CLOCK_ID)
                  )
                }
              />
              |
              <ClockStart
                clockRunning={Boolean(clock.clockTimeUpdatedAt)}
                handleStartTimer={() => handleStartTime(clock)}
                handleStopTimer={() => handleStopTime(clock)}
                minimize
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className={classNames(styles.TimeInItemWrapper, styles.ActionsWrapper, 'gap-1')}>
              <MatchControlButton
                variant={variant === 'extended' ? 'default' : 'icon'}
                disabled={disabled}
                onClick={() => handleSubtractSeconds(5)}
              >
                {getButtonContent(variant, 5, false)}
              </MatchControlButton>

              <MatchControlButton
                variant={variant === 'extended' ? 'default' : 'icon'}
                disabled={disabled}
                onClick={() => handleAddSeconds(5)}
              >
                {getButtonContent(variant, 5, true)}
              </MatchControlButton>
            </div>
            <div className={classNames(styles.TimeInItemWrapper, styles.ActionsWrapper, 'gap-1')}>
              <MatchControlButton
                variant={variant === 'extended' ? 'default' : 'icon'}
                disabled={disabled}
                onClick={() => handleSubtractSeconds(1)}
              >
                {getButtonContent(variant, 1, false)}
              </MatchControlButton>
              <MatchControlButton
                variant={variant === 'extended' ? 'default' : 'icon'}
                disabled={disabled}
                onClick={() => handleAddSeconds(1)}
              >
                {getButtonContent(variant, 1, true)}
              </MatchControlButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClockDisplay;
