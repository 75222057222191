import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MATCH_CONTROLS, MatchControlsStateType } from './types';
import { MatchControl } from 'src/types/matchControl';
import { SportCategory } from 'src/types/matchControl/sport';
import { Clock } from 'src/types/matchControl/clock';
import { Contestant, SoccerContestant } from 'src/types/matchControl/contestant';
import { Control } from 'src/types/matchControl/control';

const matchControlsInitialState: MatchControlsStateType = {
  categories: {
    error: null,
    loading: true,
    data: [],
  },
  skeleton: {
    error: null,
    loading: true,
    data: null,
  },
  matchControl: {
    error: null,
    loading: true,
    data: null,
  },
  matchControlGraphics: {
    error: '',
    loading: true,
    data: null,
  },
};

export const matchControlSlice = createSlice({
  name: MATCH_CONTROLS,
  initialState: matchControlsInitialState,
  // Apply state changes
  reducers: {
    // categories
    requestGetMatchControlTypes: (state) => {
      state.categories.loading = true;
    },
    receiveGetMatchControlTypes: (state, action: PayloadAction<SportCategory[]>) => {
      state.categories.loading = false;
      state.categories.data = action.payload;
    },
    failedGetMatchControlTypes: (state, action: PayloadAction<string>) => {
      state.categories.loading = false;
      state.categories.error = action.payload;
    },
    // skeleton
    requestGetMatchControlSkeleton: (state) => {
      state.skeleton.loading = true;
    },
    receiveGetMatchControlSkeleton: (state, action: PayloadAction<MatchControl | null>) => {
      state.skeleton.loading = false;
      state.skeleton.data = action.payload;
    },
    failedGetMatchControlSkeleton: (state, action: PayloadAction<string>) => {
      state.skeleton.loading = false;
      state.skeleton.error = action.payload;
    },
    // matchcontrol
    requestGetMatchControl: (state) => {
      state.matchControl.loading = true;
    },
    receiveGetMatchControl: (state, action: PayloadAction<MatchControl | null>) => {
      state.matchControl.loading = false;
      state.matchControl.data = action.payload;
    },
    failedGetMatchControl: (state, action: PayloadAction<string>) => {
      state.matchControl.loading = false;
      state.matchControl.error = action.payload;
    },
    requestUpdateMatchControl: (state) => {
      state.matchControl.loading = true;
    },
    receiveUpdateMatchControl: (state, action: PayloadAction<MatchControl>) => {
      state.matchControl.data = action.payload;
      state.matchControl.loading = false;
    },
    failedUpdateMatchControl: (state, action: PayloadAction<string>) => {
      state.matchControl.error = action.payload;
      state.matchControl.loading = false;
    },
    requestUpdateMatchControlShootOut: (state) => {
      state.matchControl.loading = true;
    },
    receiveUpdateMatchControlShootOut: (state, action: PayloadAction<MatchControl>) => {
      state.matchControl.data = action.payload;
      state.matchControl.loading = false;
    },
    failedUpdateMatchControlShootOut: (state, action: PayloadAction<string>) => {
      state.matchControl.error = action.payload;
      state.matchControl.loading = false;
    },
    // controls
    requestGetMatchControlControls: (state) => {
      state.matchControlGraphics.loading = true;
    },
    receiveGetMatchControlControls: (
      state,
      action: PayloadAction<(Control & { live: boolean })[]>
    ) => {
      state.matchControlGraphics.loading = false;
      state.matchControlGraphics.data = action.payload;
    },
    failedGetMatchControlControls: (state, action: PayloadAction<string>) => {
      state.matchControlGraphics.loading = false;
      state.matchControlGraphics.error = action.payload;
    },
    requestUpdateMatchControlGraphic: (state) => {
      state.matchControlGraphics.loading = true;
    },
    receiveUpdateMatchControlGraphic: (
      state,
      action: PayloadAction<(Control & { live: boolean })[]>
    ) => {
      state.matchControlGraphics.loading = false;
      if (!state.matchControlGraphics.data) return;

      state.matchControlGraphics.data = action.payload;
    },
    failedUpdateMatchControlGraphic: (state, action: PayloadAction<string>) => {
      state.matchControlGraphics.loading = false;
      state.matchControlGraphics.error = action.payload;
    },
    // clock
    requestUpdateClock: (state) => {
      state.matchControl.loading = true;
    },
    receiveUpdateClock: (state, action: PayloadAction<Clock[]>) => {
      if (state.matchControl.data && 'clocks' in state.matchControl.data) {
        state.matchControl.data.clocks = action.payload as typeof state.matchControl.data.clocks;
      }
      state.matchControl.loading = false;
    },
    failedUpdateClock: (state, action: PayloadAction<string>) => {
      state.matchControl.loading = false;
      state.matchControl.error = action.payload;
    },
    // contestant
    requestUpdateMatchControlContestant: (state) => {
      state.matchControl.loading = true;
    },
    receiveUpdateMatchControlContestant: (state, action: PayloadAction<Contestant>) => {
      if (state.matchControl.data && 'contestants' in state.matchControl.data) {
        // Update the contestant in the list of contestants, should be checked on type
        const prevContestants = state.matchControl.data.contestants as SoccerContestant[];
        const contestants = prevContestants.map((contestant: Contestant) =>
          contestant.id === action.payload.id ? action.payload : contestant
        );
        state.matchControl.data.contestants =
          contestants as typeof state.matchControl.data.contestants;
      }
      state.matchControl.loading = false;
    },
    failedUpdateMatchControlContestant: (state, action: PayloadAction<string>) => {
      state.matchControl.loading = false;
      state.matchControl.error = action.payload;
    },
  },
});

const actions = matchControlSlice.actions;
const matchControlsReducer = matchControlSlice.reducer;

export { actions, matchControlsReducer };
