import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import {
  REQUEST_CONNECT_PLAYLIST_GRAPHIC,
  REQUEST_DELETE_PLAYLIST_GRAPHIC,
  REQUEST_DUPLICATE_PLAYLIST_GRAPHIC,
  REQUEST_REORDER_PLAYLIST_GRAPHIC,
  REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC,
  REQUEST_TOGGLE_CONCEPT_PLAYLIST_GRAPHIC,
} from 'src/redux/reducers/playlistGraphic';
import { REQUEST_ON_QUICKIFY_TOGGLE } from 'src/redux/reducers/playlistGraphic';
import {
  REQUEST_ANIMATE_IN_LIVE_GRAPHIC,
  REQUEST_ANIMATE_OUT_LIVE_GRAPHIC,
} from 'src/redux/reducers/control';
import { PlaylistGetAllStateType } from 'src/redux/reducers/playlist';
import useKeypress from 'src/hooks/useKeypress';

import { IconNames, SortableList, Text } from '@southfields-digital/mpxlive-components';
import Graphic, { IGraphicProps } from 'src/components/Graphic';

import styles from './Graphics.module.scss';

interface IGraphicsProps {}

export default function Graphics({}: IGraphicsProps) {
  const dispatch = useDispatch();
  const { playlistGraphics, selectedPlaylistGraphic: selectedGraphic } = useSelector(
    (state: StateType) => state.playlistGraphic
  );
  const { selectedId: playlistId, data: playlists }: PlaylistGetAllStateType = useSelector(
    (state: StateType) => state.playlist.getAll
  );

  const graphics = useMemo(
    () => playlistGraphics?.filter((graphic) => graphic.playlist.id === playlistId),
    [playlistGraphics, playlistId]
  );

  const playlist = useMemo(
    () => playlists?.find((playlist) => playlist.id === playlistId),
    [playlists, playlistId]
  );

  const handleDeleteGraphic = (graphic: PlaylistGraphic) => {
    dispatch({
      type: REQUEST_DELETE_PLAYLIST_GRAPHIC,
      payload: { playlistId: graphic.playlist.id, playlistGraphicId: graphic.id },
    });
  };

  const handleDuplicateGraphic = (graphic: PlaylistGraphic) => {
    dispatch({
      type: REQUEST_DUPLICATE_PLAYLIST_GRAPHIC,
      payload: { playlistId: graphic.playlist.id, playlistGraphicId: graphic.id },
    });
  };

  const handleClickGraphic = (graphic: PlaylistGraphic) => {
    dispatch({
      type: REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC,
      payload: { playlistGraphic: graphic, selectedPlaylistGraphic: selectedGraphic },
    });
  };

  const handleToggleLive = (graphic: PlaylistGraphic) => {
    dispatch({
      type: graphic.status.live
        ? REQUEST_ANIMATE_OUT_LIVE_GRAPHIC
        : REQUEST_ANIMATE_IN_LIVE_GRAPHIC,
      payload: { playlistGraphicId: graphic.id },
    });
  };

  const handleQuickify = (graphic: PlaylistGraphic) => {
    dispatch({ type: REQUEST_ON_QUICKIFY_TOGGLE, payload: { graphic } });
  };

  const handleSortGraphics = (sortedValues: IGraphicProps[]) => {
    const sortedGraphics: string[] = sortedValues.map((item) => item.id);
    dispatch({
      type: REQUEST_REORDER_PLAYLIST_GRAPHIC,
      payload: {
        playlistId,
        playlistGraphicIds: sortedGraphics,
      },
    });
  };

  const getParentGraphicId = (graphic: PlaylistGraphic) => {
    if (graphic.parent) return null;

    const index = graphics?.findIndex((g) => g.id === graphic.id);

    if (!index || !graphics) return null;

    // If graphics is already a parent we should not connect to a different parent
    if (graphics[index + 1]?.parent) return null;

    for (let i = index - 1; i >= 0; i--) {
      const potentialGraphic = graphics[i];
      if (!potentialGraphic.parent) return potentialGraphic.id;
    }

    return null;
  };

  const handleToggleConnectGraphic = (graphic: PlaylistGraphic) => {
    const parentId = getParentGraphicId(graphic);

    dispatch({
      type: REQUEST_CONNECT_PLAYLIST_GRAPHIC,
      payload: { playlistId, playlistGraphicId: graphic.id, parentId },
    });
  };

  const handleToggleConceptGraphic = (graphic: PlaylistGraphic) => {
    dispatch({
      type: REQUEST_TOGGLE_CONCEPT_PLAYLIST_GRAPHIC,
      payload: { playlistId, playlistGraphicId: graphic.id },
    });
  };

  const canConnect = (graphic: PlaylistGraphic) => {
    if (!graphics) return false;

    const index = graphics.findIndex((g) => g.id === graphic.id);

    if (index === 0 || index === -1) return false;

    if (graphics[index + 1]?.parent && !graphic.parent) return false;

    for (let i = index - 1; i >= 0; i--) {
      const potentialGraphic = graphics[i];
      if (!potentialGraphic.parent) return true;
    }

    return false;
  };

  const selectNextGraphic = () => {
    if (!graphics || !graphics.length) return;

    const index = graphics.findIndex((graphic) => graphic.id === selectedGraphic?.id);
    const nextGraphic = graphics[index + 1];
    if (nextGraphic) {
      dispatch({
        type: REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC,
        payload: { playlistGraphic: nextGraphic, selectedPlaylistGraphic: selectedGraphic },
      });
    }
  };

  const selectPreviousGraphic = () => {
    if (!graphics || !graphics.length) return;

    const index = graphics.findIndex((graphic) => graphic.id === selectedGraphic?.id);
    const previousGraphic = graphics[index - 1];
    if (previousGraphic) {
      dispatch({
        type: REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC,
        payload: { playlistGraphic: previousGraphic, selectedPlaylistGraphic: selectedGraphic },
      });
    }
  };

  useKeypress('PageUp', {}, selectPreviousGraphic);
  useKeypress('PageDown', {}, selectNextGraphic);

  const items: IGraphicProps[] = useMemo(() => {
    if (!graphics) return [];

    return graphics.map((graphic) => ({
      id: graphic.id,
      text: graphic.name,
      isQuick: !!graphic.isQuick,
      live: graphic.status.live,
      active: selectedGraphic?.id === graphic.id,
      variant: 'default',
      parent: graphic.parent,
      sortable: graphic.parent === null,
      icon: graphic.parent !== null ? 'arrow-elbow-left-up' : (undefined as IconNames | undefined),
      relatedTo: graphic.parent?.id,
      canConnect: canConnect(graphic),
      showIsConcept: Boolean(playlist?.autoplay.isEnabled),
      isConcept: graphic.isConcept,
      onConcept: () => handleToggleConceptGraphic(graphic),
      onConnect: () => handleToggleConnectGraphic(graphic),
      onLive: () => handleToggleLive(graphic),
      onClick: () => handleClickGraphic(graphic),
      onDuplicate: () => handleDuplicateGraphic(graphic),
      onQuickify: () => handleQuickify(graphic),
      onDelete: () => handleDeleteGraphic(graphic),
      graphic: graphic.graphic, // needed for tooltip
    }));
  }, [graphics, selectedGraphic, playlist]);

  if (!playlistId)
    return (
      <div className={styles.NoContent}>
        <Text as="p">Please select a playlist</Text>
      </div>
    );
  if (!graphics || !graphics.length)
    return (
      <div className={styles.NoContent}>
        <Text as="p">No graphics</Text>
      </div>
    );

  return (
    <div className={styles.Container}>
      <SortableList
        activeId={selectedGraphic?.id}
        items={items}
        setItems={handleSortGraphics}
        Component={Graphic}
      />
    </div>
  );
}
