export type UserType = {
  email: string;
};

export type UserAuthResponseType = {
  id: string | null;
  name?: string;
  email?: string;
  roles?: string[];
  workspace?: Workspace;
};

export type UserAuthType = {
  authenticated: boolean;
  checked: boolean;
  error: string | null;
} & UserAuthResponseType;

export type UserStateType = {
  data: UserType | null;
  auth: UserAuthType;
};

export const USER = 'user';
export type USER = typeof USER;

export const REQUEST_USER_AUTH_STATUS = `${USER}/requestUserAuthStatus`;
export type REQUEST_USER_AUTH_STATUS = typeof REQUEST_USER_AUTH_STATUS;

export const REQUEST_USER_LOGOUT = `${USER}/requestUserLogout`;
export type REQUEST_USER_LOGOUT = typeof REQUEST_USER_LOGOUT;
