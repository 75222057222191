import { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import {
  REQUEST_GET_RUNDOWN,
  REQUEST_UPDATE_RUNDOWN_OPERATOR,
  RundownDetailStateType,
  UPDATE_CURRENT_RUNDOWN,
} from 'src/redux/reducers/rundown';
import {
  REQUEST_CREATE_PLAYLIST,
  REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID,
} from 'src/redux/reducers/playlist';
import {
  REQUEST_CREATE_PLAYLIST_GRAPHIC,
  REQUEST_GET_ALL_PLAYLIST_GRAPHICS,
} from 'src/redux/reducers/playlistGraphic';
import { Socket } from 'socket.io-client';
import { WebsocketBuddy } from 'src/services/websockets';
import { getMappedCanvases } from 'src/components/GoLive/utils';
import {
  PanelGroup,
  Panel,
  PanelResizeHandle as ResizeHandle,
  PanelResizeHandle,
} from 'src/components/ResizeablePanel';
import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import ViewWindow from 'src/components/ViewWindow/ViewWindow';
import Playlists from 'src/components/Playlists';
import GraphicList from 'src/components/GraphicList/GraphicList';
import Graphics from 'src/components/Graphics';
import GraphicDetails from 'src/components/GraphicDetails';
import PlaylistDialog from 'src/components/PlaylistDialog';
import ActiveGraphics, { ActiveGraphicsHeader } from 'src/components/ActiveGraphics';
import QuickControl from 'src/components/QuickControl/QuickControl';
import ToggleButtons from 'src/components/ToggleButtons/ToggleButtons';
import RundownLocked from 'src/components/RundownLocked';
import MatchControl from 'src/components/MatchControl';
import { Drawer, Loader, Text } from '@southfields-digital/mpxlive-components';
import { StatusDescription } from 'src/components/GoLive';
import {
  REQUEST_GET_MATCH_CONTROL,
  REQUEST_GET_MATCH_CONTROL_CONTROLS,
} from 'src/redux/reducers/matchControls';
import {
  ISocketMatchControlProps,
  ISocketPlaylistGraphicProps,
  ISocketPlaylistProps,
  ISocketRundownProps,
} from 'src/types/socket/socket';

import styles from './RundownDetail.module.scss';

const LAYOUT_PARAM = 'layout';
export default function RundownDetail() {
  const socketInstance = useRef<Socket | null>(null);

  const { setFullWidth, setDisplayClock, setGoLive, setShowRundownName, setShowRundownSettings } =
    useContext<BaseLayoutContextType | null>(BaseLayoutContext) as BaseLayoutContextType;
  const { rundownId } = useParams<{ rundownId: string }>();

  const [searchParams] = useSearchParams();
  const layout = searchParams.get(LAYOUT_PARAM);

  const dispatch = useDispatch();
  const { data, outputs, loading, error }: RundownDetailStateType = useSelector(
    (state: StateType) => state.rundown.detail
  );

  const { selectedId: activePlaylistId } = useSelector((state: StateType) => state.playlist.getAll);

  // Current activePlaylistId is volatile and likely to change during API requests, so define a ref for use in callbacks
  const guaranteedActivePlaylistId = useRef<string | null>(activePlaylistId);

  const [selectedCanvas, setSelectedCanvas] = useState<RundownTemplateCanvas | undefined>();
  const [showMatchControl, setShowMatchControl] = useState<boolean>(false);
  const [showPlaylistDialog, setShowPlaylistDialog] = useState<boolean>(false);
  const [showGraphicDrawer, setShowGraphicDrawer] = useState<boolean>(false);

  const getRundown = (rundownId: string | null) => {
    dispatch({ type: REQUEST_GET_RUNDOWN, payload: { id: rundownId } });
  };

  const getRundownGraphics = (rundownId: string) => {
    dispatch({ type: REQUEST_GET_ALL_PLAYLIST_GRAPHICS, payload: { rundownId } });
  };

  const handleSocketRundown = (_: ISocketRundownProps) => {
    dispatch({
      type: UPDATE_CURRENT_RUNDOWN,
      payload: { id: rundownId },
    });
  };

  const handleSocketPlaylist = (_: ISocketPlaylistProps) => {
    dispatch({
      type: REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID,
      payload: { rundownId, resetSelected: false },
    });
  };

  const handleSocketPlaylistGraphic = (_: ISocketPlaylistGraphicProps) => {
    if (rundownId) {
      getRundownGraphics(rundownId);
    }
  };

  const handleSocketMatchControl = (_: ISocketMatchControlProps) => {
    dispatch({ type: REQUEST_GET_MATCH_CONTROL, payload: { rundownId } });
    dispatch({ type: REQUEST_GET_MATCH_CONTROL_CONTROLS, payload: { rundownId } });
  };

  const handleUpdateRundownOperator = () => {
    dispatch({ type: REQUEST_UPDATE_RUNDOWN_OPERATOR, payload: { id: rundownId } });
  };

  const handleNewPlaylist = (name: string) => {
    dispatch({ type: REQUEST_CREATE_PLAYLIST, payload: { rundownId, name } });
    setShowPlaylistDialog(false);
  };

  const handleGraphicSelected = (graphicId: string) => {
    dispatch({
      type: REQUEST_CREATE_PLAYLIST_GRAPHIC,
      payload: { playlistId: guaranteedActivePlaylistId.current, graphicId },
    });

    setShowGraphicDrawer(false);
  };

  useEffect(() => {
    if (!rundownId) return;
    getRundown(rundownId);
    getRundownGraphics(rundownId);
  }, [rundownId, dispatch]);

  useEffect(() => {
    guaranteedActivePlaylistId.current = activePlaylistId;
  }, [activePlaylistId]);

  useEffect(() => {
    setFullWidth(true);
    setGoLive(true);
    setShowRundownName(true);
    setShowRundownSettings(true);
    setDisplayClock(true);

    return () => {
      setFullWidth(false);
      setGoLive(false);
      setDisplayClock(false);
      setShowRundownName(false);
      setShowRundownSettings(false);

      if (socketInstance.current) {
        socketInstance.current.disconnect();
        socketInstance.current = null;
      }

      getRundown(null);
    };
  }, []);

  useEffect(() => {
    if (!data) return;
    if (data.isLive && !selectedCanvas && data.canvases.length > 0) {
      setSelectedCanvas(data.canvases[0]);
    }

    if (data.sandboxChannelId && !socketInstance.current) {
      const { socket } = WebsocketBuddy({
        channel: data.sandboxChannelId,
        driver: 'SocketIo',
        listeners: {
          rundown: handleSocketRundown,
          playlist: handleSocketPlaylist,
          playlistGraphic: handleSocketPlaylistGraphic,
          matchControl: handleSocketMatchControl,
        },
      });

      socketInstance.current = socket;
    }

    const showHeaders = !data.isLocked;
    setGoLive(showHeaders);
    setShowRundownName(showHeaders);
    setShowRundownSettings(showHeaders);
    setDisplayClock(showHeaders);
  }, [data]);

  if (!rundownId || loading) return <Loader centeredFullscreen />;
  if (error) return <div className={styles.CenteredContent}>{error}</div>;
  if (!data) return <div className={styles.CenteredContent}>No Rundown found</div>;
  if (data.isLocked && data.usedBy)
    return <RundownLocked data={data.usedBy} handle={handleUpdateRundownOperator} />;

  if (layout === 'matchcontrol') {
    return (
      <div className="w-[685px] h-full mx-auto [&>div]:bg-elevated-1">
        <PanelGroup direction="vertical">
          <Panel defaultSize={70}>
            <MatchControl rundownId={rundownId} variant="extended" />
          </Panel>
          <PanelResizeHandle />
          <Panel defaultSize={30}>
            <QuickControl key={Math.random()} />
          </Panel>
        </PanelGroup>
      </div>
    );
  }

  return (
    <div className="h-full">
      <PlaylistDialog
        initialName={''}
        dialogTitle="Create Playlist"
        isOpen={showPlaylistDialog}
        onClose={() => setShowPlaylistDialog(false)}
        onSubmit={handleNewPlaylist}
      />
      <PanelGroup className={styles.Container} direction="horizontal">
        <Panel defaultSize={25} minSize={25} id="first-column" order={1}>
          <PanelGroup direction="vertical">
            <Panel
              header="Playlists"
              icon="plus"
              onClick={() => setShowPlaylistDialog(true)}
              defaultSize={40}
            >
              <Playlists rundownId={rundownId} />
            </Panel>

            <ResizeHandle />

            <Panel
              header="Graphics"
              canEdit={!!activePlaylistId}
              icon="plus"
              onClick={() => setShowGraphicDrawer(true)}
            >
              <Graphics />
            </Panel>
          </PanelGroup>
        </Panel>

        <ResizeHandle />

        <Panel defaultSize={50} id="second-column" order={2}>
          <PanelGroup direction="vertical">
            <Panel className="bg-default-surface">
              <ViewWindow name="Sandbox" src={data.sandboxUrl} liveMode={false} />
            </Panel>

            <ResizeHandle />

            <Panel>
              <GraphicDetails key={rundownId} />
            </Panel>
          </PanelGroup>
        </Panel>

        {layout !== 'simplified' ? (
          <>
            <ResizeHandle />

            <Panel defaultSize={25} maxSize={50} id="third-column" order={3}>
              <PanelGroup direction="vertical">
                <div className="border-b-[4px] border-[#151621]">
                  <StatusDescription
                    rundown={data}
                    mappedCanvases={getMappedCanvases(data.canvases, outputs)}
                    isLive={data.isLive}
                  />
                </div>
                {data.isLive && (
                  <>
                    <Panel
                      id="canvas"
                      order={1}
                      defaultSize={37}
                      header={
                        <ToggleButtons
                          items={data.canvases.filter((canvas) => canvas.graphics.length)}
                          selectedItem={selectedCanvas}
                          onClickItem={(canvas) => setSelectedCanvas(canvas)}
                        />
                      }
                    >
                      {selectedCanvas?.output?.liveUrl ? (
                        <ViewWindow name="Live" src={selectedCanvas.output.liveUrl} liveMode />
                      ) : (
                        <div className="flex justify-center items-center h-full">
                          <Text as="p" className="!text-sm !text-gray-300 uppercase p-2">
                            Configure an output for this canvas
                          </Text>
                        </div>
                      )}
                    </Panel>
                    <ResizeHandle />
                  </>
                )}
                <Panel
                  id="quickControls"
                  order={2}
                  defaultSize={data.isLive ? 32.25 : 35}
                  header={
                    <ToggleButtons
                      items={[
                        { id: 'quickControls', name: 'Quick Controls' },
                        {
                          id: 'matchControls',
                          name: 'Match Control',
                          visible: Boolean(data.matchControl),
                        },
                      ]}
                      selectedItem={
                        showMatchControl
                          ? { id: 'matchControls', name: 'Match Control' }
                          : { id: 'quickControls', name: 'Quick Controls' }
                      }
                      onClickItem={(item) => setShowMatchControl(item.id === 'matchControls')}
                    />
                  }
                >
                  {showMatchControl ? <MatchControl rundownId={rundownId} /> : <QuickControl />}
                </Panel>

                <ResizeHandle />

                <Panel
                  header={<ActiveGraphicsHeader />}
                  id="activeGraphics"
                  order={3}
                  overflow={false}
                >
                  <ActiveGraphics />
                </Panel>
              </PanelGroup>
            </Panel>
          </>
        ) : null}
      </PanelGroup>

      <Drawer
        isVisible={showGraphicDrawer}
        onClose={() => setShowGraphicDrawer(false)}
        header="Graphic Assets"
        className="overflow-auto h-full pb-4"
      >
        <GraphicList rundownId={rundownId} handleGraphicSelected={handleGraphicSelected} />
      </Drawer>
    </div>
  );
}
