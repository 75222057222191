import { Row, Items, isRepeater } from 'src/components/Repeater';

const getValues = (items: Items[]): PropertyValue[] => {
  const result: PropertyValue[] = [];

  for (const item of items) {
    const mappedItem = Object.values(item).reduce<PropertyValue>((acc, row) => {
      if (isRepeater(row)) {
        acc[row.id] = getValues(row.items);
      } else {
        acc[row.id] = row.value;
      }
      return acc;
    }, {});

    result.push(mappedItem);
  }

  return result;
};

const getRepeaterItems = (
  property: RepeaterProperties,
  propertyValues?: PropertyValue[]
): Items[] => {
  const result: Items[] = [];
  if (propertyValues?.length) {
    for (const propertyValue of propertyValues) {
      const items: Items = {};
      for (const child of property.children) {
        items[child.id] = getRepeaterItem(child, propertyValue);
      }
      result.push(items);
    }
  } else {
    const item: Items = {};
    for (const child of property.children) {
      item[child.id] = getRepeaterItem(child);
    }
    result.push(item);
  }
  return result;
};

const getRepeaterItem = (child: Properties, propertyValue?: PropertyValue): Row => {
  switch (child.type) {
    case 'repeater':
      const values = propertyValue ? (propertyValue[child.id] as PropertyValue[]) : [];
      return {
        ...child,
        name: child.name,
        id: child.id,
        items: getRepeaterItems(child, values),
      };
    case 'select':
      return {
        ...child,
        id: child.id,
        type: child.type,
        value: propertyValue ? (propertyValue[child.id] as string) : child.defaultValue ?? '',
        options: child.options,
      };
    case 'text':
      return {
        ...child,
        id: child.id,
        type: child.type,
        value: propertyValue ? (propertyValue[child.id] as string) : child.defaultValue ?? '',
        name: child.name,
      };
    default:
      return {
        ...child,
        id: child.id,
        type: child.type,
        value: propertyValue ? (propertyValue[child.id] as string) : child.defaultValue ?? '',
      };
  }
};

export { getRepeaterItems, getValues };
