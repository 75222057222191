import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import {
  REQUEST_ANIMATE_IN_LIVE_GRAPHIC,
  REQUEST_ANIMATE_OUT_LIVE_GRAPHIC,
} from 'src/redux/reducers/control';

import { QuickButton } from '@southfields-digital/mpxlive-components';

import styles from './QuickControl.module.scss';

const MIN_PLACEHOLDER_ITEMS = 9;
const ITEMS_PER_ROW = 3;

const QuickControl = () => {
  const dispatch = useDispatch();

  const { playlistGraphics } = useSelector((state: StateType) => state.playlistGraphic);
  const quickControls = useMemo(() => {
    return playlistGraphics?.filter((graphic) => graphic.isQuick) || [];
  }, [playlistGraphics]);

  // Always show $MIN_PLACEHOLDER_ITEMS, rows after have at least $ITEMS_PER_ROW
  const totalPlaceholerItems = useMemo(() => {
    const remainingItems = MIN_PLACEHOLDER_ITEMS - quickControls.length;
    if (remainingItems > 0) return remainingItems;
    if (remainingItems === 0) return 0;
    return (ITEMS_PER_ROW - (quickControls.length % ITEMS_PER_ROW)) % ITEMS_PER_ROW;
  }, [quickControls]);

  const liveIn = (item: PlaylistGraphic) => {
    if (!item) return;

    dispatch({
      type: REQUEST_ANIMATE_IN_LIVE_GRAPHIC,
      payload: { playlistGraphicId: item.id },
    });
  };

  const liveOut = (item: PlaylistGraphic) => {
    if (!item) return;

    dispatch({
      type: REQUEST_ANIMATE_OUT_LIVE_GRAPHIC,
      payload: { playlistGraphicId: item.id },
    });
  };

  const handleToggleLive = (item: PlaylistGraphic) => {
    if (!item) return;

    if (item.status.live) {
      liveOut(item);
    } else {
      liveIn(item);
    }
  };

  //@todo when merge with match controls
  return (
    <div className={styles.Container}>
      {quickControls.map((item) => (
        <QuickButton
          key={item.id}
          className={styles.QuickControlBtn}
          onClick={() => handleToggleLive(item)}
          title={item.name}
          subTitle={item.playlist.name}
          isRunning={item.status.live}
        />
      ))}
      {[...Array(totalPlaceholerItems).keys()].map((i) => (
        <QuickButton key={i} className={styles.QuickControlBtnDisabled} disabled />
      ))}
    </div>
  );
};

export default QuickControl;
