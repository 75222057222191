import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RundownTemplateOverviewResponseType,
  RundownTemplateStateType,
  RUNDOWN_TEMPLATE,
  RundownTemplateDetailResponseType,
} from './types';

const rundownTemplateInitialState: RundownTemplateStateType = {
  create: {
    data: null,
    submitting: false,
    error: null,
  },
  edit: {
    data: null,
    submitting: false,
    error: null,
  },
  detail: {
    data: null,
    deleting: false,
    loading: true,
    error: null,
  },
  overview: {
    data: null,
    loading: true, // start with loading state to avoid flash
    error: null,
  },
};

export const rundownSlice = createSlice({
  name: RUNDOWN_TEMPLATE,
  initialState: rundownTemplateInitialState,
  reducers: {
    requestCreateRundownTemplate: (rundown: RundownTemplateStateType) => {
      rundown.create.submitting = true;
    },
    receiveCreateRundownTemplate: (rundown: RundownTemplateStateType) => {
      rundown.create.submitting = false;
    },
    failedCreateRundownTemplate: (
      rundown: RundownTemplateStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      rundown.create.submitting = false;
      rundown.create.error = error;
    },
    requestDeleteRundownTemplate: (rundown: RundownTemplateStateType) => {
      rundown.detail.deleting = true;
    },
    receiveDeleteRundownTemplate: (
      rundown: RundownTemplateStateType,
      { payload }: PayloadAction<string>
    ) => {
      if (rundown.overview.data) {
        rundown.overview.data = rundown.overview.data.filter(
          (rundownTemplate) => rundownTemplate.id !== payload
        );
      }
      rundown.detail.deleting = false;
    },
    failedDeleteRundownTemplate: (rundown: RundownTemplateStateType) => {
      rundown.detail.deleting = false;
    },
    requestUpdateRundownTemplate: (rundown: RundownTemplateStateType) => {
      rundown.edit.submitting = true;
    },
    receiveUpdateRundownTemplate: (rundown: RundownTemplateStateType) => {
      rundown.edit.submitting = false;
    },
    failedUpdateRundownTemplate: (
      rundown: RundownTemplateStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      rundown.edit.submitting = false;
      rundown.edit.error = error;
    },
    requestGetRundownTemplates: (rundown: RundownTemplateStateType) => {
      rundown.overview.loading = true;
    },
    receiveGetRundownTemplates: (
      rundown: RundownTemplateStateType,
      { payload }: PayloadAction<RundownTemplateOverviewResponseType>
    ) => {
      rundown.overview.loading = false;
      rundown.overview.data = payload;
    },
    failedGetRundownTemplates: (
      rundown: RundownTemplateStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      rundown.overview.loading = false;
      rundown.overview.error = error;
    },
    requestGetRundownTemplate: (rundown: RundownTemplateStateType) => {
      rundown.detail.loading = true;
    },
    receiveGetRundownTemplate: (
      rundown: RundownTemplateStateType,
      { payload }: PayloadAction<RundownTemplateDetailResponseType | null>
    ) => {
      rundown.detail.loading = false;
      rundown.detail.data = payload;
      if (rundown.detail.data && payload) {
        rundown.detail.data.graphicPackageId = payload.graphicPackage?.id || '';
      }
    },
    failedGetRundownTemplate: (
      rundown: RundownTemplateStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      rundown.detail.loading = false;
      rundown.detail.error = error;
    },
  },
});

const actions = rundownSlice.actions;
const rundownTemplateReducer = rundownSlice.reducer;

export { actions, rundownTemplateReducer };
