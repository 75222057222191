import classNames from 'classnames';

import { PanelResizeHandle, PanelResizeHandleProps } from 'react-resizable-panels';

import styles from './PanelResizeHandle.module.scss';

type ResizeHandleProps = {
  className?: string;
  id?: string;
  variant?: 'panel' | 'subpanel';
} & PanelResizeHandleProps;

export default function ResizeHandle({
  className = '',
  id,
  variant = 'panel',
  disabled,
}: ResizeHandleProps) {
  return (
    <PanelResizeHandle
      disabled={disabled}
      className={classNames(
        styles.ResizeHandleOuter,
        variant === 'subpanel' && styles.Subpanel,
        disabled && styles.Disabled,
        className
      )}
      id={id}
    />
  );
}
