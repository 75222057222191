import React, { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@southfields-digital/mpxlive-components';
import { Id, Items } from './types';
import { RemoveButton } from './buttons';
import { isRepeater } from './utils';
import Repeater from './Repeater';
import Cell from './Cell';
import styles from './Repeater.module.scss';

type HorizontalRepeaterProps = {
  items: Items[];
  parentRowIndex?: number;
  handleChangeRepeater: (id: Id, rowIndex: number, rows: Items[]) => void;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
  handleClickAdd: () => void;
  handleClickRemove: (index: number) => void;
  buttonClassNames?: string;
  excludedIds?: Id[];
  isDisabled?: boolean;
};

type HorizontalRepeaterRowProps = Omit<
  HorizontalRepeaterProps,
  'items' | 'handleClickAdd' | 'hadleClickRemove'
> & {
  item: Items;
  handleClickRemove: () => void;
};

const HorizontalRepeaterRow = ({
  item,
  parentRowIndex = 0,
  handleClickRemove,
  handleChangeRepeater,
  handleChangeValue,
  buttonClassNames,
  excludedIds,
  isDisabled,
}: HorizontalRepeaterRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: `HorizontalRepeater-${parentRowIndex}`,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const currentItems = useMemo(
    () => Object.values(item).filter(({ id }) => !excludedIds?.includes(id)),
    [item, excludedIds]
  );

  return (
    <tr key={parentRowIndex} ref={setNodeRef} style={style}>
      <td {...listeners} {...attributes} className="w-[30px] h-[30px] relative text-[#5B5D70]">
        <Icon icon="sort" weight="bold" />
      </td>
      <td>
        <table>
          <tbody className={styles.HorizontalRowContainer}>
            {currentItems.map((currentItem) => (
              <React.Fragment key={currentItem.id}>
                <tr>
                  <th>{currentItem.name || ''}</th>
                </tr>
                <tr>
                  <td>
                    {isRepeater(currentItem) ? (
                      <Repeater
                        parentRowIndex={parentRowIndex}
                        onChangeRows={handleChangeRepeater}
                        {...currentItem}
                      />
                    ) : (
                      <Cell
                        item={currentItem}
                        rowIndex={parentRowIndex}
                        handleChangeValue={handleChangeValue}
                      />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </td>
      {!isDisabled && (
        <td className={buttonClassNames}>
          <RemoveButton onClick={handleClickRemove} />
        </td>
      )}
    </tr>
  );
};

export default HorizontalRepeaterRow;
