export default function LivePulse() {
  return (
    <div className="flex items-center">
      <span className="relative inline-flex h-3 w-3 mx-[3px]">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-live opacity-75" />
        <span className="relative inline-flex rounded-full h-3 w-3 bg-live" />
      </span>
    </div>
  );
}
