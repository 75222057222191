import Select, { Option } from 'src/components/Select';

import styles from './CanvasForm.module.scss';

type CanvasFormProps = {
  state: (RundownCanvas | RundownTemplateCanvas)[];
  canvases: Canvas[];
  outputs?: Output[];
  canvasBackgrounds?: CanvasBackground[];
  stateUpdated?: (state: RundownCanvas[]) => void;
  disabled?: boolean;
};

const emptyOption: Option = { label: ' - ', value: '' };

const CanvasForm = ({
  state,
  outputs,
  canvasBackgrounds,
  stateUpdated,
  disabled = false,
}: CanvasFormProps) => {
  const backgroundOptions: Option[] = [
    emptyOption,
    ...(canvasBackgrounds?.map(({ name, id }) => ({ label: name, value: id })) || []),
  ];
  const outputOptions: Option[] = [
    emptyOption,
    ...(outputs?.map(({ id, name, disabled }) => ({
      label: name,
      value: id,
      disabled,
    })) || []),
  ];

  const handleSelectOutput = (canvasId: string, outputId: string) => {
    const output = outputs?.find(({ id }) => outputId === id);
    const updatedState = state.map((canvas) =>
      canvas.id !== canvasId ? canvas : { ...canvas, output: output }
    );

    if (stateUpdated) {
      stateUpdated(updatedState);
    }
  };

  const handleSelectBackground = (
    canvas: RundownCanvas | RundownTemplateCanvas,
    backgroundId: CanvasBackground['id']
  ) => {
    const updatedState = state.map((c) =>
      c.id !== canvas.id ? c : { ...c, canvasBackgroundId: backgroundId ? backgroundId : null }
    );

    if (stateUpdated) {
      stateUpdated(updatedState);
    }
  };

  return (
    <table className={styles.CanvasGraphicsForm}>
      <thead>
        <tr>
          <th className={styles.CanvasColumn}>Canvas</th>
          {outputs ? <th>Output</th> : null}
          <th>Background</th>
        </tr>
      </thead>
      <tbody>
        {state?.map((canvas) => {
          const inputDisabled = disabled || !canvas.graphics.length;
          return (
            <tr key={canvas.id}>
              <td className={styles.CanvasColumn}>{canvas.name}</td>
              {outputs ? (
                <td>
                  <Select
                    disabled={inputDisabled}
                    onChange={(e) => handleSelectOutput(canvas.id, e.target.value)}
                    options={outputOptions}
                    value={canvas.output?.id || emptyOption.value}
                  />
                </td>
              ) : null}
              <td>
                <Select
                  disabled={inputDisabled}
                  onChange={(e) => handleSelectBackground(canvas, e.target.value)}
                  options={backgroundOptions}
                  value={canvas.canvasBackgroundId || emptyOption.value}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CanvasForm;
