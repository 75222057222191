import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PLAYLIST_GRAPHIC,
  PlaylistGraphicResponseType,
  PlaylistGraphicsResponseType,
  PlaylistGraphicStateType,
  PlaylistGraphicsType,
  PlaylistGraphicType,
} from './types';
import { updatePlaylistGraphics } from './utils';

const playlistGraphicInitialState: PlaylistGraphicStateType = {
  playlistGraphics: null,
  selectedPlaylistGraphicId: null,
  selectedPlaylistGraphic: null,
  loading: true,
  error: null,
};

export const playlistGraphicSlice = createSlice({
  name: PLAYLIST_GRAPHIC,
  initialState: playlistGraphicInitialState,
  reducers: {
    // CREATE
    requestCreatePlaylistGraphic: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = true;
    },
    receiveCreatePlaylistGraphic: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = false;
    },
    failedCreatePlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.error = error;
    },
    // DELETE
    requestDeletePlaylistGraphic: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = true;
    },
    receiveDeletePlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: playlistGraphicId }: PayloadAction<string>
    ) => {
      if (playlistGraphicId === playlistGraphic.selectedPlaylistGraphic?.id) {
        playlistGraphic.selectedPlaylistGraphic = null;
      }
      playlistGraphic.loading = false;
    },
    failedDeletePlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.error = error;
    },

    // DUPLICATE
    requestDuplicatePlaylistGraphic: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = true;
    },
    receiveDuplicatePlaylistGraphic: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = false;
    },
    failedDuplicatePlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.error = error;
    },

    // UPDATE
    requestUpdatePlaylistGraphic: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = true;
    },
    receiveUpdatePlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload }: PayloadAction<PlaylistGraphicType>
    ) => {
      playlistGraphic.loading = false;

      if (!playlistGraphic.playlistGraphics) return;
      //Insert updated playlistGraphic into playlistGraphics array
      playlistGraphic.playlistGraphics = playlistGraphic.playlistGraphics?.map((graphic) =>
        graphic.id === payload.id ? payload : graphic
      );
      //Insert updated playlistGraphic into selectedPlaylistGraphic
      if (playlistGraphic?.selectedPlaylistGraphic?.id === payload.id) {
        playlistGraphic.selectedPlaylistGraphic = payload;
      }
    },
    failedUpdatePlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.error = error;
    },
    // GET ALL
    requestGetPlaylistGraphics: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = true;
    },
    receiveGetPlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload }: PayloadAction<PlaylistGraphicsResponseType | null>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.playlistGraphics = payload;

      if (payload) {
        playlistGraphic.selectedPlaylistGraphic =
          payload.find((graphic) => graphic.id === playlistGraphic.selectedPlaylistGraphic?.id) ||
          null;
      }
    },
    failedGetPlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.error = error;
    },
    // SET SELECTED
    requestSetSelectedPlaylistGraphic: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.error = null;
    },
    receiveSetSelectedPlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload }: PayloadAction<PlaylistGraphicResponseType | null>
    ) => {
      playlistGraphic.selectedPlaylistGraphic = payload;
    },
    failedSetSelectedPlaylistGraphic: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.error = error;
    },
    // REORDER
    requestReorderPlaylistGraphics: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.error = null;
    },
    receiveReorderPlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: playlistGraphicIds }: PayloadAction<string[]>
    ) => {
      if (!playlistGraphic.playlistGraphics) return;
      playlistGraphic.playlistGraphics.sort((a, b) => {
        const indexA = playlistGraphicIds.indexOf(a.id);
        const indexB = playlistGraphicIds.indexOf(b.id);
        if (indexA < indexB) {
          return -1;
        }
        if (indexA > indexB) {
          return 1;
        }
        return 0;
      });
    },
    failedReorderPlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.error = error;
    },
    // UPDATE STATUS
    requestUpdateStatusPlaylistGraphics: () => {},
    receiveUpdateStatusPlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload }: PayloadAction<PlaylistGraphicsResponseType>
    ) => {
      if (!playlistGraphic.playlistGraphics) return;

      const result = playlistGraphic.playlistGraphics?.reduce<PlaylistGraphicsType>(
        (acc, graphic) => {
          // Should exclude playlistgrahics that are not in current array
          if (!playlistGraphic.playlistGraphics?.find((g) => g.id === graphic.id)) return acc;

          const updatedGraphic = payload.find((g) => g.id === graphic.id);
          if (updatedGraphic) {
            acc.push({ ...graphic, status: updatedGraphic.status });
            return acc;
          }
          graphic.status = {
            ...graphic.status,
            live: false,
          };
          //Insert updated playlistGraphic into selectedPlaylistGraphic
          if (playlistGraphic?.selectedPlaylistGraphic?.id === graphic.id) {
            playlistGraphic.selectedPlaylistGraphic = graphic;
          }
          acc.push(graphic);
          return acc;
        },
        []
      );

      playlistGraphic.playlistGraphics = result;
    },
    failedUpdateStatusPlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.error = error;
    },
    // UPDATE MULTIPLE
    requestUpdatePlaylistGraphics: (playlistGraphic: PlaylistGraphicStateType) => {
      playlistGraphic.loading = true;
    },
    receiveUpdatePlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload }: PayloadAction<PlaylistGraphicsResponseType>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.playlistGraphics = updatePlaylistGraphics(
        payload,
        playlistGraphic.playlistGraphics
      );

      if (playlistGraphic.selectedPlaylistGraphic) {
        const selectedPlaylistGraphic = payload.find(
          (graphic) => graphic.id === playlistGraphic.selectedPlaylistGraphic?.id
        );
        if (selectedPlaylistGraphic) {
          playlistGraphic.selectedPlaylistGraphic = selectedPlaylistGraphic;
        }
      }
    },
    failedUpdatePlaylistGraphics: (
      playlistGraphic: PlaylistGraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlistGraphic.loading = false;
      playlistGraphic.error = error;
    },
  },
});

const actions = playlistGraphicSlice.actions;
const playlistGraphicReducer = playlistGraphicSlice.reducer;

export { actions, playlistGraphicReducer };
