import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REQUEST_GET_SPORT_DATA_TOURNAMENT_CALENDAR } from 'src/redux/reducers/sportData';
import { getCategory, getPropertyOptionsSportData } from './utils';

import Standings from './Standings';
import Fixtures from './Fixtures';
import MatchStats from './MatchStats';
import Select from 'src/components/Select';
import { FormGroup, Text } from '@southfields-digital/mpxlive-components';

import styles from './SportData.module.scss';

type Props = {
  selectedGraphic: PlaylistGraphic;
  onImport: (propertyId: string, value: string | PropertyValue[]) => void;
};

export default function SportData({ selectedGraphic, onImport }: Props) {
  const dispatch = useDispatch();
  const [property, setProperty] = useState<string>('');

  const selectedProperty = selectedGraphic.graphic.properties.find((p) => p.id === property);
  const category = getCategory(selectedGraphic.graphic.ref, selectedProperty);

  const handleImportStandings = (value: PropertyValue[]) => {
    if (!property) return;
    onImport(property, value);
  };
  const handleImportMatchStat = (value: string) => {
    if (!property) return;
    onImport(property, value);
  };
  const handleImportFixtures = (value: PropertyValue[]) => {
    if (!property) return;
    onImport(property, value);
  };

  useEffect(() => {
    dispatch({
      type: REQUEST_GET_SPORT_DATA_TOURNAMENT_CALENDAR,
    });
  }, []);

  return (
    <div className={styles.Container}>
      <Text as="h2">Import Data</Text>
      <div className={styles.Content}>
        <FormGroup className={styles.InputProperties} label="Selected property">
          <Select
            value={property}
            options={getPropertyOptionsSportData(
              selectedGraphic.graphic.ref,
              selectedGraphic.graphic.properties
            )}
            onChange={(e) => setProperty(e.currentTarget.value)}
          />
        </FormGroup>
        {category === 'Standings' && selectedProperty && (
          <Standings
            handleImportData={handleImportStandings}
            selectedGraphic={selectedGraphic}
            selectedProperty={selectedProperty as RepeaterProperties}
          />
        )}
        {category === 'Fixtures' && selectedProperty && (
          <Fixtures
            handleImportData={handleImportFixtures}
            selectedGraphic={selectedGraphic}
            selectedProperty={selectedProperty as RepeaterProperties}
          />
        )}
        {category === 'Statistics' && <MatchStats handleImportData={handleImportMatchStat} />}
      </div>
    </div>
  );
}
