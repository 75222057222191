import type { AxiosResponse, AxiosError } from 'axios';

export class ApiError extends Error {
  static handleError(error: AxiosError | string | undefined) {
    if ((error as AxiosError)?.response) {
      const response: AxiosResponse = (error as AxiosError)?.response as AxiosResponse;

      if (response?.data) {
        if (response.data?.response?.error) {
          throw new Error(response.data.response.error);
        }

        if (response.data?.message) {
          throw new Error(response.data.message);
        }

        if (response.data?.error) {
          throw new Error(response.data.error);
        }

        throw new Error(JSON.stringify(response.data));
      }
    }

    throw new Error(error as string | undefined);
  }
}
