import { useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';

import { Text } from '@southfields-digital/mpxlive-components';

import styles from './RundownHeader.module.scss';

export default function RundownHeader() {
  const { data } = useSelector((state: StateType) => state.rundown.detail);
  if (!data) return null;

  return (
    <div className={styles.Container}>
      <Text as="h2">{data.name}</Text>
    </div>
  );
}
