import { ChangeEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { TennisMatchControl } from 'src/types/matchControl';
import { TennisContestant } from 'src/types/matchControl/contestant';
import { TennisScore, TENNIS_POINTS } from 'src/types/matchControl/score';
import { Control } from 'src/types/matchControl/control';

import { MatchControlButton, MatchControlIcon } from '../components';
import { Button, Input, tailwindPreset } from '@southfields-digital/mpxlive-components';
import Points from '../components/Points';

const SCORE_BUG_ID = 'scorebug';

type TennisProps = {
  matchControl: TennisMatchControl;
  contestant1: TennisContestant;
  contestant2: TennisContestant;
  handleUpdateMatchControl: (matchControl: TennisMatchControl) => void;
  handleDispatchControl: (control: Control | undefined) => void;
  matchControlGraphics: Control[];
};

const TennisScorecard = ({
  scoreData,
  contestant1,
  contestant2,
}: {
  scoreData: TennisScore[];
  contestant1: TennisContestant;
  contestant2: TennisContestant;
}) => {
  const currentScoreCotestant1 =
    scoreData.length > 0
      ? scoreData[scoreData.length - 1].isTiebreak
        ? scoreData[scoreData.length - 1].tiebreak.contestant1
        : scoreData[scoreData.length - 1].currentScore.contestant1
      : '0';

  const currentScoreCotestant2 = scoreData.length
    ? scoreData[scoreData.length - 1].isTiebreak
      ? scoreData[scoreData.length - 1].tiebreak.contestant2
      : scoreData[scoreData.length - 1].currentScore.contestant2
    : '0';

  const cellStyle = {
    textAlign: 'center',
    borderLeft: '1px solid #4D4D5B',
    padding: '8px',
    width: '40px',
  } as const;

  const nameCellStyle = {
    textAlign: 'left',
    paddingLeft: '16px',
    width: '100%',
    overflow: 'hidden',
  } as const;

  const currentGameCellStyle = {
    ...cellStyle,
    color: tailwindPreset.theme.extend.colors.primary,
  };

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: '#151621' }}>
      <tbody>
        <tr style={{ borderBottom: '1px solid #4D4D5B' }}>
          <td style={nameCellStyle}>{contestant1.name}</td>
          {scoreData.map((set, index) => (
            <td key={index} style={cellStyle}>
              {set.games.contestant1}
            </td>
          ))}
          <td style={currentGameCellStyle}>{currentScoreCotestant1}</td>
        </tr>
        <tr>
          <td style={nameCellStyle}>{contestant2.name}</td>
          {scoreData.map((set, index) => (
            <td key={index} style={cellStyle}>
              {set.games.contestant2}
            </td>
          ))}
          <td style={currentGameCellStyle}>{currentScoreCotestant2}</td>
        </tr>
      </tbody>
    </table>
  );
};

const CurrentTennisSet = ({
  set,
  contestant1,
  contestant2,
  handleChangeSet,
}: {
  set: TennisScore;
  contestant1: TennisContestant;
  contestant2: TennisContestant;
  handleChangeSet: (set: TennisScore) => void;
}) => {
  const isTiebreak = set.isTiebreak;

  const pointsContestant1 = set.currentScore.contestant1;
  const pointsContestant2 = set.currentScore.contestant2;

  const tieBreakPointsContestant1 = set.tiebreak.contestant1;
  const tieBreakPointsContestant2 = set.tiebreak.contestant2;

  const nameContestant1 = contestant1.name;
  const nameContestant2 = contestant2.name;

  const gamesContestant1 = set.games.contestant1;
  const gamesContestant2 = set.games.contestant2;

  return (
    <div className="flex items-center gap-2 [&>div]:flex [&>div]:gap-2 [&>div]:flex-col px-4">
      <div className="flex-auto min-w-0 [&>div]:overflow-hidden [&>div]:text-ellipsis [&>div]:line-clamp-1">
        <div>{nameContestant1}</div>
        <div>{nameContestant2}</div>
      </div>
      <div className="flex-2">
        <div className="flex gap-2">
          {isTiebreak ? (
            <>
              <Points
                points={tieBreakPointsContestant1}
                handleAddPoint={() =>
                  handleChangeSet({
                    ...set,
                    tiebreak: { ...set.tiebreak, contestant1: set.tiebreak.contestant1 + 1 },
                  })
                }
                handleRemovePoint={() =>
                  handleChangeSet({
                    ...set,
                    tiebreak: {
                      ...set.tiebreak,
                      contestant1: Math.max(set.tiebreak.contestant1 - 1, 0),
                    },
                  })
                }
              />
            </>
          ) : (
            <>
              {TENNIS_POINTS.map((point) => (
                <Button
                  className={classNames({
                    '!px-2 !py-1 !text-sm !leading-none': true,
                    'hover:!bg-primary': true,
                    '!bg-primary': pointsContestant1 === point,
                    '!bg-[#313342]': pointsContestant1 !== point,
                  })}
                  key={point}
                  onClick={() =>
                    handleChangeSet({
                      ...set,
                      currentScore: { ...set.currentScore, contestant1: point },
                    })
                  }
                >
                  {point}
                </Button>
              ))}
            </>
          )}
        </div>
        <div className="flex flex-2 gap-2">
          {isTiebreak ? (
            <>
              <Points
                points={tieBreakPointsContestant2}
                handleAddPoint={() =>
                  handleChangeSet({
                    ...set,
                    tiebreak: { ...set.tiebreak, contestant2: set.tiebreak.contestant2 + 1 },
                  })
                }
                handleRemovePoint={() =>
                  handleChangeSet({
                    ...set,
                    tiebreak: {
                      ...set.tiebreak,
                      contestant2: Math.max(set.tiebreak.contestant2 - 1, 0),
                    },
                  })
                }
              />
            </>
          ) : (
            <>
              {TENNIS_POINTS.map((point) => (
                <Button
                  className={classNames({
                    '!px-2 !py-1 !text-sm !leading-none': true,
                    'hover:!bg-primary': true,
                    '!bg-primary': pointsContestant2 === point,
                    '!bg-[#313342]': pointsContestant2 !== point,
                  })}
                  key={point}
                  onClick={() =>
                    handleChangeSet({
                      ...set,
                      currentScore: { ...set.currentScore, contestant2: point },
                    })
                  }
                >
                  {point}
                </Button>
              ))}
            </>
          )}
        </div>
      </div>
      <div>
        <Points
          points={gamesContestant1}
          handleAddPoint={() =>
            handleChangeSet({
              ...set,
              games: { ...set.games, contestant1: set.games.contestant1 + 1 },
            })
          }
          handleRemovePoint={() =>
            handleChangeSet({
              ...set,
              games: { ...set.games, contestant1: Math.max(set.games.contestant1 - 1, 0) },
            })
          }
        />
        <Points
          points={gamesContestant2}
          handleAddPoint={() =>
            handleChangeSet({
              ...set,
              games: { ...set.games, contestant2: set.games.contestant2 + 1 },
            })
          }
          handleRemovePoint={() =>
            handleChangeSet({
              ...set,
              games: { ...set.games, contestant2: Math.max(set.games.contestant2 - 1, 0) },
            })
          }
        />
      </div>
    </div>
  );
};

type TennisSetsProps = {
  selectedSetId?: string;
  handleChangeSelectedSetId: (setId: string) => void;
  sets: TennisScore[];
  contestant1: TennisContestant;
  contestant2: TennisContestant;
  handleChangeSets: (sets: TennisScore[]) => void;
};

const TennisSets = ({
  selectedSetId,
  handleChangeSelectedSetId,
  sets,
  handleChangeSets,
  contestant1,
  contestant2,
}: TennisSetsProps) => {
  const currentSet = sets.find((set) => set.id === selectedSetId);

  const handleAddSet = () => {
    const newSets: TennisScore[] = [
      ...sets,
      {
        id: uuidv4(),
        games: { contestant1: 0, contestant2: 0 },
        tiebreak: { contestant1: 0, contestant2: 0 },
        isTiebreak: false,
        currentScore: { contestant1: '0', contestant2: '0' },
      },
    ];
    handleChangeSets(newSets);
    handleChangeSelectedSetId(newSets[newSets.length - 1].id);
  };

  const handleDeleteSet = (id?: string) => {
    if (!sets.length || !id) return;
    const newSets: TennisScore[] = sets.filter((set) => set.id !== id);
    handleChangeSets(newSets);
    const newSelectedSetId = newSets.length ? newSets[newSets.length - 1].id : undefined;
    handleChangeSelectedSetId(newSelectedSetId || '');
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between gap-2 px-4 border-b border-[#151621] min-h-[43px]">
        <div className="flex items-center gap-2">
          {sets.map((set, index) => (
            <Button
              key={index}
              className={classNames({
                '!text-[#777784] !border-transparent': selectedSetId !== set.id,
                '!border-primary': selectedSetId === set.id,
                '!bg-[#232432] !px-0 !border-b-2 !border-t-0 !border-l-0 !border-r-0 focus:!ring-0 !rounded-none':
                  true,
              })}
              onClick={() => handleChangeSelectedSetId(set.id)}
            >
              Set {index + 1}
            </Button>
          ))}
          <MatchControlButton variant="icon" onClick={handleAddSet}>
            <MatchControlIcon iconProps={{ icon: 'plus' }} />
          </MatchControlButton>
        </div>
        <div className="flex items-center gap-2">
          <MatchControlButton variant="icon" onClick={() => handleDeleteSet(selectedSetId)}>
            <MatchControlIcon iconProps={{ icon: 'trash' }} />
          </MatchControlButton>
        </div>
      </div>
      <div className="py-2">
        {currentSet ? (
          <CurrentTennisSet
            handleChangeSet={(set) =>
              handleChangeSets(sets.map((s) => (s.id === set.id ? set : s)))
            }
            set={currentSet}
            contestant1={contestant1}
            contestant2={contestant2}
          />
        ) : null}
      </div>
    </div>
  );
};

const Tennis = ({
  matchControl,
  handleUpdateMatchControl,
  handleDispatchControl,
  matchControlGraphics,
  contestant1,
  contestant2,
}: TennisProps) => {
  const [selectedSetId, setSelectedSetId] = useState('');
  const sets = matchControl.score;
  const selectedSet = sets.find((set) => set.id === selectedSetId);

  const handleChangeSets = (sets: TennisScore[]) =>
    handleUpdateMatchControl({ ...matchControl, score: sets });

  const handleChangeServer = (server: 'contestant1' | 'contestant2') =>
    handleUpdateMatchControl({ ...matchControl, server });

  return (
    <div className="flex flex-col gap-2 py-4 text-white">
      {/* Current server / Scorecard + */}
      <div className="flex flex-row gap-4 items-stretch px-4">
        <div className="flex flex-col gap-2 justify-evenly">
          <MatchControlButton
            className={classNames({
              '!bg-primary': matchControl.server === 'contestant1',
            })}
            variant="icon"
            onClick={() => handleChangeServer('contestant1')}
          >
            <MatchControlIcon iconProps={{ icon: 'tennis-ball', weight: 'fill' }} />
          </MatchControlButton>
          <MatchControlButton
            className={classNames({
              '!bg-primary': matchControl.server === 'contestant2',
            })}
            variant="icon"
            onClick={() => handleChangeServer('contestant2')}
          >
            <MatchControlIcon iconProps={{ icon: 'tennis-ball', weight: 'fill' }} />
          </MatchControlButton>
        </div>
        <div>
          <TennisScorecard scoreData={sets} contestant1={contestant1} contestant2={contestant2} />
        </div>
      </div>
      {/* Sets panel */}
      <div>
        <TennisSets
          selectedSetId={selectedSetId}
          handleChangeSelectedSetId={(setId) => setSelectedSetId(setId)}
          sets={sets}
          contestant1={contestant1}
          contestant2={contestant2}
          handleChangeSets={handleChangeSets}
        />
      </div>
      {/* Score bug in + tiebreak toggle */}
      <div className="flex justify-between [&>button]:h-7 px-4">
        <MatchControlButton
          onClick={() =>
            handleDispatchControl(matchControlGraphics?.find((c) => c.controlId === SCORE_BUG_ID))
          }
          isRunning={Boolean(
            matchControlGraphics?.find(
              (control) => control.controlId === SCORE_BUG_ID && control.live
            )
          )}
        >
          SCORE BUG IN
        </MatchControlButton>
        <Input
          className={classNames({
            'flex items-center': true,
          })}
          key={selectedSet?.id}
          type="checkbox"
          label="Tiebreak"
          checked={selectedSet?.isTiebreak}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (!selectedSet) return;
            handleChangeSets(
              sets.map((s) =>
                s.id === selectedSetId ? { ...selectedSet, isTiebreak: event.target.checked } : s
              )
            );
          }}
        />
      </div>
    </div>
  );
};

export default Tennis;
