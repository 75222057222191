import { io } from 'socket.io-client';
import { debounce } from 'lodash';

type Listeners = {
  [key: string]: (...args: any[]) => void;
};

type WebsocketBuddyArguments = {
  channel: string | null;
  driver: 'SocketIo' | 'Pusher' | null;
  listeners: Listeners;
};

const SocketIOInstance = (channelId: string, listeners: Listeners) => {
  const socket = io(import.meta.env.VITE_SERVICE_GRAPHICS_WEBSOCKET_URL, {
    withCredentials: true, // Required for sticky sessions (socket.io is load balanced)
    transports: ['websocket', 'polling'], // Lets try websocket first
  });

  socket.on('connect', () => {
    console.log('Subscribing to channel', channelId);
    socket.emit('attachId', channelId);
  });

  socket.on('disconnect', () => {
    console.log('Unsubscribing from channel', channelId);
  });

  Object.keys(listeners).forEach((listener) => {
    const callback = debounce((...args: unknown[]) => listeners[listener](...args), 150);

    if ('function' !== typeof callback) {
      throw new Error(`Listener callback must be a function, but got ${typeof callback}`);
    }

    socket.on(listener, callback);
  });

  return { io, socket };
};

export const WebsocketBuddy = ({
  channel = null,
  driver = null,
  listeners = {},
}: WebsocketBuddyArguments) => {
  if (!channel) {
    throw new Error(`Channel is required`);
  }

  if (!driver) {
    throw new Error(`Driver is required`);
  }

  switch (driver) {
    case 'SocketIo':
      return SocketIOInstance(channel, listeners);
    default:
      throw new Error(`Driver ${driver} not supported`);
  }
};
