export type LocalProperties = { [key: string]: { value: any } & Properties };

const getMappedProperties = (playlistGraphic: PlaylistGraphic | null) => {
  if (!playlistGraphic || !playlistGraphic.graphic) return null;

  return playlistGraphic.graphic.properties.reduce<LocalProperties>((acc, property) => {
    acc[property.id] = {
      ...property,
      value: playlistGraphic.propertiesValues[property.id] ?? property.defaultValue ?? '',
    };
    return acc;
  }, {});
};

const getPropertiesValues = (properties: LocalProperties) => {
  return Object.values(properties).reduce<PropertyValue>((acc, property) => {
    acc[property.id] = property.value;
    return acc;
  }, {});
};

export { getMappedProperties, getPropertiesValues };
