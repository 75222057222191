import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import { getMappedProperties, getPropertiesValues, LocalProperties } from './utils';
import { REQUEST_UPDATE_PLAYLIST_GRAPHIC } from 'src/redux/reducers/playlistGraphic';
import useKeypress from 'src/hooks/useKeypress';

import { Transition } from '@headlessui/react';
import SportData, { getPropertyOptionsSportData } from 'src/components/SportData';
import GraphicControlForm from 'src/components/GraphicControlForm';
import GraphicPropertiesForm from 'src/components/GraphicPropertiesForm';
import GraphicMediaLibrary from 'src/components/GraphicMediaLibrary';

import styles from './GraphicDetails.module.scss';

export default function GraphicDetails() {
  const dispatch = useDispatch();
  const [mediaLibraryState, setMediaLibraryState] = useState<{ open: boolean; onClose?: Function }>(
    { open: false, onClose: () => {} }
  );
  const [showSportData, setShowSportData] = useState<boolean>(false);
  const [canHideLibrary, setCanHideLibrary] = useState<boolean>(true);

  const { selectedPlaylistGraphicId, playlistGraphics, selectedPlaylistGraphic } = useSelector(
    (state: StateType) => state.playlistGraphic
  );

  const selectedGraphic = useMemo(
    () =>
      playlistGraphics?.find((graphic) => graphic.id === selectedPlaylistGraphicId) ||
      selectedPlaylistGraphic,
    [playlistGraphics, selectedPlaylistGraphic, selectedPlaylistGraphicId]
  );

  const [localProperties, setLocalProperties] = useState<LocalProperties | null>(null);

  useEffect(() => {
    handleClickOutsideDrawer();
    setLocalProperties(getMappedProperties(selectedGraphic));
  }, [selectedGraphic?.id]);

  const handleUpdateProperties = (properties: LocalProperties) => {
    setLocalProperties(properties);

    dispatch({
      type: REQUEST_UPDATE_PLAYLIST_GRAPHIC,
      payload: {
        graphic: {
          ...selectedGraphic,
          propertiesValues: getPropertiesValues(properties),
        },
        requestDelay: 250,
      },
    });
  };

  const handleRequestSportData = () => {
    setShowSportData(true);
  };

  const handleImportSportData = (propertyId: string, value: string | any[]) => {
    if (!propertyId) return;

    const property = localProperties ? localProperties[propertyId] : null;
    if (property) {
      const updatedProperty = { ...property, value };
      const updatedLocalProperties: LocalProperties = {
        ...localProperties,
        [propertyId]: updatedProperty,
      };

      handleUpdateProperties(updatedLocalProperties);
    }

    setShowSportData(false);
  };

  const handleRequestMedia = (properties: Properties) => {
    const onClose = (url: string) => {
      handleUpdateProperties({
        ...localProperties,
        [properties.id]: { ...properties, value: url },
      });

      if (selectedGraphic && !selectedGraphic.graphic.config.isSwappable) {
        setMediaLibraryState({ open: false, onClose: () => {} });
      }
    };

    setMediaLibraryState({
      open: true,
      onClose,
    });
  };

  const handleMediaSelected = (url: string) => {
    mediaLibraryState.onClose?.(url);
  };

  const handleClickOutsideDrawer = () => {
    setMediaLibraryState({ open: false, onClose: () => {} });
    setShowSportData(false);
  };

  useKeypress('Escape', {}, () => canHideLibrary && handleClickOutsideDrawer());

  if (!selectedGraphic) return null;

  const slideOutOpen = mediaLibraryState.open || showSportData;

  return (
    <div className={styles.Container}>
      <GraphicControlForm
        selectedGraphic={selectedGraphic}
        showStatsPerform={
          getPropertyOptionsSportData(
            selectedGraphic.graphic.ref,
            selectedGraphic.graphic.properties
          ).length > 1
            ? handleRequestSportData
            : undefined
        }
      />
      <div className={styles.Content}>
        {/* <ErrorBoundary> */}
        <GraphicPropertiesForm
          onClickMedia={handleRequestMedia}
          properties={localProperties}
          handleChangeGraphicProperties={(properties) => handleUpdateProperties(properties)}
        />
        {/* </ErrorBoundary> */}
        <Transition.Root show={slideOutOpen} className={styles.SlideOutContainer}>
          <Transition.Child
            className={styles.Backdrop}
            onClick={handleClickOutsideDrawer}
            as="div"
            leave="transition-opacity"
            leaveFrom="opacity-25"
            leaveTo="opacity-0"
          />

          <Transition.Child
            className={styles.SlideOutContent}
            enter="transition-all duration-100"
            enterFrom="transform translate-x-[75%]"
            enterTo="transform translate-x-0"
            leave="transition-all duration-100"
            leaveFrom="transform translate-x-0"
            leaveTo="transform translate-x-[75%]"
          >
            {mediaLibraryState.open && (
              <GraphicMediaLibrary
                setCanHideLibrary={setCanHideLibrary}
                onFileSelect={handleMediaSelected}
              />
            )}

            {showSportData && (
              <SportData selectedGraphic={selectedGraphic} onImport={handleImportSportData} />
            )}
          </Transition.Child>
        </Transition.Root>
      </div>
    </div>
  );
}
