import { MatchControlVariant } from '../types';

import ShortName from './ShortName';
import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';
import { Text } from '@southfields-digital/mpxlive-components';

import styles from '../MatchControl.module.scss';

interface IProps {
  variant?: MatchControlVariant;
  teamName?: string;
  redCards: number;
  disabled?: boolean;
  inverted?: boolean;
  handleChangeCards?: (amount: number) => void;
  handleChangeTeamName?: (name: string) => void;
}

const RedCards = ({
  teamName,
  handleChangeTeamName,
  redCards,
  disabled = false,
  inverted = false,
  handleChangeCards,
  variant = 'default',
}: IProps) => {
  const handleAddRedCard = () => handleChangeCards?.(redCards + 1);
  const handleSubtractRedCard = () => handleChangeCards?.(Math.max(redCards - 1, 0));

  if (variant === 'extended') {
    return (
      <div className="flex flex-col gap-2 items-center">
        <div className={styles.RedCardParagraphWrapper}>
          <Text as="h3">Red Cards</Text>
          <p className={styles.RedCardParagraph}>{redCards}</p>
        </div>
        <div className="flex gap-4">
          <MatchControlButton
            className={styles.MainButton}
            onClick={handleSubtractRedCard}
            disabled={disabled}
          >
            REMOVE RED CARD
          </MatchControlButton>
          <MatchControlButton
            className={styles.MainButton}
            onClick={handleAddRedCard}
            disabled={disabled}
          >
            ADD RED CARD
          </MatchControlButton>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.RedCardsMainWrapper}>
      {teamName && (
        <ShortName
          shortName={teamName}
          handleChangeShortName={handleChangeTeamName}
          inverted={inverted}
        />
      )}

      <div className={styles.RedCardWrapper}>
        <MatchControlButton variant="icon" onClick={handleSubtractRedCard} disabled={disabled}>
          <MatchControlIcon iconProps={{ icon: 'minus' }} />
        </MatchControlButton>

        <div className={styles.RedCardParagraphWrapper}>
          <p className={styles.RedCardParagraph}>{redCards}</p>
        </div>

        <MatchControlButton variant="icon" onClick={handleAddRedCard} disabled={disabled}>
          <MatchControlIcon iconProps={{ icon: 'plus' }} />
        </MatchControlButton>
      </div>
    </div>
  );
};

export default RedCards;
