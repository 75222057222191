import { MatchControl } from 'src/types/matchControl';
import { Control } from 'src/types/matchControl/control';
import { SportCategory } from 'src/types/matchControl/sport';

export type MatchControlsStateType = {
  categories: {
    loading: boolean;
    error: string | null;
    data: SportCategory[];
  };
  skeleton: {
    loading: boolean;
    error: string | null;
    data: MatchControl | null;
  };
  matchControl: {
    loading: boolean;
    error: string | null;
    data: MatchControl | null;
  };
  matchControlGraphics: {
    loading: boolean;
    error: string | null;
    data: (Control & { live: boolean })[] | null;
  };
};

export const MATCH_CONTROLS = 'matchControls';
export type MATCH_CONTROLS = typeof MATCH_CONTROLS;

export const REQUEST_GET_MATCH_CONTROL_TYPES = `${MATCH_CONTROLS}/requestGetMatchControlTypes`;
export type REQUEST_GET_MATCH_CONTROL_TYPES = typeof REQUEST_GET_MATCH_CONTROL_TYPES;

export const REQUEST_GET_MATCH_CONTROL_SKELETON = `${MATCH_CONTROLS}/requestGetMatchControlSkeleton`;
export type REQUEST_GET_MATCH_CONTROL_SKELETON = typeof REQUEST_GET_MATCH_CONTROL_SKELETON;

export const REQUEST_GET_MATCH_CONTROL = `${MATCH_CONTROLS}/requestGetMatchControl`;
export type REQUEST_GET_MATCH_CONTROL = typeof REQUEST_GET_MATCH_CONTROL;

export const REQUEST_UPDATE_MATCH_CONTROL = `${MATCH_CONTROLS}/requestUpdateMatchControl`;
export type REQUEST_UPDATE_MATCH_CONTROL = typeof REQUEST_UPDATE_MATCH_CONTROL;

export const REQUEST_UPDATE_MATCH_CONTROL_SHOOT_OUT = `${MATCH_CONTROLS}/requestUpdateMatchControlShootOut`;
export type REQUEST_UPDATE_MATCH_CONTROL_SHOOT_OUT = typeof REQUEST_UPDATE_MATCH_CONTROL_SHOOT_OUT;

export const REQUEST_GET_MATCH_CONTROL_CONTROLS = `${MATCH_CONTROLS}/requestGetMatchControlControls`;
export type REQUEST_GET_MATCH_CONTROL_CONTROLS = typeof REQUEST_GET_MATCH_CONTROL_CONTROLS;

export const REQUEST_UPDATE_MATCH_CONTROL_GRAPHIC = `${MATCH_CONTROLS}/requestUpdateMatchControlGraphic`;
export type REQUEST_UPDATE_MATCH_CONTROL_GRAPHIC = typeof REQUEST_UPDATE_MATCH_CONTROL_GRAPHIC;

export const REQUEST_UPDATE_MATCH_CONTROL_CLOCK = `${MATCH_CONTROLS}/requestUpdateMatchControlClock`;
export type REQUEST_UPDATE_MATCH_CONTROL_CLOCK = typeof REQUEST_UPDATE_MATCH_CONTROL_CLOCK;

export const REQUEST_STOP_MATCH_CONTROL_CLOCK = `${MATCH_CONTROLS}/requestStopMatchControlClock`;
export type REQUEST_STOP_MATCH_CONTROL_CLOCK = typeof REQUEST_STOP_MATCH_CONTROL_CLOCK;

export const REQUEST_START_MATCH_CONTROL_CLOCK = `${MATCH_CONTROLS}/requestStartMatchControlClock`;
export type REQUEST_START_MATCH_CONTROL_CLOCK = typeof REQUEST_START_MATCH_CONTROL_CLOCK;

export const REQUEST_UPDATE_MATCH_CONTROL_CONTESTANT = `${MATCH_CONTROLS}/requestUpdateMatchControlContestant`;
export type REQUEST_UPDATE_MATCH_CONTROL_CONTESTANT =
  typeof REQUEST_UPDATE_MATCH_CONTROL_CONTESTANT;

export const REQUEST_CREATE_MATCH_CONTROL_EVENT = `${MATCH_CONTROLS}/requestCreateMatchControlEvent`;
export type REQUEST_CREATE_MATCH_CONTROL_EVENT = typeof REQUEST_CREATE_MATCH_CONTROL_EVENT;

export const REQUEST_DELETE_MATCH_CONTROL_EVENT = `${MATCH_CONTROLS}/requestDeleteMatchControlEvent`;
export type REQUEST_DELETE_MATCH_CONTROL_EVENT = typeof REQUEST_DELETE_MATCH_CONTROL_EVENT;
