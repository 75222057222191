import type { FallbackComponentProps } from 'src/components/ErrorBoundary';
import BaseLayout from 'src/layouts/BaseLayout';
import DefaultBoundary from 'src/components/ErrorBoundary/DefaultBoundary';

const InternalServerError = (props: FallbackComponentProps) => {
  return (
    <BaseLayout>
      <DefaultBoundary {...props} />
    </BaseLayout>
  );
};

export default InternalServerError;
