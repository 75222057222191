export const getRawPath = (path: string) => {
  const pathComponents = path.split('/');

  return pathComponents
    .map((pathComponent, index) => {
      if (index === 0 || index === pathComponents.length - 1) {
        return pathComponent;
      }

      if (index === pathComponents.length - 2) {
        return pathComponent;
      }

      return null;
    })
    .filter((pathComponent) => !!pathComponent)
    .join('/');
};
