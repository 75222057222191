import { Button, Text } from '@southfields-digital/mpxlive-components';
import { IconNames } from '@southfields-digital/mpxlive-components/build/components/Icon';

import styles from './PanelHeader.module.scss';

interface IPanelHeader {
  header?: React.ReactNode;
  onClick?: () => void;
  icon?: IconNames;
  canEdit?: boolean;
}

export default function PanelHeader({ header, icon, onClick, canEdit }: IPanelHeader) {
  const iconProps = icon ? { icon: 'plus', color: '#ffffff', weight: 'bold' } : null;
  return (
    <div className={styles.PanelHeader}>
      {typeof header === 'string' ? (
        <Text as="h4" className={styles.Header}>
          {header}
        </Text>
      ) : (
        header
      )}
      {onClick && canEdit !== false && (
        <Button
          variant="secondary"
          onClick={onClick}
          buttonSize="medium"
          className={styles.PanelButton}
          iconProps={iconProps}
        />
      )}
    </div>
  );
}
