import { useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import { Button, Text } from '@southfields-digital/mpxlive-components';

import styles from './ActionButtons.module.scss';

interface IActionButtonsProps {
  isActive: boolean;
  text?: string;
  children?: React.ReactNode;
  showAuto?: boolean;
  autoEnabled?: boolean;
  onClickAutoPlay?: () => void;
}

function ActionButtons({
  text,
  children,
  isActive,
  autoEnabled = false,
  showAuto = false,
  onClickAutoPlay,
}: IActionButtonsProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const id = useMemo(() => uniqueId('buttons-'), []);

  const openMenu = () => {
    setMenuOpen(true);
    menuRef.current?.focus();
  };

  const closeMenu = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    // No related target means the user clicked outside the menu
    if (e.relatedTarget === null) {
      setMenuOpen(false);
      return;
    }

    // If the related target is a child of the menu, we don't want to close it
    const isRelatedTargetInChildren = menuRef.current?.contains(e.relatedTarget as Node);
    if (!isRelatedTargetInChildren) {
      setMenuOpen(false);
    }
  };

  return (
    <div
      className={classNames(styles.Container, { [styles.Active]: isActive })}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={`${styles.Extra}`}>
        {!menuOpen ? (
          <Text className={`${styles.Text}`} as="p">
            {text}
          </Text>
        ) : null}

        {showAuto ? (
          <Button
            iconProps={{ icon: 'arrow-clockwise' }}
            buttonSize="xs"
            variant={autoEnabled ? 'danger' : 'secondary'}
            onClick={onClickAutoPlay}
          />
        ) : null}

        <Button
          iconProps={{ icon: 'ellipsis-horizontal', color: '#5B5D70' }}
          buttonSize="medium"
          color="grey"
          variant="tertiary"
          onClick={openMenu}
          className={styles.Button}
        />
      </div>
      <div
        id={id}
        tabIndex={0}
        ref={menuRef}
        onBlur={(e) => closeMenu(e)}
        className={styles.OptionsContainer}
      >
        <div className={`${styles.Options} ${menuOpen ? 'w-full ' : 'w-0'}`}>{children}</div>
      </div>
    </div>
  );
}

export default ActionButtons;
