import { toDatetimeLocal } from 'src/utils';
import { useSearchParams } from 'react-router-dom';

import { Input } from '@southfields-digital/mpxlive-components';

const DATE_PARAM = 'date';
export default function DateInput() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateParam = searchParams.get(DATE_PARAM) || '';
  const date = Date.parse(dateParam) ? dateParam : toDatetimeLocal(new Date()).date;

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const datetime = event.currentTarget.value;
    if (!datetime) return;

    searchParams.set(DATE_PARAM, toDatetimeLocal(datetime).date);
    setSearchParams(searchParams);
  };

  return <Input value={date} type="date" onChange={handleDateChange} />;
}
