import { useRef } from 'react';

import styles from '../MatchControl.module.scss';

type Props = {
  color: string;
  handleChangeColor: (color: string) => void;
};

export const ColorPicker = ({ color, handleChangeColor }: Props) => {
  const inputRef = useRef(null);

  const handleColorClick = (color: string) => {
    handleChangeColor(color);
  };

  return (
    <div className={styles.Flag}>
      <div className={styles.FlagPart} style={{ position: 'relative' }}>
        <div style={{ background: color, width: '100%' }} />
        <input
          type="color"
          ref={inputRef}
          style={{ opacity: 0, position: 'absolute', zIndex: 2, width: '100%', height: '100%' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleColorClick(e.currentTarget.value)
          }
        />
      </div>
    </div>
  );
};

export default ColorPicker;
