import {
  CallEffect,
  PutEffect,
  SelectEffect,
  call,
  delay,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  REQUEST_CREATE_MATCH_CONTROL_EVENT,
  REQUEST_DELETE_MATCH_CONTROL_EVENT,
  REQUEST_GET_MATCH_CONTROL,
  REQUEST_GET_MATCH_CONTROL_CONTROLS,
  REQUEST_GET_MATCH_CONTROL_SKELETON,
  REQUEST_GET_MATCH_CONTROL_TYPES,
  REQUEST_START_MATCH_CONTROL_CLOCK,
  REQUEST_STOP_MATCH_CONTROL_CLOCK,
  REQUEST_UPDATE_MATCH_CONTROL,
  REQUEST_UPDATE_MATCH_CONTROL_CLOCK,
  REQUEST_UPDATE_MATCH_CONTROL_CONTESTANT,
  REQUEST_UPDATE_MATCH_CONTROL_GRAPHIC,
  REQUEST_UPDATE_MATCH_CONTROL_SHOOT_OUT,
  matchControlSlice,
} from '../reducers/matchControls';
import api from 'src/services/graphicsApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { SportCategory } from 'src/types/matchControl/sport';
import { MatchControl } from 'src/types/matchControl';
import { Clock } from 'src/types/matchControl/clock';
import { Contestant } from 'src/types/matchControl/contestant';
import { Control } from 'src/types/matchControl/control';
import { NOTIFY } from '../reducers/notification';
import { ShootOut } from 'src/types/matchControl/shootOut';

type ApiResponseCategories = void | {
  data: SportCategory[];
  status: number;
};
function* getCategories(): Generator<
  CallEffect<ApiResponseCategories> | PutEffect | void,
  void,
  ApiResponseCategories
> {
  try {
    const response = yield call(api.get, '/matchControl/sports');
    if (response?.data) {
      yield put(matchControlSlice.actions.receiveGetMatchControlTypes(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(matchControlSlice.actions.failedGetMatchControlTypes('No data found'));
  }
}

type ApiResponseSkeleton = void | {
  data: MatchControl;
  status: number;
};
function* getSkeleton(
  action: PayloadAction<{ category: string | null; callback?: (...args: unknown[]) => void }>
): Generator<
  CallEffect<ApiResponseSkeleton> | PutEffect | SelectEffect,
  void,
  ApiResponseSkeleton
> {
  try {
    const { category, callback } = action.payload;
    if (!category) {
      yield put(matchControlSlice.actions.receiveGetMatchControlSkeleton(null));
      return;
    }

    const response = yield call(api.get, `/matchControl/sports/${category}`);

    if (response?.data) {
      yield put(matchControlSlice.actions.receiveGetMatchControlSkeleton(response.data));
      if (callback) callback(response.data);
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(matchControlSlice.actions.failedGetMatchControlSkeleton('Failed to get skeleton'));
  }
}

type ApiResponseMatchControl = void | {
  data: MatchControl;
  status: number;
};
function* getMatchControl(
  action: PayloadAction<{
    rundownId: string;
    callback?: () => void;
  }>
): Generator<
  CallEffect<ApiResponseMatchControl> | PutEffect | SelectEffect,
  void,
  ApiResponseMatchControl
> {
  try {
    const { rundownId, callback } = action.payload;

    if (callback) {
      yield delay(250);
    }

    if (!rundownId) {
      yield put(matchControlSlice.actions.receiveGetMatchControl(null));
      callback?.();
      return;
    }

    const response = yield call(api.get, `/rundowns/${rundownId}/matchControl`);
    yield put(matchControlSlice.actions.receiveGetMatchControl(response?.data || null));
    callback?.();
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(matchControlSlice.actions.failedGetMatchControl('Failed to get match control'));
  }
}

type ApiResponseMatchControlGraphics = void | {
  data: (Control & { live: boolean })[];
  status: number;
};
function* getMatchControlControls(
  action: PayloadAction<{
    rundownId: string;
  }>
): Generator<
  CallEffect<ApiResponseMatchControlGraphics> | PutEffect | SelectEffect,
  void,
  ApiResponseMatchControlGraphics
> {
  try {
    const { rundownId } = action.payload;

    const response = yield call(api.get, `/rundowns/${rundownId}/matchControl/graphics`);
    if (response?.data) {
      yield put(matchControlSlice.actions.receiveGetMatchControlControls(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(
      matchControlSlice.actions.failedGetMatchControlControls('Failed to get match controls')
    );
  }
}

type ApiResponseUpdateMatchControlGraphics = void | {
  data: (Control & { live: boolean })[];
  status: number;
};
function* toggleMatchControlGraphic(
  action: PayloadAction<{ rundownId: string; control: Control }>
): Generator<
  CallEffect<ApiResponseUpdateMatchControlGraphics> | PutEffect | SelectEffect,
  void,
  ApiResponseUpdateMatchControlGraphics
> {
  try {
    const { rundownId, control } = action.payload;
    const response = yield call(api.post, `/controls/rundown/${rundownId}/graphic/toggle`, control);

    if (response?.data) {
      yield put(matchControlSlice.actions.receiveUpdateMatchControlGraphic(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(
      matchControlSlice.actions.failedUpdateMatchControlGraphic('Failed to update graphic')
    );
  }
}

type ApiResponseClock = void | { data: Clock[]; status: number };
function* updateClock(
  action: PayloadAction<{ clock: Clock; callback?: (...args: unknown[]) => void }>
): Generator<CallEffect<ApiResponseClock> | PutEffect | SelectEffect, void, ApiResponseClock> {
  try {
    const { clock } = action.payload;
    const response = yield call(api.patch, `/matchControl/sports/clock/${clock.id}`, clock);
    if (response?.data) {
      yield put(matchControlSlice.actions.receiveUpdateClock(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(matchControlSlice.actions.failedUpdateClock('Failed to update clock'));
  }
}

function* startClock(
  action: PayloadAction<{ clock: Clock; callback?: (...args: unknown[]) => void }>
): Generator<CallEffect<ApiResponseClock> | PutEffect | SelectEffect, void, ApiResponseClock> {
  try {
    const { clock } = action.payload;
    const response = yield call(api.post, `/matchControl/sports/clock/${clock.id}/start`);
    if (response?.data) {
      yield put(matchControlSlice.actions.receiveUpdateClock(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(matchControlSlice.actions.failedUpdateClock('Failed to start clock'));
  }
}

function* stopClock(
  action: PayloadAction<{ clock: Clock; callback?: (...args: unknown[]) => void }>
): Generator<CallEffect<ApiResponseClock> | PutEffect | SelectEffect, void, ApiResponseClock> {
  try {
    const { clock } = action.payload;
    const response = yield call(api.post, `/matchControl/sports/clock/${clock.id}/stop`);
    if (response?.data) {
      yield put(matchControlSlice.actions.receiveUpdateClock(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(matchControlSlice.actions.failedUpdateClock('Failed to start clock'));
  }
}

type ApiResponseContestant = void | { data: Contestant; status: number };
function* updateContestant(
  action: PayloadAction<{ contestant: Contestant; callback?: (...args: unknown[]) => void }>
): Generator<
  CallEffect<ApiResponseContestant> | PutEffect | SelectEffect,
  void,
  ApiResponseContestant
> {
  try {
    yield delay(250);
    const { contestant } = action.payload;
    const response = yield call(
      api.patch,
      `/matchControl/sports/contestant/${contestant.id}`,
      contestant
    );
    if (response?.data) {
      yield put(matchControlSlice.actions.receiveUpdateMatchControlContestant(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(
      matchControlSlice.actions.failedUpdateMatchControlContestant('Failed to update contestant')
    );
  }
}

type ApiResponseEvent = void | { data: MatchControl; status: number };
function* createEvent(
  action: PayloadAction<{
    matchControlId: string;
    event: { entityId: string; entityType: string; eventType: string };
  }>
): Generator<CallEffect<ApiResponseEvent> | PutEffect | SelectEffect, void, ApiResponseEvent> {
  try {
    const { matchControlId, event } = action.payload;
    const response = yield call(api.post, `/matchControl/${matchControlId}/event`, event);

    if (response?.data) {
      yield put(matchControlSlice.actions.receiveGetMatchControl(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

function* deleteEvent(
  action: PayloadAction<{ matchControlId: string; event: any }>
): Generator<CallEffect<ApiResponseEvent> | PutEffect | SelectEffect, void, ApiResponseEvent> {
  try {
    const { matchControlId, event } = action.payload;
    const response = yield call(
      api.delete,
      `/matchControl/${matchControlId}/event/${event.id ? event.id : ''}`,
      event
    );

    if (response?.data) {
      yield put(matchControlSlice.actions.receiveGetMatchControl(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

function* updateMatchControlConfig(
  action: PayloadAction<{ matchControlId: string; config: Record<string, any> }>
): Generator<CallEffect<ApiResponseEvent> | PutEffect, void, ApiResponseEvent> {
  try {
    const { matchControlId, config } = action.payload;
    const response = yield call(api.patch, `/matchControl/${matchControlId}/config`, config);

    if (response?.data) {
      yield put(matchControlSlice.actions.receiveUpdateMatchControl(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

function* updateMatchControlShootOut(
  action: PayloadAction<{ matchControlId: string; shootOut: ShootOut }>
): Generator<CallEffect<ApiResponseEvent> | PutEffect, void, ApiResponseEvent> {
  try {
    const { matchControlId, shootOut } = action.payload;
    const response = yield call(api.patch, `/matchControl/${matchControlId}/shootout`, shootOut);
    if (response?.data) {
      yield put(matchControlSlice.actions.receiveUpdateMatchControl(response.data));
    }
  } catch (e) {
    const apiError = e as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_MATCH_CONTROL_TYPES, getCategories);
  yield takeLatest(REQUEST_GET_MATCH_CONTROL, getMatchControl);
  yield takeLatest(REQUEST_GET_MATCH_CONTROL_SKELETON, getSkeleton);
  yield takeLatest(REQUEST_GET_MATCH_CONTROL_CONTROLS, getMatchControlControls);
  yield takeLatest(REQUEST_UPDATE_MATCH_CONTROL_GRAPHIC, toggleMatchControlGraphic);
  yield takeLatest(REQUEST_UPDATE_MATCH_CONTROL_CLOCK, updateClock);
  yield takeLatest(REQUEST_START_MATCH_CONTROL_CLOCK, startClock);
  yield takeLatest(REQUEST_STOP_MATCH_CONTROL_CLOCK, stopClock);
  yield takeLatest(REQUEST_UPDATE_MATCH_CONTROL_CONTESTANT, updateContestant);
  yield takeLatest(REQUEST_CREATE_MATCH_CONTROL_EVENT, createEvent);
  yield takeLatest(REQUEST_DELETE_MATCH_CONTROL_EVENT, deleteEvent);
  yield takeLatest(REQUEST_UPDATE_MATCH_CONTROL, updateMatchControlConfig);
  yield takeLatest(REQUEST_UPDATE_MATCH_CONTROL_SHOOT_OUT, updateMatchControlShootOut);
}
