import classnames from 'classnames';
import { IGraphicProps } from './types';
import GraphicCanvasCount from 'src/components/GraphicCanvasCount';
import ActionButtons from 'src/components/ActionButtons';
import {
  Button,
  Icon,
  PlayButton,
  Text,
  LayeredIcon,
} from '@southfields-digital/mpxlive-components';

import styles from './Graphic.module.scss';

function Graphic(props: IGraphicProps) {
  const {
    variant,
    id,
    text,
    subText,
    live,
    onLive,
    graphic,
    canConnect = true,
    icon = 'sort',
    isConcept = false,
    showIsConcept = false,
  } = props;

  const active = variant === 'static' ? false : props.active;
  const isQuickActive = variant === 'static' ? false : props.isQuick;
  const onClick = variant === 'static' ? () => {} : props.onClick;
  const onDuplicate = variant === 'static' ? () => {} : props.onDuplicate;
  const onQuickify = variant === 'static' ? () => {} : props.onQuickify;
  const onDelete = variant === 'static' ? () => {} : props.onDelete;
  const onConnect = variant === 'static' ? () => {} : props.onConnect;
  const onConcept = variant === 'static' ? () => {} : props.onConcept;
  const parent = variant === 'static' ? false : props.parent;

  const showActionButtons = variant === 'static' ? false : true;

  const canConnectToParent = variant === 'static' ? false : canConnect;

  const handleToggleLive = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onLive(e);
  };

  return (
    <div className={classnames(styles.Graphic, { [styles.Active]: active })} key={id}>
      {variant === 'default' ? (
        <div className="p-3.5 text-[#5B5D70]">
          <Icon icon={icon} weight="bold" />
        </div>
      ) : null}
      <div className={styles.Content} onClick={onClick}>
        <PlayButton
          variant="live"
          className={styles.PlayButton}
          onClick={handleToggleLive}
          isRunning={live}
          hasIcon
        />

        <div className={styles.Label}>
          <div className={styles.GraphicName}>
            <div className={styles.GraphicNameContent}>
              <Text as="p" lineClamp="clamp-1">
                {text}
              </Text>
              <div className={styles.GraphicNameInnerContent}>
                <GraphicCanvasCount graphic={graphic} maxLength={2} />
                {graphic && (
                  <Text as="span" className={styles.GraphicNameLabel}>
                    {graphic.name}
                  </Text>
                )}
              </div>
            </div>
            {isConcept && showIsConcept ? (
              <LayeredIcon
                baseIcon={{ icon: 'arrow-clockwise', size: 24, color: '#9b9ca6' }}
                layeredIcons={[
                  {
                    icon: 'x',
                    weight: 'bold',
                    size: 10,
                    color: '#9b9ca6',
                    position: {
                      top: '50%',
                      left: '50%',
                      translate: 'translate(-50%,-50%)',
                    },
                  },
                ]}
              />
            ) : null}
          </div>
        </div>

        {showActionButtons && (
          <div className={styles.Options}>
            <ActionButtons isActive={active} text={subText}>
              {showIsConcept && (
                <Button variant={isConcept ? 'primary' : 'secondary'} onClick={onConcept}>
                  <LayeredIcon
                    baseIcon={{ icon: 'arrow-clockwise', size: 24 }}
                    layeredIcons={[
                      {
                        icon: 'x',
                        weight: 'bold',
                        size: 10,
                        position: {
                          top: '50%',
                          left: '50%',
                          translate: 'translate(-50%,-50%)',
                        },
                      },
                    ]}
                  />
                </Button>
              )}
              {canConnectToParent && (
                <Button
                  iconProps={{ icon: 'arrow-elbow-left-up' }}
                  variant={parent ? 'primary' : 'secondary'}
                  onClick={onConnect}
                />
              )}
              <Button
                iconProps={{ icon: 'quickbutton' }}
                variant={isQuickActive ? 'primary' : 'secondary'}
                onClick={onQuickify}
              />
              <Button iconProps={{ icon: 'copy' }} variant="secondary" onClick={onDuplicate} />
              <Button iconProps={{ icon: 'trash' }} variant="danger" onClick={onDelete} />
            </ActionButtons>
          </div>
        )}
      </div>
    </div>
  );
}

export default Graphic;
