import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, SettingsForm } from '@southfields-digital/mpxlive-components';
import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import menuGroups from 'src/pages/Settings/menuGroups';
import {
  REQUEST_DELETE_RUNDOWN_TEMPLATE,
  REQUEST_GET_RUNDOWN_TEMPLATES,
} from 'src/redux/reducers/rundownTemplate';
import { StateType } from 'src/redux/reducers';
import styles from './RundownTemplateOverview.module.scss';

export default function RundownTemplateOverview() {
  const { setWithContainer } = useContext<BaseLayoutContextType | null>(
    BaseLayoutContext
  ) as BaseLayoutContextType;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, loading } = useSelector(
    (state: StateType) => state.rundownTemplate.overview
  );

  useEffect(() => {
    setWithContainer(true);
    dispatch({ type: REQUEST_GET_RUNDOWN_TEMPLATES });

    return function unmount() {
      setWithContainer(false);
    };
  }, []);

  const handleDeleteRundownTemplate = (id: string) => {
    dispatch({ type: REQUEST_DELETE_RUNDOWN_TEMPLATE, payload: { id } });
  };

  const handleClickRundownTemplate = (id: string) => {
    navigate(`/settings/templates/edit/${id}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!data) return <div>No Rundown found</div>;

  return (
    <SettingsForm
      title="Rundown templates"
      formSections={[
        {
          type: 'overview',
          onCreate: () => navigate('/settings/templates/create'),
          onCreateLabel: 'Add template',
          overview: (
            <div className={styles.RundownTemplatesOverview}>
              {data?.map((rundownTemplate) => (
                <div
                  onClick={() => handleClickRundownTemplate(rundownTemplate.id)}
                  key={rundownTemplate.id}
                  className={styles.RundownTemplate}
                >
                  <Card
                    actionItems={[
                      {
                        as: 'button',
                        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDeleteRundownTemplate(rundownTemplate.id);
                        },
                        value: 'delete',
                      },
                    ]}
                    headerContent={rundownTemplate.name}
                  />
                </div>
              ))}
            </div>
          ),
        },
      ]}
      menuGroups={menuGroups}
    />
  );
}
