export type OutputCollectionStateType = {
  data: Output[] | null;
  loading: boolean;
  error: string | null;
};

export type OutputDetailStateType = {
  data: Output | null;
  loading: boolean;
  submitting: boolean;
  error: string | null;
};

export type OutputStateType = {
  collection: OutputCollectionStateType;
  detail: OutputDetailStateType;
};

export const OUTPUT = 'output';
export type OUTPUT = typeof OUTPUT;

export const REQUEST_CREATE_OUTPUT = `${OUTPUT}/requestCreateOutput`;
export type REQUEST_CREATE_OUTPUT = typeof REQUEST_CREATE_OUTPUT;

export const REQUEST_DELETE_OUTPUT = `${OUTPUT}/requestDeleteOutput`;
export type REQUEST_DELETE_OUTPUT = typeof REQUEST_DELETE_OUTPUT;

export const REQUEST_GET_OUTPUTS = `${OUTPUT}/requestGetOutputs`;
export type REQUEST_GET_OUTPUTS = typeof REQUEST_GET_OUTPUTS;

export const REQUEST_UPDATE_OUTPUT = `${OUTPUT}/requestUpdateOutput`;
export type REQUEST_UPDATE_OUTPUT = typeof REQUEST_UPDATE_OUTPUT;
