import { Button, FormGroup, Text } from '@southfields-digital/mpxlive-components';
import Select, { Option } from 'src/components/Select';

import styles from './RundownTemplatePickerForm.module.scss';

type RundownTemplatePickerFormProps = {
  rundownTemplates: RundownTemplate[];
  state: string;
  stateUpdated: (state: Partial<Rundown>) => void;
  editing: boolean;
  editingDisabled: boolean;
  handleChangeEditing: (editing: boolean) => void;
};

export default function RundownTemplatePickerForm({
  editing,
  editingDisabled,
  handleChangeEditing,
  rundownTemplates,
  state,
  stateUpdated,
}: RundownTemplatePickerFormProps) {
  const rundownTemplateOptions: Option[] = [
    { label: '-', value: '' },
    ...rundownTemplates.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  ];

  const selectedTemplate = rundownTemplateOptions.find(({ value }) => value === state);

  const handleChangeRundownTemplate = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const rundownTemplate = rundownTemplates.find(({ id }) => id === e.currentTarget.value);
    stateUpdated({
      rundownTemplateId: e.currentTarget.value,
      graphicPackage: rundownTemplate?.graphicPackage,
      graphicPackageId: rundownTemplate?.graphicPackage?.id,
      canvases: rundownTemplate?.canvases as RundownCanvas[],
      matchControl: rundownTemplate?.matchControlType
        ? { sport: rundownTemplate?.matchControlType }
        : undefined,
    });
  };

  if (state && !editing) {
    return (
      <div className={styles.Picked}>
        <Text as="p">
          {state === 'blank' ? (
            'Rundown created from scratch'
          ) : (
            <>
              Rundown created from template <strong>{selectedTemplate?.label}</strong>
            </>
          )}
        </Text>

        <Button
          disabled={editingDisabled}
          variant="tertiary"
          buttonSize="small"
          onClick={() => handleChangeEditing(true)}
        >
          Change
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <FormGroup
        label="Start with a template"
        htmlFor="rundownTemplate"
        className={styles.TemplateOrScratch}
      >
        <Select
          id="rundownTemplate"
          value={selectedTemplate?.value || ''}
          options={rundownTemplateOptions}
          onChange={handleChangeRundownTemplate}
        />
      </FormGroup>

      <Text className={styles.TemplateOrScratch} as="p">
        or
      </Text>

      <Button
        className={styles.StartFromScratchButton}
        variant="primary"
        onClick={() => {
          stateUpdated({
            rundownTemplateId: 'blank',
            graphicPackageId: '',
            canvases: [],
          });
        }}
      >
        Start from scratch
      </Button>
    </div>
  );
}
