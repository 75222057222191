import { ColorPicker } from './ColorPicker';

type Props = {
  primaryColor: string;
  secondaryColor: string;
  handleChangePrimaryColor: (color: string) => void;
  handleChangeSecondaryColor: (color: string) => void;
};

const TeamColors = ({
  primaryColor,
  secondaryColor,
  handleChangePrimaryColor,
  handleChangeSecondaryColor,
}: Props) => {
  return (
    <div className="flex w-6">
      <ColorPicker color={primaryColor} handleChangeColor={handleChangePrimaryColor} />
      <ColorPicker color={secondaryColor} handleChangeColor={handleChangeSecondaryColor} />
    </div>
  );
};

export default TeamColors;
