import { ColorPicker } from 'src/components/MatchControl/components';
import { Contestant as ContestantType } from 'src/types/matchControl/contestant';
import { Sport } from 'src/types/matchControl/sport';

import { Input, Label } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';

type Props = {
  sport: Sport;
  inverted?: boolean;
  contestant: ContestantType;
  handleChangeContestant: (contestant: ContestantType) => void;
  handleClickMedia?: (contestant: ContestantType) => void;
};

const DefaultContestant = ({
  contestant,
  inverted = false,
  handleChangeContestant,
  handleClickMedia = () => {},
}: Omit<Props, 'sport'>) => {
  const side = inverted ? 'Away' : 'Home';

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeContestant({ ...contestant, name: e.currentTarget.value });
  };

  const handleChangeShortName = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeContestant({ ...contestant, shortName: e.currentTarget.value });
  };

  const handleChangePrimaryColor = (color: string) => {
    handleChangeContestant({ ...contestant, primaryColor: color });
  };

  const handleChangeSecondaryColor = (color: string) => {
    handleChangeContestant({ ...contestant, secondaryColor: color });
  };

  return (
    <div className="flex flex-col">
      <div className={classNames('flex', { 'justify-end': inverted })}>{<Label>{side}</Label>}</div>
      <div className={'flex flex-row gap-2 items-center'}>
        <div
          className={classNames('flex flex-col gap-2', {
            'order-2': inverted,
            'order-1': !inverted,
          })}
        >
          <Input placeholder="Name" onChange={handleChangeName} value={contestant.name} />
          <div className="grid grid-flow-col gap-2 items-center">
            <Input
              placeholder="Short Name"
              className={classNames({ 'order-1': inverted, 'order-2': !inverted })}
              onChange={handleChangeShortName}
              value={contestant.shortName}
            />
            <div className={classNames('flex w-6', { 'order-2': inverted, 'order-1': !inverted })}>
              <ColorPicker
                color={contestant.primaryColor}
                handleChangeColor={handleChangePrimaryColor}
              />
              <ColorPicker
                color={contestant.secondaryColor}
                handleChangeColor={handleChangeSecondaryColor}
              />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            {
              'order-1': inverted,
              'order-2': !inverted,
            },
            'flex justify-center align-center basis-[min-content] aspect-square h-full p-2 bg-elevated-2 cursor-pointer'
          )}
          onClick={() => handleClickMedia(contestant)}
        >
          <img className="object-contain" src={contestant.imageUrl} />
        </div>
      </div>
    </div>
  );
};

const SoccerContestant = (props: Omit<Props, 'sport'>) => {
  return <DefaultContestant {...props} />;
};

const HockeyContestant = (props: Omit<Props, 'sport'>) => {
  return <DefaultContestant {...props} />;
};

const TennisContestant = (props: Omit<Props, 'sport'>) => {
  return <DefaultContestant {...props} />;
};

const VolleyballContestant = (props: Omit<Props, 'sport'>) => {
  return <DefaultContestant {...props} />;
};

const Contestant = (props: Props) => {
  const { sport, ...rest } = props;
  switch (sport) {
    case 'soccer':
      return <SoccerContestant {...rest} />;
    case 'hockey':
      return <HockeyContestant {...rest} />;
    case 'tennis':
      return <TennisContestant {...rest} />;
    case 'volleyball':
      return <VolleyballContestant {...rest} />;
    default:
      return null;
  }
};

export default Contestant;
