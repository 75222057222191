import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: any;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  variant?: 'icon' | 'default';
  isRunning?: boolean;
};

const MatchControlButton = ({
  children,
  onClick,
  disabled,
  className,
  isRunning = false,
  variant = 'default',
}: Props) => {
  const styles = twMerge(
    classNames({
      'flex items-center justify-center p-2': true,
      'text-modal font-semibold text-sm rounded uppercase': true,
      'bg-elevated-2 hover:bg-primary': true,
      'h-6': variant === 'icon',
      'bg-gradient-to-b from-gr-gray-dark to-gr-gray-light border border-gr-gray-light ':
        variant === 'default',
      'bg-gradient-to-b from-gr-red-dark to-gr-red-light border border-gr-red-light': isRunning,
    }),
    className
  );

  return (
    <button onClick={onClick} disabled={disabled} className={styles}>
      {children}
    </button>
  );
};

export default MatchControlButton;
