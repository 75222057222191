import { useContext, useEffect, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  REQUEST_GET_RUNDOWNS,
  REQUEST_DELETE_RUNDOWN,
  RundownsType,
  RundownCalendarStateType,
} from 'src/redux/reducers/rundown';
import type { StateType } from '../../redux/reducers';
import { getCalendarWeek, getStartDate } from './utils';
import { getISODateTimeString, toDatetimeLocal } from 'src/utils';

import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import RundownCard from './RundownCard/RundownCard';
import { Text, Button, Loader } from '@southfields-digital/mpxlive-components';

import styles from './RundownCalendar.module.scss';

const DATE_PARAM = 'date';
function RundownCalendar() {
  const dispatch = useDispatch();
  const { setFullWidth, setShowDateInput } = useContext<BaseLayoutContextType | null>(
    BaseLayoutContext
  ) as BaseLayoutContextType;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dateParam = searchParams.get(DATE_PARAM) || '';
  const date = Date.parse(dateParam) ? dateParam : toDatetimeLocal(new Date()).date;

  const firstDayofWeekRef = useRef<string | undefined>();
  const weekDates = getCalendarWeek(new Date(date || ''));

  const rundowns: RundownCalendarStateType = useSelector(
    (state: StateType) => state.rundown.calendar
  );

  useEffect(() => {
    setFullWidth(true);
    setShowDateInput(true);

    return function unmount() {
      setFullWidth(false);
      setShowDateInput(false);
    };
  }, []);

  useEffect(() => {
    if (!date) return;

    const selectedDate = new Date(date);
    const startOfWeek = getStartDate(selectedDate);
    if (startOfWeek.toLocaleString() === firstDayofWeekRef.current) return;

    firstDayofWeekRef.current = startOfWeek.toLocaleString();
    dispatch({
      type: REQUEST_GET_RUNDOWNS,
      payload: { date: getISODateTimeString(toDatetimeLocal(startOfWeek).localDateTime) },
    });
  }, [date, dispatch]);

  const handleDeleteRundown = (id: string) => {
    dispatch({ type: REQUEST_DELETE_RUNDOWN, payload: { id } });
  };

  const handleClickCard = (id: string) => {
    navigate(`${id}`);
  };

  return (
    <div className={styles.RundownCalendar}>
      <div className={styles.Calendar}>
        {rundowns?.loading && <Loader centeredFullscreen />}

        {!rundowns?.loading &&
          weekDates.map((date) => (
            <div key={date.toString()} className={styles.Day}>
              <div className={styles.Header}>
                <Text as="h2" className={styles.Title}>
                  {format(date, 'EEEE')}
                </Text>
                <small>{format(date, 'PPP')}</small>
              </div>

              {(rundowns?.data as RundownsType)?.length > 0 && (
                <div className={styles.Rundowns}>
                  {rundowns?.data?.map((rundown) => {
                    if (new Date(rundown.start).getDate() !== date.getDate()) return null;
                    return (
                      <RundownCard
                        key={rundown.id}
                        rundown={rundown}
                        date={date}
                        handleClickDelete={() => handleDeleteRundown(rundown.id)}
                        handleClickCard={() => handleClickCard(rundown.id)}
                      />
                    );
                  })}
                </div>
              )}

              <Link to={`/rundowns/create?date=${date.toISOString()}`}>
                <Button
                  className={styles.NewButton}
                  iconProps={{ icon: 'plus-circle' }}
                  variant="primary"
                  color="white"
                />
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
}

export default RundownCalendar;
