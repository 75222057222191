import MatchControlIcon from './MatchControlIcon';
import MatchControlButton from './MatchControlButton';

import styles from '../MatchControl.module.scss';

type ExtraInProps = {
  isRunning?: boolean;
  disabled?: boolean;
  handleChangeExtraTime: (amount: number) => void;
  handleToggleIsExtraTimeLive: () => void;
};

const ExtraIn = ({
  isRunning = false,
  disabled = false,
  handleChangeExtraTime,
  handleToggleIsExtraTimeLive,
}: ExtraInProps) => {
  const handleAddExtraTime = (amount: number) => {
    handleChangeExtraTime(amount);
  };

  const handleSubtractExtraTime = (amount: number) => {
    handleChangeExtraTime(-amount);
  };

  return (
    <div className={styles.ExtraInWrapper}>
      <div className={styles.ActionsWrapper}>
        <MatchControlButton
          className={styles.MatchControlButton}
          onClick={() => handleSubtractExtraTime(1000)}
          disabled={disabled}
          variant="icon"
        >
          <MatchControlIcon iconProps={{ icon: 'minus' }} />
        </MatchControlButton>
        <MatchControlButton
          variant="icon"
          className={styles.MatchControlButton}
          onClick={() => handleAddExtraTime(1000)}
          disabled={disabled}
        >
          <MatchControlIcon iconProps={{ icon: 'plus' }} />
        </MatchControlButton>
      </div>
      <MatchControlButton
        isRunning={isRunning}
        onClick={handleToggleIsExtraTimeLive}
        disabled={disabled}
      >
        EXTRA
      </MatchControlButton>
    </div>
  );
};

export default ExtraIn;
