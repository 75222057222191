import { useEffect, useRef, useState } from 'react';

import MatchControlIcon from './MatchControlIcon';
import { Text } from '@southfields-digital/mpxlive-components';
import useOutsideClick from 'src/hooks/useOutsideClick';
import classNames from 'classnames';

import styles from '../MatchControl.module.scss';

type Props = {
  inverted?: boolean;
  shortName: string;
  handleChangeShortName?: (name: string) => void;
};

const ShortName = ({ shortName, handleChangeShortName, inverted }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [clubName, setClubName] = useState<string>(shortName);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setClubName(shortName);
  }, [shortName]);

  const handleSubmitName = () => {
    if (isEditing && handleChangeShortName) {
      handleChangeShortName(clubName);
      setIsEditing(false);
    }
  };

  useOutsideClick(containerRef, () => {
    handleSubmitName();
  });

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  if (isEditing) {
    return (
      <div
        className={classNames(styles.RedCardTeamWrapper, {
          [styles.HomeTeam]: !inverted,
          [styles.GuestsTeam]: inverted,
        })}
        ref={containerRef}
      >
        <MatchControlIcon
          iconProps={{ icon: 'x', color: '#5B5D70', size: 18 }}
          onClick={() => {
            toggleEdit();
          }}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitName();
          }}
        >
          <input
            autoFocus
            style={{
              width: 45,
              background: 'transparent',
              color: 'white',
              fontWeight: 700,
              fontSize: '1.25rem',
              height: '27.5px',
              position: 'relative',
              top: 0,
              border: 'none',
              outline: 'none',
            }}
            type="text"
            maxLength={3}
            value={clubName}
            onChange={(e) => setClubName(e.currentTarget.value)}
          />
        </form>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.RedCardTeamWrapper, {
        [styles.HomeTeam]: !inverted,
        [styles.GuestsTeam]: inverted,
      })}
      ref={containerRef}
    >
      <MatchControlIcon
        iconProps={{ icon: 'pencil', color: '#5B5D70', size: 18, weight: 'fill' }}
        onClick={() => toggleEdit()}
      />
      <Text as="h2" color="light">
        {clubName}
      </Text>
    </div>
  );
};

export default ShortName;
