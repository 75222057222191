import { Icon, IconProps } from '@southfields-digital/mpxlive-components';

type Props = {
  iconProps: IconProps;
  onClick?: () => void;
};

const MatchControlIcon = ({ iconProps, onClick }: Props) => {
  const props = { ...iconProps, size: iconProps.size || 14, weight: iconProps.weight || 'bold' };
  return <Icon {...props} onClick={onClick} />;
};

export default MatchControlIcon;
