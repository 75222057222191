import { Input } from '@southfields-digital/mpxlive-components';
import type { Id, Cell } from '.';
import Select from 'src/components/Select';

type CellProps = {
  item: Cell;
  rowIndex: number;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
};

const Cell = ({ item, rowIndex, handleChangeValue }: CellProps) => {
  const itemId = item.id;
  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChangeValue(itemId, rowIndex, e.currentTarget.value);
  };

  switch (item.type) {
    case 'text':
    case 'image':
      return (
        <Input
          type="text"
          value={item.value}
          onChange={handleOnChange}
          placeholder={item.name}
          maxLength={item.maxLength}
        />
      );
    case 'select':
      return (
        <Select
          value={item.value}
          options={item.options}
          placeholder={item.name}
          onChange={handleOnChange}
        />
      );
    default:
      return null;
  }
};

export default Cell;
