import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import {
  REQUEST_DELETE_OUTPUT,
  REQUEST_GET_OUTPUTS,
  REQUEST_UPDATE_OUTPUT,
} from 'src/redux/reducers/output';
import OutputOverview from 'src/pages/Settings/Output/Overview';
import { StateType } from 'src/redux/reducers';
import { NavigateFunction } from 'react-router-dom';

const mapStateToProps = (state: StateType) => ({
  outputsData: state.output.collection.data as Output[],
  loading: state.output.collection.loading,
  submitting: state.output.detail.submitting,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteOutput: (id: string) =>
    dispatch({
      type: REQUEST_DELETE_OUTPUT,
      payload: {
        id,
      },
    }),
  getOutputs: () => dispatch({ type: REQUEST_GET_OUTPUTS }),
  updateOutput: (id: string, name: string, navigate?: NavigateFunction) =>
    dispatch({
      type: REQUEST_UPDATE_OUTPUT,
      payload: {
        id,
        data: { name } as Partial<Output>,
        navigate,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutputOverview);
