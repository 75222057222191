import { Dialog, Button } from '@southfields-digital/mpxlive-components';

import styles from '../GoLive.module.scss';

type Props = {
  isOpen: boolean;
  isLive: boolean;
  isDisabled?: boolean;
  isTakeOver?: boolean;
  description?: React.ReactNode;
  handleCloseDialog: () => void;
  handleToggleLive: () => void;
};

export default function GoLiveDialog({
  isOpen,
  isLive,
  isDisabled,
  isTakeOver,
  description,
  handleCloseDialog,
  handleToggleLive,
}: Props) {
  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} backdrop>
      <Dialog.Panel className={styles.ContainerDialog}>
        <Dialog.Title>{isLive ? 'Go Offline' : 'Go Live'}</Dialog.Title>
        {isLive && (
          <div className={styles.Warning}>
            <div className={styles.WarningText}>
              This will stop the live output and clear the output canvas.
            </div>
          </div>
        )}
        {description}
        <div className={styles.Buttons}>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          {!isDisabled && (
            <Button variant="danger" onClick={handleToggleLive}>
              {isLive ? 'Offline' : isTakeOver ? 'Take Over' : 'Go Live'}
            </Button>
          )}
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
