import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Loader, SettingsForm } from '@southfields-digital/mpxlive-components';
import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import menuGroups from '../../menuGroups';
import { REQUEST_DELETE_RUNDOWN, REQUEST_GET_RUNDOWNS } from 'src/redux/reducers/rundown';
import { StateType } from 'src/redux/reducers';
import styles from './RundownOverview.module.scss';

export default function RundownOverview() {
  const { setWithContainer } = useContext<BaseLayoutContextType | null>(
    BaseLayoutContext
  ) as BaseLayoutContextType;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, loading } = useSelector((state: StateType) => state.rundown.calendar);

  useEffect(() => {
    dispatch({
      type: REQUEST_GET_RUNDOWNS,
      payload: { date: '1970-01-01T00:00:00Z', persistent: true },
    });
    setWithContainer(true);

    return function unmount() {
      setWithContainer(false);
    };
  }, []);

  const handleDeleteRundown = (id: string) => {
    dispatch({ type: REQUEST_DELETE_RUNDOWN, payload: { id } });
  };

  const handleClickCard = (id: string) => {
    navigate(`/rundown/${id}`);
  };

  if (loading) return <Loader centeredFullscreen />;
  if (error) return <div>{error}</div>;

  return (
    <SettingsForm
      title="Persistent rundowns"
      formSections={[
        {
          type: 'overview',
          onCreate: () => navigate('/rundowns/create?persistent=true'),
          onCreateLabel: 'Add persistent rundown',
          overview: (
            <>
              {!data || data.length === 0 ? (
                <div className={styles.NoContent}>No persistent rundowns</div>
              ) : (
                <div className={styles.RundownOverview}>
                  {data.map((rundown) => (
                    <div
                      onClick={() => handleClickCard(rundown.id)}
                      key={rundown.id}
                      className={styles.RundownOverview}
                    >
                      <Card
                        actionItems={[
                          {
                            to: `/rundowns/edit/${rundown.id}`,
                            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                              e.stopPropagation();
                            },
                            value: 'edit',
                          },
                          {
                            as: 'button',
                            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteRundown(rundown.id);
                            },
                            value: 'delete',
                          },
                        ]}
                        className={styles.Rundown}
                        headerContent={rundown.name}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          ),
        },
      ]}
      menuGroups={menuGroups}
    />
  );
}
