import { Directory, File, Pagination } from '@southfields-digital/mpxlive-components';
import { Id } from 'react-toastify';

export const MEDIA_LIBRARY = 'mediaLibrary';
export type MEDIA_LIBRARY = typeof MEDIA_LIBRARY;

export const REQUEST_CREATE_BULK_FILE_UPLOAD = `${MEDIA_LIBRARY}/requestCreateBulkFileUpload`;
export type REQUEST_CREATE_BULK_FILE_UPLOAD = typeof REQUEST_CREATE_BULK_FILE_UPLOAD;

export const REQUEST_CREATE_DIRECTORY = `${MEDIA_LIBRARY}/requestCreateDirectory`;
export type REQUEST_CREATE_DIRECTORY = typeof REQUEST_CREATE_DIRECTORY;

export const REQUEST_DELETE_DIRECTORY = `${MEDIA_LIBRARY}/requestDeleteDirectory`;
export type REQUEST_DELETE_DIRECTORY = typeof REQUEST_DELETE_DIRECTORY;

export const REQUEST_DELETE_FILE = `${MEDIA_LIBRARY}/requestDeleteFile`;
export type REQUEST_DELETE_FILE = typeof REQUEST_DELETE_FILE;

export const REQUEST_GET_DIRECTORIES = `${MEDIA_LIBRARY}/requestGetDirectories`;
export type REQUEST_GET_DIRECTORIES = typeof REQUEST_GET_DIRECTORIES;

export const REQUEST_GET_FILES = `${MEDIA_LIBRARY}/requestGetFiles`;
export type REQUEST_GET_FILES = typeof REQUEST_GET_FILES;

export const REQUEST_RESET_FILES_AND_DIRECTORIES = `${MEDIA_LIBRARY}/requestResetFilesAndDirectories`;
export type REQUEST_RESET_FILES_AND_DIRECTORIES = typeof REQUEST_RESET_FILES_AND_DIRECTORIES;

export const REQUEST_SEARCH_DIRECTORIES = `${MEDIA_LIBRARY}/requestSearchDirectories`;
export type REQUEST_SEARCH_DIRECTORIES = typeof REQUEST_SEARCH_DIRECTORIES;

export const REQUEST_SEARCH_FILES = `${MEDIA_LIBRARY}/requestSearchFiles`;
export type REQUEST_SEARCH_FILES = typeof REQUEST_SEARCH_FILES;

export const REQUEST_UPDATE_DIRECTORY = `${MEDIA_LIBRARY}/requestUpdateDirectory`;
export type REQUEST_UPDATE_DIRECTORY = typeof REQUEST_UPDATE_DIRECTORY;

export const REQUEST_UPLOAD_FILE_CHUNKS = `${MEDIA_LIBRARY}/requestUploadFileChunks`;
export type REQUEST_UPLOAD_FILE_CHUNKS = typeof REQUEST_UPLOAD_FILE_CHUNKS;

export const NOTIFY_UPLOADS_COMPLETED = `${MEDIA_LIBRARY}/notifyUploadsCompleted`;
export type NOTIFY_UPLOADS_COMPLETED = typeof NOTIFY_UPLOADS_COMPLETED;

export type DirectoriesResponseType = {
  directories: Directory[];
  locked?: boolean;
};

export type DirectoryUpdateResponseType = {
  id: string;
  name: string;
};

export interface FilesResponseType {
  files: File[];
  pagination: Pagination;
  count?: number;
}

export type BulkUpload = {
  completedUploads: number;
  successfulUploads: number;
  totalNumberOfFiles: number;
  toastId: Id;
  uploadProgress: number;
};

export type BulkUploadsType = {
  [key: string]: BulkUpload;
};

export type MediaLibraryStateType = {
  directories: {
    data: DirectoriesResponseType | null;
    searchData: DirectoriesResponseType | null;
    isLoading: boolean;
    error: null | string;
  };
  files: {
    data: FilesResponseType | null;
    searchData: FilesResponseType | null;
    isLoading: boolean;
    error: null | string;
  };
  bulkUploads: BulkUploadsType | null;
};
