import { Button } from '@southfields-digital/mpxlive-components';
import styles from '../Repeater.module.scss';

type RemoveButtonProps = {
  onClick: () => void;
};

const RemoveButton = ({ onClick }: RemoveButtonProps) => {
  return (
    <Button
      variant="iconButton"
      iconProps={{ icon: 'x-circle' }}
      color="#F5F6FF"
      onClick={onClick}
      className={styles.ButtonRemoveRow}
    />
  );
};

export default RemoveButton;
