import { Label } from '@southfields-digital/mpxlive-components';
import { Tooltip } from 'src/components/Tooltip';
import styles from './GraphicCanvasCount.module.scss';

type GraphicCanvasCountProps = {
  graphic?: Graphic;
  maxLength: number;
};

export default ({ graphic, maxLength = 1 }: GraphicCanvasCountProps) => {
  if (!graphic?.canvases?.length) {
    return null;
  }

  return (
    <Label id={graphic.id} className={styles.CanvasLabel}>
      {graphic.canvases
        .slice(0, maxLength)
        .map((canvas) => canvas.name)
        .join(', ')}{' '}
      {graphic.canvases.length > maxLength && (
        <Tooltip
          className={styles.JustTheTooltip}
          overlay={
            <Label id={graphic.id} className={styles.CanvasLabel}>
              {graphic.canvases
                .slice(maxLength, -1)
                .map((canvas) => canvas.name)
                .join(', ')}
            </Label>
          }
          placement="bottom-end"
        >
          <Label id={graphic.id} className={styles.CanvasCount}>
            +{graphic.canvases.length - maxLength}
          </Label>
        </Tooltip>
      )}
    </Label>
  );
};
