import api from 'src/services/graphicsApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';
import {
  actions,
  REQUEST_GET_SPORT_DATA_FIXTURES,
  REQUEST_GET_SPORT_DATA_MATCH_STATS,
  REQUEST_GET_SPORT_DATA_SCHEDULE,
  REQUEST_GET_SPORT_DATA_STANDINGS,
  REQUEST_GET_SPORT_DATA_TOURNAMENT_CALENDAR,
} from '../reducers/sportData';
import { StandingResponse } from 'src/types/statsperform/standings';
import { TournamentScheduleResponse } from 'src/types/statsperform/tournamentSchedule';
import { MatchStatsResponse } from 'src/types/statsperform/matchStats';
import { TournamentCalendarResponse } from 'src/types/statsperform/tournamentCalendar';
import { FixturesResponse } from 'src/types/statsperform/fixtures';
import { NOTIFY } from '../reducers/notification';

type StandingsApiResponse = void | { data: StandingResponse; status: number };
function* getStandings(
  action: PayloadAction<{ tmcl: string; _lcl: string; live: string }>
): Generator<PutEffect | CallEffect<StandingsApiResponse>, void, StandingsApiResponse> {
  try {
    const apiResponse = yield call(api.get, `/sportData/standings`, action.payload);
    if (apiResponse?.data && !apiResponse?.hasOwnProperty('errorCode')) {
      yield put(
        actions.receiveStandings({
          standingsData: apiResponse.data,
          tournamentCalendarId: action.payload.tmcl,
        })
      );
    }
  } catch (error) {
    yield put(
      actions.failedStandings((error as Error)?.message as string) ||
        'Something went wrong. Could not get standings'
    );
  }
}

type ScheduleApiResponse = void | { data: TournamentScheduleResponse; status: number };
function* getSchedule(
  action: PayloadAction<{ tmcl: string }>
): Generator<PutEffect | CallEffect<ScheduleApiResponse>, void, ScheduleApiResponse> {
  try {
    const apiResponse = yield call(api.get, `/sportData/schedule`, action.payload);

    if (apiResponse?.data && !apiResponse?.hasOwnProperty('errorCode')) {
      yield put(actions.receiveSchedule(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedSchedule((error as Error)?.message as string) ||
        'Something went wrong. Could not get schedule'
    );
  }
}

type FixturesApiResponse = void | { data: FixturesResponse; status: number };
function* getFixtures(
  action: PayloadAction<{ tmcl: string }>
): Generator<PutEffect | CallEffect<FixturesApiResponse>, void, FixturesApiResponse> {
  try {
    yield delay(250);

    const apiResponse = yield call(api.get, `/sportData/fixtures`, action.payload);

    if (apiResponse?.data && !apiResponse?.data.hasOwnProperty('errorCode')) {
      yield put(actions.receiveFixtures(apiResponse.data));
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(
      actions.failedFixtures((error as Error)?.message as string) ||
        'Something went wrong. Could not get fixtures'
    );
  }
}

type MatchStatsApiResponse = void | { data: MatchStatsResponse; status: number };
function* getMatchStats(
  action: PayloadAction<{ fx: string }>
): Generator<PutEffect | CallEffect<MatchStatsApiResponse>, void, MatchStatsApiResponse> {
  try {
    const apiResponse = yield call(api.get, `/sportData/matchStats`, action.payload);

    if (apiResponse?.data && !apiResponse?.data.hasOwnProperty('errorCode')) {
      yield put(
        actions.receiveMatchData({
          fixtureId: action.payload.fx,
          fixtureData: apiResponse.data,
        })
      );
    }
  } catch (error) {
    yield put(
      actions.failedMatchData((error as Error)?.message as string) ||
        'Something went wrong. Could not get stats'
    );
  }
}

type TournamentCalenderApiResponse = void | { data: TournamentCalendarResponse; status: number };
function* getTournamentCalendar(
  action: PayloadAction<{ fx: string }>
): Generator<
  PutEffect | CallEffect<TournamentCalenderApiResponse>,
  void,
  TournamentCalenderApiResponse
> {
  try {
    const apiResponse = yield call(api.get, `/sportData/tournamentCalendar`, action.payload);

    if (apiResponse?.data && !apiResponse?.data.hasOwnProperty('errorCode')) {
      yield put(actions.receiveTournamentCalendar(apiResponse?.data));
    }
  } catch (error) {
    yield put(
      actions.failedTournamentCalendar((error as Error)?.message as string) ||
        'Something went wrong. Could not get stats'
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_SPORT_DATA_STANDINGS, getStandings);
  yield takeLatest(REQUEST_GET_SPORT_DATA_SCHEDULE, getSchedule);
  yield takeLatest(REQUEST_GET_SPORT_DATA_FIXTURES, getFixtures);
  yield takeLatest(REQUEST_GET_SPORT_DATA_MATCH_STATS, getMatchStats);
  yield takeLatest(REQUEST_GET_SPORT_DATA_TOURNAMENT_CALENDAR, getTournamentCalendar);
}
