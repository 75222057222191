import { Dialog } from '@southfields-digital/mpxlive-components';

interface IShortKeysDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const shortkeys = [
  { keystroke: 'F1', action: 'Live in selected graphic' },
  { keystroke: 'F2', action: 'Live out selected graphic' },
  { keystroke: 'F3', action: 'Preview in selected graphic' },
  { keystroke: 'F4', action: 'Preview out selected graphic' },
  { keystroke: 'PageDown', action: 'Select next graphic in playlist' },
  { keystroke: 'PageUp', action: 'Select previous graphic in playlist' },
  { keystroke: 'Shift + PageDown', action: 'Select next playlist' },
  { keystroke: 'Shift + PageUp', action: 'Select previous playlist' },
  { keystroke: 'Esc', action: 'Close media library when open' },
];

export default function ShortKeysDialog({ isOpen, onClose }: IShortKeysDialogProps) {
  return (
    <Dialog backdrop open={isOpen} onClose={onClose}>
      <Dialog.Panel className="!max-w-lg">
        <Dialog.Title>MXMZ Collection keyboard shortcuts</Dialog.Title>
        <Dialog.Description as="div">
          <div className="flow-root">
            <div className="overflow-x-auto w-full bg-elevated-1">
              <div className="inline-block min-w-full align-middle ">
                <table className="min-w-full divide-y divide-elevated-2">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 sm:pl-3"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-200"
                      >
                        Keyboard shortcuts
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-elevated-1 divide-y divide-elevated-2">
                    {shortkeys.map((shortKey, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-400 text-left">
                          {shortKey.action}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3 text-left">
                          <div className="bg-secondary text-gray-50 py-1 px-2 text-sm inline-block font-medium rounded">
                            {shortKey.keystroke}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Dialog.Description>
      </Dialog.Panel>
    </Dialog>
  );
}
