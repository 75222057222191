import classNames from 'classnames';
import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';

import styles from '../MatchControl.module.scss';

type Props = {
  disabled?: boolean;
  clockRunning: boolean;
  handleStartTimer: () => void;
  handleStopTimer: () => void;
  iconSize?: number;
  minimize?: boolean;
};

const ClockStart = ({
  disabled = false,
  clockRunning,
  handleStartTimer,
  handleStopTimer,
  iconSize,
  minimize,
}: Props) => (
  <div className={classNames(styles.ActionsWrapper, minimize && 'gap-1.5')}>
    <MatchControlButton
      variant="icon"
      isRunning={clockRunning}
      onClick={handleStartTimer}
      disabled={disabled}
    >
      <MatchControlIcon iconProps={{ icon: 'play', weight: 'fill', size: iconSize }} />
    </MatchControlButton>
    <MatchControlButton
      variant="icon"
      isRunning={!clockRunning}
      onClick={handleStopTimer}
      disabled={disabled}
    >
      <MatchControlIcon iconProps={{ icon: 'pause', weight: 'fill', size: iconSize }} />
    </MatchControlButton>
  </div>
);

export default ClockStart;
