import { useEffect, useState } from 'react';
import { Loader } from '@southfields-digital/mpxlive-components';
import {
  REQUEST_CREATE_MATCH_CONTROL_EVENT,
  REQUEST_DELETE_MATCH_CONTROL_EVENT,
  REQUEST_GET_MATCH_CONTROL,
  REQUEST_GET_MATCH_CONTROL_CONTROLS,
  REQUEST_START_MATCH_CONTROL_CLOCK,
  REQUEST_STOP_MATCH_CONTROL_CLOCK,
  REQUEST_UPDATE_MATCH_CONTROL,
  REQUEST_UPDATE_MATCH_CONTROL_CLOCK,
  REQUEST_UPDATE_MATCH_CONTROL_CONTESTANT,
  REQUEST_UPDATE_MATCH_CONTROL_GRAPHIC,
  REQUEST_UPDATE_MATCH_CONTROL_SHOOT_OUT,
} from 'src/redux/reducers/matchControls';
import { Contestant } from 'src/types/matchControl/contestant';
import { Clock } from 'src/types/matchControl/clock';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import { Control } from 'src/types/matchControl/control';
import {
  HockeyMatchControl,
  SoccerMatchControl,
  TennisMatchControl,
  VolleyballMatchControl,
} from 'src/types/matchControl';
import { ShootOut } from 'src/types/matchControl/shootOut';
import { MatchControlVariant } from './types';

import Soccer from './sports/Soccer';
import Hockey from './sports/Hockey';
import Tennis from './sports/Tennis';
import Volleyball from './sports/Volleyball';

type Props = {
  variant?: MatchControlVariant;
  rundownId: string;
};

const MatchControl = ({ rundownId, variant }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch({
      type: REQUEST_GET_MATCH_CONTROL,
      payload: {
        rundownId,
        callback: () => setLoading(false),
      },
    });
    dispatch({ type: REQUEST_GET_MATCH_CONTROL_CONTROLS, payload: { rundownId } });
  }, [rundownId, dispatch]);

  const { data: matchControl } = useSelector(
    (state: StateType) => state.matchControls.matchControl
  );
  const { data: matchControlGraphics } = useSelector(
    (state: StateType) => state.matchControls.matchControlGraphics
  );

  const updateTennisMatchControl = (matchControl: TennisMatchControl) => {
    dispatch({
      type: REQUEST_UPDATE_MATCH_CONTROL,
      payload: {
        matchControlId: matchControl.id,
        config: { score: matchControl.score, server: matchControl.server },
      },
    });
  };

  const updateVolleyballMatchControl = (matchControl: VolleyballMatchControl) => {
    dispatch({
      type: REQUEST_UPDATE_MATCH_CONTROL,
      payload: {
        matchControlId: matchControl.id,
        config: { score: matchControl.score, server: matchControl.server },
      },
    });
  };

  const updateSoccerMatchControl = (matchControl: SoccerMatchControl) => {
    dispatch({
      type: REQUEST_UPDATE_MATCH_CONTROL,
      payload: {
        matchControlId: matchControl.id,
        config: { hasShootOut: matchControl.hasShootOut, shootOut: matchControl.shootOut },
      },
    });
  };

  const updateHockeyMatchControl = (matchControl: HockeyMatchControl) => {
    dispatch({
      type: REQUEST_UPDATE_MATCH_CONTROL,
      payload: {
        matchControlId: matchControl.id,
        config: {
          hasShootOut: matchControl.hasShootOut,
          shootOut: matchControl.shootOut,
          period: matchControl.period,
        },
      },
    });
  };

  const updateShootOutMatchControl = (
    matchControl: SoccerMatchControl | HockeyMatchControl,
    shootOut: ShootOut
  ) => {
    dispatch({
      type: REQUEST_UPDATE_MATCH_CONTROL_SHOOT_OUT,
      payload: {
        matchControlId: matchControl.id,
        shootOut,
      },
    });
  };

  const updateContestant = (contestant: Contestant) => {
    if (!matchControl) return;

    dispatch({
      type: REQUEST_UPDATE_MATCH_CONTROL_CONTESTANT,
      payload: { matchControlId: matchControl.id, contestant },
    });
  };

  const updateClock = (clock: Clock) => {
    if (!matchControl) return;

    dispatch({
      type: REQUEST_UPDATE_MATCH_CONTROL_CLOCK,
      payload: { matchControlId: matchControl.id, clock },
    });
  };

  const handleChangeExtraTime = (clock: Clock, amount: number) => {
    if (!('extraTime' in clock)) return;

    updateClock({ ...clock, extraTime: Math.max(0, amount) });
  };

  const handleChangeTime = (clock: Clock, time: number) => {
    updateClock({ ...clock, clockTime: time });
  };

  const handleChangeScore = (contestant: Contestant, goals: number) => {
    if (!('points' in contestant.score)) return;

    if (contestant.score.points < goals) {
      handleAddGoal(contestant);
    } else {
      handleDeleteGoal(contestant);
    }
  };

  const handleAddGoal = (contestant: Contestant) => {
    dispatch({
      type: REQUEST_CREATE_MATCH_CONTROL_EVENT,
      payload: {
        matchControlId: matchControl?.id,
        event: { type: 'goal', entityId: contestant.id, entityType: 'contestant' },
      },
    });
  };

  const handleDeleteGoal = (contestant: Contestant) => {
    dispatch({
      type: REQUEST_DELETE_MATCH_CONTROL_EVENT,
      payload: {
        matchControlId: matchControl?.id,
        event: { type: 'goal', entityId: contestant.id, entityType: 'contestant' },
      },
    });
  };

  const handleChangeShortName = (contestant: Contestant, name: string) => {
    updateContestant({ ...contestant, shortName: name });
  };

  const handleChangeColor = (
    contestant: Contestant,
    color: 'primaryColor' | 'secondaryColor',
    value: string
  ) => {
    updateContestant({ ...contestant, [color]: value });
  };

  const handleChangeCards = (contestant: Contestant, amount: number) => {
    if (!('redCards' in contestant)) return;

    if (contestant.redCards < amount) {
      handleAddRedCard(contestant);
    } else {
      handleDeleteRedCard(contestant);
    }
  };

  const handleAddRedCard = (contestant: Contestant) => {
    dispatch({
      type: REQUEST_CREATE_MATCH_CONTROL_EVENT,
      payload: {
        matchControlId: matchControl?.id,
        event: { type: 'red_card', entityId: contestant.id, entityType: 'contestant' },
      },
    });
  };

  const handleDeleteRedCard = (contestant: Contestant) => {
    dispatch({
      type: REQUEST_DELETE_MATCH_CONTROL_EVENT,
      payload: {
        matchControlId: matchControl?.id,
        event: { type: 'red_card', entityId: contestant.id, entityType: 'contestant' },
      },
    });
  };

  const handleStartTime = (clock: Clock) => {
    dispatch({
      type: REQUEST_START_MATCH_CONTROL_CLOCK,
      payload: { clock },
    });
  };

  const handleStopTime = (clock: Clock) => {
    dispatch({
      type: REQUEST_STOP_MATCH_CONTROL_CLOCK,
      payload: { clock },
    });
  };

  const handleDispatchControl = (control?: Control) => {
    if (!control) return;
    dispatch({ type: REQUEST_UPDATE_MATCH_CONTROL_GRAPHIC, payload: { rundownId, control } });
  };

  if (loading) {
    return (
      <div className="grid place-items-center h-full [&>div]:transform-none">
        <Loader />
      </div>
    );
  }

  if (!matchControl) return null;

  switch (matchControl.sport) {
    case 'soccer':
      return (
        <Soccer
          key={matchControl.id}
          matchControl={matchControl}
          handleChangeCards={handleChangeCards}
          handleChangeColor={handleChangeColor}
          handleChangeExtraTime={handleChangeExtraTime}
          handleChangeScore={handleChangeScore}
          handleChangeShortName={handleChangeShortName}
          handleChangeTime={handleChangeTime}
          handleDispatchControl={handleDispatchControl}
          handleStartTime={handleStartTime}
          handleStopTime={handleStopTime}
          matchControlGraphics={matchControlGraphics || []}
          handleUpdateMatchControl={(matchControl) => updateSoccerMatchControl(matchControl)}
          handleUpdateShootOut={(shootOut) => updateShootOutMatchControl(matchControl, shootOut)}
          variant={variant}
        />
      );
    case 'hockey':
      return (
        <Hockey
          key={matchControl.id}
          matchControl={matchControl}
          handleChangeShortName={handleChangeShortName}
          handleChangeColor={handleChangeColor}
          handleChangeScore={handleChangeScore}
          handleChangeTime={handleChangeTime}
          handleDispatchControl={handleDispatchControl}
          handleStartTime={handleStartTime}
          handleStopTime={handleStopTime}
          matchControlGraphics={matchControlGraphics || []}
          handleUpdateMatchControl={(matchControl) => updateHockeyMatchControl(matchControl)}
          handleUpdateShootOut={(shootOut) => updateShootOutMatchControl(matchControl, shootOut)}
        />
      );
    case 'tennis':
      return (
        <Tennis
          key={matchControl.id}
          contestant1={matchControl.contestants[0]}
          contestant2={matchControl.contestants[1]}
          matchControl={matchControl}
          handleUpdateMatchControl={(matchControl) => updateTennisMatchControl(matchControl)}
          handleDispatchControl={handleDispatchControl}
          matchControlGraphics={matchControlGraphics || []}
        />
      );
    case 'volleyball':
      return (
        <Volleyball
          key={matchControl.id}
          contestant1={matchControl.contestants[0]}
          contestant2={matchControl.contestants[1]}
          matchControl={matchControl}
          handleUpdateMatchControl={(matchControl) => updateVolleyballMatchControl(matchControl)}
          handleDispatchControl={handleDispatchControl}
          matchControlGraphics={matchControlGraphics || []}
        />
      );
    default:
      return (
        <div className="pt-2 text-center">This sport has no match control implemented yet</div>
      );
  }
};

export default MatchControl;
