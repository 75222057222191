import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { StateType } from 'src/redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Loader, Text } from '@southfields-digital/mpxlive-components';
import { REQUEST_GET_SPORT_DATA_STANDINGS } from 'src/redux/reducers/sportData';
import { getDomesticCompetitions, getPropertyValues } from '../utils';
import { getRepeaterItems } from 'src/components/GraphicPropertiesForm';
import { Option, Value } from 'src/components/Select';

import StandingsSelect from './StandingsSelect';
import Repeater from 'src/components/Repeater';

import styles from './Standings.module.scss';

type Props = {
  handleImportData: (values: PropertyValue[]) => void;
  selectedGraphic: PlaylistGraphic;
  selectedProperty: RepeaterProperties;
};

const LanguageOptions: Option[] = [
  { value: 'en-op', label: 'English' },
  { value: 'nl', label: 'Dutch' },
];

const TypeOptions: Option[] = [
  { value: 'no', label: 'Normal' },
  { value: 'yes', label: 'Virtual' },
];

export default function Standings({ handleImportData, selectedGraphic, selectedProperty }: Props) {
  const dispatch = useDispatch();
  const { standings, tournamentCalendar } = useSelector((state: StateType) => state.sportData);

  const [tmcl, setTmcl] = useState<string>(standings.data.tournamentCalendarId || '');
  const [language, setLanguage] = useState<Value>(LanguageOptions[0].value);
  const [type, setType] = useState<Value>(TypeOptions[0].value);

  const [startRange, setStartRange] = useState<number>(1);
  const [endRange, setEndRange] = useState<number>(0);

  const competitions = useMemo(
    () => getDomesticCompetitions(tournamentCalendar.data?.competition),
    [tournamentCalendar.data?.competition]
  );

  const propertyValues = useMemo(
    () =>
      getPropertyValues(
        selectedGraphic,
        standings.data.standingsData?.stage
          .find((stage) => stage.name === 'Regular Season' || stage.name === 'League Stage')
          ?.division.find((division) => division.type === 'total')?.ranking ?? []
      ),
    [selectedGraphic, standings.data.standingsData]
  );

  const repeaterValues = useMemo(
    () => (propertyValues.length ? getRepeaterItems(selectedProperty, propertyValues) : []),
    [propertyValues, selectedProperty]
  );

  const errorInRange = useMemo(() => startRange > endRange, [startRange, endRange]);

  useEffect(() => {
    if (!tmcl) return;
    setEndRange(0);
    dispatch({
      type: REQUEST_GET_SPORT_DATA_STANDINGS,
      payload: { tmcl, _lcl: language, live: type },
    });
  }, [tmcl, type, language]);

  useMemo(() => {
    if (!propertyValues.length) return;

    const lowerId = selectedProperty.id.toLowerCase();
    const midRange = Math.floor(propertyValues.length / 2);

    if (lowerId.includes('left')) {
      setStartRange(1);
      setEndRange(midRange);
    } else if (lowerId.includes('right')) {
      setStartRange(midRange + 1);
      setEndRange(propertyValues.length);
    } else if (!endRange) {
      setEndRange(propertyValues.length);
    }
  }, [propertyValues, selectedProperty]);

  const handleClickImport = () => {
    if (errorInRange) return;
    const newValues = propertyValues.slice(startRange - 1, endRange);
    handleImportData(newValues);
  };

  if (tournamentCalendar.loading) return <Loader />;
  if (standings.loading || !standings.data.standingsData?.stage.length) {
    return (
      <div className={styles.Container}>
        <div className={styles.Header} />
        <div className={styles.Content}>
          <div className={styles.FilterContainer}>
            <div className={styles.League}>
              <StandingsSelect
                label="Selected league"
                value={tmcl}
                options={competitions}
                onChange={(e) => setTmcl(e.currentTarget.value)}
              />
            </div>
            <div className={styles.Type}>
              <StandingsSelect
                label="Type"
                value={type}
                options={TypeOptions}
                onChange={(e) => setType(e.currentTarget.value)}
              />
            </div>
            <div className={styles.Language}>
              <StandingsSelect
                label="Language"
                value={language}
                options={LanguageOptions}
                onChange={(e) => setLanguage(e.currentTarget.value)}
              />
            </div>
          </div>
          {standings.loading && (
            <div className={styles.LoadingContainer}>
              <Loader className={styles.Loading} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        {propertyValues.length > 0 && (
          <div className={styles.RangeContainer}>
            <Input
              type="number"
              min={1}
              max={propertyValues.length}
              className={styles.RangeInput}
              value={startRange}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setStartRange(+e.target.value)}
            />
            <Text as="span" className={styles.RangeLabel}>
              t/m
            </Text>
            <Input
              type="number"
              min={startRange || 1}
              max={propertyValues.length}
              className={styles.RangeInput}
              value={endRange}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEndRange(+e.target.value)}
            />
          </div>
        )}
        <Button className={styles.ImportButton} onClick={handleClickImport} disabled={errorInRange}>
          Import
        </Button>
      </div>
      <div className={styles.Content}>
        <div className={styles.FilterContainer}>
          <div className={styles.League}>
            <StandingsSelect
              label="Selected league"
              value={tmcl}
              options={competitions}
              onChange={(e) => setTmcl(e.currentTarget.value)}
            />
          </div>
          <div className={styles.Type}>
            <StandingsSelect
              label="Type"
              value={type}
              options={TypeOptions}
              onChange={(e) => setType(e.currentTarget.value)}
            />
          </div>
          <div className={styles.Language}>
            <StandingsSelect
              label="Language"
              value={language}
              options={LanguageOptions}
              onChange={(e) => setLanguage(e.currentTarget.value)}
            />
          </div>
        </div>
        <Repeater
          id=""
          onChangeRows={() => {}}
          items={repeaterValues}
          isDisabled
          excludedIds={['divider', 'highlight']}
        />
      </div>
    </div>
  );
}
