import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import {
  REQUEST_GET_OUTPUTS_RUNDOWN,
  REQUEST_GO_LIVE_RUNDOWN,
  REQUEST_GO_OFFLINE_RUNDOWN,
} from 'src/redux/reducers/rundown';
import { getCanvasesWithoutOutput, getMappedCanvases, getUnavailableOutputs } from './utils';

import Dialog from './Dialog';
import { StatusDescription } from 'src/components/GoLive';
import { PlayButton } from '@southfields-digital/mpxlive-components';

import styles from './GoLive.module.scss';

export default function GoLive() {
  const dispatch = useDispatch();
  const { data, outputs } = useSelector((state: StateType) => state.rundown.detail);
  const [showDialog, setShowDialog] = useState(false);

  if (!data) return null;

  const mappedCanvases = getMappedCanvases(data.canvases, outputs);
  const canvasWithoutOutput = getCanvasesWithoutOutput(mappedCanvases);
  const unavailableOutputs = getUnavailableOutputs(mappedCanvases, data.id);
  const isLive = data.isLive;

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleToggleLive = () => {
    dispatch({ type: isLive ? REQUEST_GO_OFFLINE_RUNDOWN : REQUEST_GO_LIVE_RUNDOWN });
    handleCloseDialog();
  };

  const handleClickLive = () => {
    dispatch({ type: REQUEST_GET_OUTPUTS_RUNDOWN });
    setShowDialog(true);
  };

  return (
    <div className={styles.Container}>
      <Dialog
        description={<StatusDescription rundown={data} mappedCanvases={mappedCanvases} />}
        isLive={isLive}
        isOpen={showDialog}
        isDisabled={
          canvasWithoutOutput.length > 0 || !mappedCanvases.length || data.isLiveRundownLimitReached
        }
        isTakeOver={unavailableOutputs.length > 0}
        handleCloseDialog={handleCloseDialog}
        handleToggleLive={handleToggleLive}
      />
      <PlayButton hasIcon variant="live" isRunning={isLive} onClick={handleClickLive}>
        {isLive ? 'Go Offline' : 'Go Live'}
      </PlayButton>
    </div>
  );
}
