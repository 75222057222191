export type RundownTemplatesType = RundownTemplate[];
export type RundownTemplateType = RundownTemplate;

export type RundownTemplateOverviewResponseType = RundownTemplatesType;
export type RundownTemplateDetailResponseType = RundownTemplateType;

export type RundownTemplateCreateStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type RundownTemplateEditStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type RundownTemplateDetailStateType = {
  data: RundownTemplate | null;
  deleting: boolean;
  loading: boolean;
  error: string | null;
};

export type RundownTemplateOverviewStateType = {
  data: RundownTemplate[] | null;
  loading: boolean;
  error: string | null;
};

export type RundownTemplateStateType = {
  create: RundownTemplateCreateStateType;
  edit: RundownTemplateEditStateType;
  detail: RundownTemplateDetailStateType;
  overview: RundownTemplateOverviewStateType;
};

export const RUNDOWN_TEMPLATE = 'rundownTemplate';
export type RUNDOWN_TEMPLATE = typeof RUNDOWN_TEMPLATE;

export const REQUEST_CREATE_RUNDOWN_TEMPLATE = `${RUNDOWN_TEMPLATE}/requestCreateRundownTemplate`;
export type REQUEST_CREATE_RUNDOWN_TEMPLATE = typeof REQUEST_CREATE_RUNDOWN_TEMPLATE;

export const REQUEST_DELETE_RUNDOWN_TEMPLATE = `${RUNDOWN_TEMPLATE}/requestDeleteRundownTemplate`;
export type REQUEST_DELETE_RUNDOWN_TEMPLATE = typeof REQUEST_DELETE_RUNDOWN_TEMPLATE;

export const REQUEST_UPDATE_RUNDOWN_TEMPLATE = `${RUNDOWN_TEMPLATE}/requestUpdateRundownTemplate`;
export type REQUEST_UPDATE_RUNDOWN_TEMPLATE = typeof REQUEST_UPDATE_RUNDOWN_TEMPLATE;

export const REQUEST_GET_RUNDOWN_TEMPLATE = `${RUNDOWN_TEMPLATE}/requestGetRundownTemplate`;
export type REQUEST_GET_RUNDOWN_TEMPLATE = typeof REQUEST_GET_RUNDOWN_TEMPLATE;

export const REQUEST_GET_RUNDOWN_TEMPLATES = `${RUNDOWN_TEMPLATE}/requestGetRundownTemplates`;
export type REQUEST_GET_RUNDOWN_TEMPLATES = typeof REQUEST_GET_RUNDOWN_TEMPLATES;
