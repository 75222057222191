import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';

type Props = {
  period: number;
  handleAddPeriod: () => void;
  handleRemovePeriod: () => void;
};

const Period = ({ period, handleAddPeriod, handleRemovePeriod }: Props) => (
  <div className="flex flex-row gap-2 items-center">
    <MatchControlButton variant="icon" onClick={handleRemovePeriod}>
      <MatchControlIcon iconProps={{ icon: 'minus' }} />
    </MatchControlButton>
    {period || '1'}
    <MatchControlButton variant="icon" onClick={handleAddPeriod}>
      <MatchControlIcon iconProps={{ icon: 'plus' }} />
    </MatchControlButton>
  </div>
);

export default Period;
