export type GraphicType = Graphic;
export type GraphicsType = Graphic[];

export type GraphicResponseType = GraphicType;
export type GraphicsResponseType = GraphicsType;

export type GraphicCreateStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type GraphicUpdateStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type GraphicDeleteStateType = {
  data: null;
  deleting: boolean;
  error: string | null;
};

export type GraphicGetStateType = {
  data: Graphic | null;
  loading: boolean;
  error: string | null;
};

export type GraphicGetAllStateType = {
  data: Graphic[] | null;
  selectedGraphic: Graphic | null;
  loading: boolean;
  error: string | null;
};

export type GraphicGetAllFromPackageStateType = {
  data: Graphic[] | null;
  selectedId: string | null;
  loading: boolean;
  error: string | null;
};

export type GraphicGetAllFromRundownStateType = {
  data: Graphic[] | null;
  selectedId: string | null;
  loading: boolean;
  error: string | null;
};

export type GraphicStateType = {
  create: GraphicCreateStateType;
  update: GraphicUpdateStateType;
  delete: GraphicDeleteStateType;
  get: GraphicGetStateType;
  getAll: GraphicGetAllStateType;
  allFromPackage: GraphicGetAllFromPackageStateType;
  allFromRundown: GraphicGetAllFromRundownStateType;
};

export const GRAPHIC = 'graphic';
export type GRAPHIC = typeof GRAPHIC;

export const REQUEST_CREATE_GRAPHIC = `${GRAPHIC}/requestCreateGraphic`;
export type REQUEST_CREATE_GRAPHIC = typeof REQUEST_CREATE_GRAPHIC;

export const REQUEST_DELETE_GRAPHIC = `${GRAPHIC}/requestDeleteGraphic`;
export type REQUEST_DELETE_GRAPHIC = typeof REQUEST_DELETE_GRAPHIC;

export const REQUEST_UPDATE_GRAPHIC = `${GRAPHIC}/requestUpdateGraphic`;
export type REQUEST_UPDATE_GRAPHIC = typeof REQUEST_UPDATE_GRAPHIC;

export const REQUEST_GET_GRAPHIC_BY_ID = `${GRAPHIC}/requestGetGraphicById`;
export type REQUEST_GET_GRAPHIC_BY_ID = typeof REQUEST_GET_GRAPHIC_BY_ID;

export const REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID = `${GRAPHIC}/requestGetAllGraphicsByGraphicPackageId`;
export type REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID =
  typeof REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID;

export const REQUEST_GET_ALL_GRAPHICS_BY_RUNDOWN_ID = `${GRAPHIC}/requestGetAllGraphicsByRundownId`;
export type REQUEST_GET_ALL_GRAPHICS_BY_RUNDOWN_ID = typeof REQUEST_GET_ALL_GRAPHICS_BY_RUNDOWN_ID;
