import { Directory } from '@southfields-digital/mpxlive-components';

export const recursivelyFindOpenDirectory = (
  directories: Directory[],
  ids: string[]
): Directory | undefined => {
  const [currentId, ...remainingIds] = ids;
  const currentDirectory = directories.find(({ id }) => currentId === id);

  return remainingIds.length === 0
    ? currentDirectory
    : recursivelyFindOpenDirectory(currentDirectory?.directories || [], remainingIds);
};
