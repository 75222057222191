import { Clock as ClockType } from 'src/types/matchControl/clock';
import { Contestant as ContestantType } from 'src/types/matchControl/contestant';

import Clock from './Clock';
import Contestant from './Contestant';

import styles from './RundownMatchControl.module.scss';

type Props = {
  handleClickMedia: (contestant: ContestantType, index: number) => void;
  handleChangeContestant: (contestant: ContestantType, index: number) => void;
  handleChangeClock: (clock: ClockType, index: number) => void;
  contestants: ContestantType[];
  clocks: ClockType[];
};

const Soccer = ({
  clocks,
  contestants,
  handleChangeContestant,
  handleChangeClock,
  handleClickMedia,
}: Props) => (
  <div className={styles.SportContainer}>
    <div className={styles.Contestants}>
      {contestants?.map((contestant, idx) => (
        <Contestant
          key={idx}
          sport="soccer"
          contestant={contestant}
          handleChangeContestant={(contestant) => handleChangeContestant(contestant, idx)}
          handleClickMedia={(contestant) => handleClickMedia(contestant, idx)}
          inverted={idx % 2 === 1}
        />
      ))}
    </div>
    <div className={styles.Clocks}>
      {clocks?.map((clock, idx) => (
        <div key={idx}>
          <Clock clock={clock} handleChangeClock={(clock) => handleChangeClock(clock, idx)} />
        </div>
      ))}
    </div>
  </div>
);

export default Soccer;
