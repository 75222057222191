import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import App from './App.tsx';

import './index.scss';
import '@southfields-digital/mpxlive-components/build/index.css';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  releaseStage: import.meta.env.VITE_BUGSNAG_STAGE || 'development',
  plugins: [new BugsnagPluginReact()],
});

BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter
      future={{
        v7_startTransition: true,
      }}
    >
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
