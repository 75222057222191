import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import {
  REQUEST_DELETE_PLAYLIST,
  REQUEST_UPDATE_PLAYLIST,
  REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID,
  PlaylistGetAllStateType,
  REQUEST_SET_SELECTED_PLAYLIST,
  REQUEST_REORDER_PLAYLISTS,
  REQUEST_DUPLICATE_PLAYLIST,
  REQUEST_STOP_AUTO_PLAY_PLAYLIST,
  REQUEST_START_AUTO_PLAY_PLAYLIST,
} from 'src/redux/reducers/playlist';
import useKeypress from 'src/hooks/useKeypress';

import Playlist, { IPlaylistProps } from 'src/components/Playlist/Playlist';
import { SortableList, Text } from '@southfields-digital/mpxlive-components';

import styles from './Playlists.module.scss';
import { PlaylistGraphicStateType } from 'src/redux/reducers/playlistGraphic';

type PlaylistsProps = {
  rundownId: string;
};

export default function Playlists({ rundownId }: PlaylistsProps) {
  const dispatch = useDispatch();
  const { data: playlists, selectedId }: PlaylistGetAllStateType = useSelector(
    (state: StateType) => state.playlist.getAll
  );
  const { playlistGraphics }: PlaylistGraphicStateType = useSelector(
    (state: StateType) => state.playlistGraphic
  );

  useEffect(() => {
    dispatch({
      type: REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID,
      payload: { rundownId, firstSelected: true },
    });
  }, [dispatch, rundownId]);

  const [editPlaylistId, setEditPlaylistId] = useState<string | null>(null);

  const handleSubmitEdit = (playlistName: string) => {
    if (editPlaylistId) {
      dispatch({
        type: REQUEST_UPDATE_PLAYLIST,
        payload: { id: editPlaylistId, name: playlistName, rundownId },
      });
    }
    setEditPlaylistId(null);
  };

  const handleUpdatePlaylist = (
    playlistId: string,
    data: { name?: string; autoplay?: AutoPlay }
  ) => {
    dispatch({
      type: REQUEST_UPDATE_PLAYLIST,
      payload: { id: playlistId, ...data },
    });
  };

  const handleClickDelete = (playlistId: string) => {
    dispatch({ type: REQUEST_DELETE_PLAYLIST, payload: { id: playlistId } });
  };

  const handleClickPlaylist = (playlistId: string) => {
    dispatch({ type: REQUEST_SET_SELECTED_PLAYLIST, payload: { id: playlistId } });
  };

  const handleSortPlaylists = (sortedValues: IPlaylistProps[]) => {
    const sortedPlaylists: string[] = sortedValues.map((item) => item.id);
    dispatch({
      type: REQUEST_REORDER_PLAYLISTS,
      payload: { playlistIds: sortedPlaylists, rundownId },
    });
  };

  const handleDuplicatePlaylist = (playlistId: string) => {
    dispatch({ type: REQUEST_DUPLICATE_PLAYLIST, payload: { id: playlistId, rundownId } });
  };

  const handleToggleAutoPlayPlaylist = (playlist: Playlist) => {
    dispatch({
      type: playlist.autoplay.isRunning
        ? REQUEST_STOP_AUTO_PLAY_PLAYLIST
        : REQUEST_START_AUTO_PLAY_PLAYLIST,
      payload: {
        playlistId: playlist.id,
      },
    });
  };

  const previousPlaylist = () => {
    if (!playlists) return;

    const index = playlists.findIndex((playlist) => playlist.id === selectedId);
    if (index > 0) {
      const playlistId = playlists[index - 1].id;
      dispatch({ type: REQUEST_SET_SELECTED_PLAYLIST, payload: { id: playlistId } });
    }
  };

  const nextPlaylist = () => {
    if (!playlists) return;

    const index = playlists.findIndex((playlist) => playlist.id === selectedId);
    if (index < playlists.length - 1) {
      const playlistId = playlists[index + 1].id;
      dispatch({ type: REQUEST_SET_SELECTED_PLAYLIST, payload: { id: playlistId } });
    }
  };

  useKeypress('PageUp', { shiftKey: true }, previousPlaylist);
  useKeypress('PageDown', { shiftKey: true }, nextPlaylist);

  const items = useMemo<IPlaylistProps[]>(
    () =>
      playlists?.map((playlist) => ({
        id: playlist.id,
        name: playlist.name,
        autoplay: playlist.autoplay,
        active: selectedId === playlist.id,
        isOpen: playlist.id === editPlaylistId,
        numberGraphics:
          playlistGraphics?.filter((graphic) => graphic.playlist.id === playlist.id).length || 0,
        onUpdatePlaylist: (autoplay: AutoPlay) => handleUpdatePlaylist(playlist.id, { autoplay }),
        onClickAutoPlay: () => handleToggleAutoPlayPlaylist(playlist),
        onClick: () => handleClickPlaylist(playlist.id),
        onEdit: () => setEditPlaylistId(playlist.id),
        onCopy: () => handleDuplicatePlaylist(playlist.id),
        onDelete: () => handleClickDelete(playlist.id),
        onClose: () => setEditPlaylistId(null),
        onSubmit: (name: string) => handleSubmitEdit(name),
      })) || [],
    [
      playlists,
      selectedId,
      editPlaylistId,
      handleClickPlaylist,
      setEditPlaylistId,
      handleClickDelete,
      handleSubmitEdit,
      handleDuplicatePlaylist,
    ]
  );

  if (!playlists?.length) {
    return (
      <div className={styles.NoContent}>
        <Text as="p">No playlists</Text>
      </div>
    );
  }

  return (
    <div className={styles.Container}>
      <SortableList
        items={items}
        setItems={handleSortPlaylists}
        activeId={selectedId || undefined}
        Component={Playlist}
      />
    </div>
  );
}
