import { Icon, Input } from '@southfields-digital/mpxlive-components';
import styles from './CanvasGraphicsForm.module.scss';
import { useEffect } from 'react';

interface IRundownFormProps {
  title: string;
  canvases: Canvas[];
  graphics: Graphic[];
  state: RundownTemplateCanvas[];
  stateUpdated: (state: (RundownTemplateCanvas | RundownCanvas)[]) => void;
  showToggleAll?: boolean;
}

export default function CanvasGraphicsForm({
  title,
  canvases,
  graphics,
  state,
  stateUpdated,
  showToggleAll = true,
}: IRundownFormProps) {
  useEffect(() => {
    if (state && canvases.length && state.length !== canvases.length) {
      configureState(state, canvases);
    }
  }, [state, canvases]);

  const isChecked = (graphic: Graphic, relatedCanvas?: RundownTemplateCanvas) =>
    relatedCanvas ? relatedCanvas.graphics.some(({ id }) => id === graphic.id) : false;

  const getCheckedGraphics = (
    rundownTemplateCanvas: RundownTemplateCanvas
  ): { graphic: Graphic; checked: boolean }[] =>
    graphics.map((graphic) => ({
      graphic,
      checked: isChecked(graphic, rundownTemplateCanvas),
    }));

  const configureState = (state: (RundownTemplateCanvas | RundownCanvas)[], canvases: Canvas[]) => {
    const updatedState = canvases.map((canvas) => {
      const existingCanvas = state.find(({ id }) => id === canvas.id);
      if (existingCanvas) return existingCanvas;

      return { id: canvas.id, name: canvas.name, graphics: [], canvasBackgroundId: null };
    });

    stateUpdated(updatedState);
  };

  const handleToggleGraphic = (canvas: Canvas, graphic: Graphic) => {
    if (!state) return;

    const canvasId = canvas.id;
    const updatedState: RundownTemplateCanvas[] = state.map((canvas) => {
      if (canvas.id !== canvasId) return canvas;

      const existingGraphic = canvas.graphics.some(({ id }) => id === graphic.id);
      const graphicsToInject: Graphic[] = [graphic];

      if (graphic.types.includes('bundle')) {
        graphicsToInject.push(...getChildGraphics(graphic.id));
      }

      const graphics: Graphic[] = existingGraphic
        ? canvas.graphics.filter(({ id }) => !Boolean(graphicsToInject.find((g) => g.id === id)))
        : [...canvas.graphics, ...graphicsToInject];

      return { ...canvas, graphics };
    }, []);

    if (stateUpdated) {
      stateUpdated(updatedState);
    }
  };

  const getChildGraphics = (parentGraphicId: string) => {
    return graphics.filter((graphic) => graphic.parentId === parentGraphicId);
  };

  const toggleAllGraphics = (isChecked: boolean, targetCanvas: Canvas) => {
    if (!state) return;

    const updatedState = state.map((canvas) => {
      if (canvas.id !== targetCanvas.id) return canvas;

      const newGraphics = isChecked
        ? canvas.graphics.concat(
            graphics.filter((graphic) => !canvas.graphics.some((g) => g.id === graphic.id))
          )
        : canvas.graphics.filter((graphic) => !graphics.some((g) => g.id === graphic.id));

      return { ...canvas, graphics: newGraphics };
    });

    stateUpdated?.(updatedState);
  };

  const allChecked = (canvas: Canvas) => {
    if (!state) return false;

    const relatedCanvas = state.find(({ id }) => id === canvas.id);
    if (!relatedCanvas) return false;

    const canvasGraphics = getCheckedGraphics(relatedCanvas);
    if (!canvasGraphics.length) return false;

    return canvasGraphics.every(({ checked }) => checked);
  };

  return (
    <table className={styles.CanvasGraphicsForm}>
      <thead>
        <tr>
          <th className={styles.GraphicColumn}>{title}</th>
          {canvases?.map((canvas, index) => {
            return (
              <th key={index} className={styles.CanvasGraphicColumn}>
                {canvas.name}
              </th>
            );
          })}
        </tr>
        {showToggleAll && (
          <tr className={styles.CanvasGraphicToggleAllRow}>
            <th>Toggle all</th>
            {canvases?.map((canvas, index) => {
              const allGraphicsChecked = allChecked(canvas);
              return (
                <th key={index} className={styles.CanvasGraphicColumn}>
                  <Input
                    classNameWrapper={styles.CanvasGraphicsToggle}
                    size="sm"
                    type="toggle"
                    onChange={() => toggleAllGraphics(!allGraphicsChecked, canvas)}
                    checked={!!allGraphicsChecked}
                  />
                </th>
              );
            })}
          </tr>
        )}
      </thead>
      <tbody>
        {graphics?.map((graphic) => (
          <tr key={graphic.id}>
            <td className={styles.GraphicColumn}>
              {graphic.parentId ? <Icon icon="minus" weight="bold" /> : null} {graphic.name}
            </td>

            {canvases?.map((canvas) => {
              const relatedCanvas = state?.find(({ id }) => id === canvas.id);
              const checked = isChecked(graphic, relatedCanvas);
              return (
                <td className={styles.CanvasGraphicColumn} key={`${graphic.id}-${canvas.id}`}>
                  <Input
                    key={`${graphic.id}-${canvas.id}-input`}
                    classNameWrapper={styles.CanvasGraphicsToggle}
                    checked={!!checked}
                    onChange={() => handleToggleGraphic(canvas, graphic)}
                    disabled={graphic.parentId !== null}
                    size="sm"
                    type="toggle"
                  />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
