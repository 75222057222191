import MatchControlButton from './MatchControlButton';

type Props = {
  clockLive: boolean;
  disabled?: boolean;
  variant?: 'icon' | 'default';
  handleToggleGraphic: () => void;
};

const ClockIn = ({ clockLive = false, disabled = false, variant, handleToggleGraphic }: Props) => (
  <div className="flex justify-center">
    <MatchControlButton
      isRunning={clockLive}
      onClick={handleToggleGraphic}
      disabled={disabled}
      variant={variant}
    >
      CLOCK
    </MatchControlButton>
  </div>
);

export default ClockIn;
