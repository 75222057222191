import { useEffect } from 'react';

export interface KeypressOptions {
  ctrlKey?: boolean;
  shiftKey?: boolean;
  altKey?: boolean;
  metaKey?: boolean;
}

const useKeypress = (
  key: KeyboardEvent['key'],
  keyOptions: KeypressOptions,
  callback: Function
) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    const optionsMatch =
      event.ctrlKey === !!keyOptions.ctrlKey &&
      event.shiftKey === !!keyOptions.shiftKey &&
      event.altKey === !!keyOptions.altKey &&
      event.metaKey === !!keyOptions.metaKey;

    if (event.key === key && optionsMatch) {
      callback();
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [key, keyOptions, callback]);
};

export default useKeypress;
