import { createSlice } from '@reduxjs/toolkit';
import { NOTIFICATION } from './types';

export const notificationSlice = createSlice({
  name: NOTIFICATION,
  initialState: {
    toastId: null,
  },
  // Apply state changes
  reducers: {
    setToastId: (state, action) => {
      state.toastId = action.payload.toastId;
    },
    resetToastId: (state) => {
      state.toastId = null;
    },
    notify: () => {},
  },
});

const notificationActions = notificationSlice.actions;
const notificationReducer = notificationSlice.reducer;

export { notificationActions, notificationReducer };
