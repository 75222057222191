export type PlaylistGraphicType = PlaylistGraphic;
export type PlaylistGraphicsType = PlaylistGraphic[];

export type PlaylistGraphicResponseType = PlaylistGraphicType;
export type PlaylistGraphicsResponseType = PlaylistGraphicsType;

export type PlaylistGraphicStateType = {
  playlistGraphics: PlaylistGraphicsType | null;
  selectedPlaylistGraphicId: string | null;
  selectedPlaylistGraphic: PlaylistGraphicType | null;
  loading: boolean;
  error: string | null;
};

export const PLAYLIST_GRAPHIC = 'playlistGraphic';
export type PLAYLIST_GRAPHIC = typeof PLAYLIST_GRAPHIC;

export const REQUEST_CREATE_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestCreatePlaylistGraphic`;
export type REQUEST_CREATE_PLAYLIST_GRAPHIC = typeof REQUEST_CREATE_PLAYLIST_GRAPHIC;

export const REQUEST_DELETE_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestDeletePlaylistGraphic`;
export type REQUEST_DELETE_PLAYLIST_GRAPHIC = typeof REQUEST_DELETE_PLAYLIST_GRAPHIC;

export const REQUEST_DUPLICATE_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestDuplicatePlaylistGraphic`;
export type REQUEST_DUPLICATE_PLAYLIST_GRAPHIC = typeof REQUEST_DUPLICATE_PLAYLIST_GRAPHIC;

export const REQUEST_CONNECT_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestConnectPlaylistGraphic`;
export type REQUEST_CONNECT_PLAYLIST_GRAPHIC = typeof REQUEST_CONNECT_PLAYLIST_GRAPHIC;

export const REQUEST_TOGGLE_CONCEPT_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestToggleConceptPlaylistGraphic`;
export type REQUEST_TOGGLE_CONCEPT_PLAYLIST_GRAPHIC =
  typeof REQUEST_TOGGLE_CONCEPT_PLAYLIST_GRAPHIC;

export const REQUEST_UPDATE_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestUpdatePlaylistGraphic`;
export type REQUEST_UPDATE_PLAYLIST_GRAPHIC = typeof REQUEST_UPDATE_PLAYLIST_GRAPHIC;

export const REQUEST_UPDATE_STATUS_PLAYLIST_GRAPHICS = `${PLAYLIST_GRAPHIC}/requestUpdateStatusPlaylistGraphics`;
export type REQUEST_UPDATE_STATUS_PLAYLIST_GRAPHICS =
  typeof REQUEST_UPDATE_STATUS_PLAYLIST_GRAPHICS;

export const REQUEST_UPDATE_PLAYLIST_GRAPHICS = `${PLAYLIST_GRAPHIC}/requestUpdatePlaylistGraphics`;
export type REQUEST_UPDATE_PLAYLIST_GRAPHICS = typeof REQUEST_UPDATE_PLAYLIST_GRAPHICS;

export const RECEIVE_UPDATE_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/receiveUpdatePlaylistGraphic`;
export type RECEIVE_UPDATE_PLAYLIST_GRAPHIC = typeof RECEIVE_UPDATE_PLAYLIST_GRAPHIC;

export const REQUEST_REORDER_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestReorderPlaylistGraphics`;
export type REQUEST_REORDER_PLAYLIST_GRAPHIC = typeof REQUEST_REORDER_PLAYLIST_GRAPHIC;

export const REQUEST_GET_ALL_PLAYLIST_GRAPHICS = `${PLAYLIST_GRAPHIC}/requestGetAllPlaylistGraphics`;
export type REQUEST_GET_ALL_PLAYLIST_GRAPHICS = typeof REQUEST_GET_ALL_PLAYLIST_GRAPHICS;

export const REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC = `${PLAYLIST_GRAPHIC}/requestSetSelectedPlaylistGraphic`;
export type REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC = typeof REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC;

export type REQUEST_ON_QUICKIFY_TOGGLE = typeof REQUEST_ON_QUICKIFY_TOGGLE;
export const REQUEST_ON_QUICKIFY_TOGGLE = `${PLAYLIST_GRAPHIC}/requestQuickifyToggle`;
