import { Id, Items, Row } from './types';
import Repeater, { isRepeater } from '.';
import Cell from './Cell';

type VerticalRepeaterRowItemProps = {
  currentItem: Row;
  rowIndex: number;
  handleChangeRepeater: (id: Id, rowIndex: number, rows: Items[]) => void;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
};

const VerticalRepeaterRowItem = ({
  currentItem,
  rowIndex,
  handleChangeRepeater,
  handleChangeValue,
}: VerticalRepeaterRowItemProps) => (
  <td>
    {isRepeater(currentItem) ? (
      <Repeater parentRowIndex={rowIndex} onChangeRows={handleChangeRepeater} {...currentItem} />
    ) : (
      <Cell item={currentItem} rowIndex={rowIndex} handleChangeValue={handleChangeValue} />
    )}
  </td>
);

export default VerticalRepeaterRowItem;
