import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../redux/reducers';
import rootSaga from '../redux/sagas';

// Add Middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

// Congifure Store
const store = configureStore({
  reducer: rootReducer,
  middleware,
});

// Run saga
sagaMiddleware.run(rootSaga);

export { configureStore };

export default store;
