export type CanvasCollectionStateType = {
  data: Canvas[] | null;
  loading: boolean;
  error: string | null;
};

export type CanvasBackgroundCollectinStateType = {
  data: CanvasBackground[] | null;
  loading: boolean;
  error: string | null;
};

export type CanvasStateType = {
  collection: CanvasCollectionStateType;
  backgroundCollection: CanvasBackgroundCollectinStateType;
};

export const CANVAS = 'canvas';
export type CANVAS = typeof CANVAS;

export const REQUEST_GET_CANVASES = `${CANVAS}/requestGetCanvases`;
export type REQUEST_GET_CANVASES = typeof REQUEST_GET_CANVASES;

export const REQUEST_GET_CANVAS_BACKGROUNDS = `${CANVAS}/requestGetCanvasBackgrounds`;
export type REQUEST_GET_CANVAS_BACKGROUNDS = typeof REQUEST_GET_CANVAS_BACKGROUNDS;
