import { Button } from '@southfields-digital/mpxlive-components';

import styles from './MatchStats.module.scss';

type StatRowProps = {
  label: string;
  homeValue?: string | number;
  awayValue?: string | number;
  suffix?: string;
  onClickImport?: (value: string) => void;
};

export default function StatRow({
  label,
  homeValue,
  awayValue,
  onClickImport,
  suffix = '',
}: StatRowProps) {
  const valueHome = homeValue ?? 0;
  const valueAway = awayValue ?? 0;

  if (!valueHome && !valueAway) return null;
  return (
    <div className={styles.RowContainer}>
      <div className={styles.RowLabel}>{label}</div>
      <div>{`${valueHome}${suffix}`}</div>
      <div>{`${valueAway}${suffix}`}</div>
      <div>
        {onClickImport && (
          <Button
            onClick={() => onClickImport(`${valueHome}${suffix} - ${valueAway}${suffix}`)}
            variant="tertiary"
            iconProps={{ icon: 'copy', size: 16, color: '#5B5D70' }}
          />
        )}
      </div>
    </div>
  );
}
