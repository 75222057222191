import { useEffect } from 'react';
import { getISODateTimeString, toDatetimeLocal } from 'src/utils';

import Select, { Option } from 'src/components/Select';
import { FormGroup, Input } from '@southfields-digital/mpxlive-components';

import styles from './RundownForm.module.scss';

interface IRundownFormProps {
  state: Partial<Rundown>;
  graphicsPackages: Option[];
  stateUpdated: (state: Partial<Rundown>) => void;
}

export default function RundownForm({ state, graphicsPackages, stateUpdated }: IRundownFormProps) {
  const { date, time } = state.start ? toDatetimeLocal(state.start) : { date: '', time: '' };

  const graphicPackageNote = !state.id
    ? 'After creating a rundown, the graphics package will be permanent and cannot be changed.'
    : 'The graphics package cannot be changed. Create a new rundown to select a different graphics package.';

  useEffect(() => {
    if (state.isPersistent) {
      stateUpdated({ start: '1970-01-01T00:00:00Z' });
    }
  }, []);

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    stateUpdated({ start: getISODateTimeString(`${e.currentTarget.value}T${time}`) });
  };

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    stateUpdated({ start: getISODateTimeString(`${date}T${e.currentTarget.value}`) });
  };

  return (
    <div className={styles.RundownForm}>
      <FormGroup label="Rundown Name" htmlFor="rundownName">
        <Input
          id="rundownName"
          value={state.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            stateUpdated({ name: e.currentTarget.value })
          }
        />
      </FormGroup>

      {state.isPersistent ? null : (
        <>
          <FormGroup label="Date" htmlFor="rundownDate">
            <Input id="rundownDate" onChange={handleChangeDate} type="date" value={date} />
          </FormGroup>

          <FormGroup label="Start Time" htmlFor="rundownTime">
            <Input id="rundownTime" onChange={handleChangeTime} value={time} type="time" step={1} />
          </FormGroup>
        </>
      )}

      <FormGroup label="Graphics Package" htmlFor="graphicsPackage">
        <Select
          id="graphicsPackage"
          disabled={Boolean(state.id)}
          onChange={(e) => {
            stateUpdated({
              canvases: [],
              graphicPackageId: e.currentTarget.value,
            });
          }}
          options={[{ label: '-', value: '' }, ...graphicsPackages]}
          placeholder="Pick a graphics package"
          value={state.graphicPackageId || ''}
        />
        <p className={styles.RundownGraphicPackageNote}>{graphicPackageNote}</p>
      </FormGroup>
    </div>
  );
}
