import {
  SelectEffect,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';
import api from 'src/services/graphicsApi';
import {
  actions,
  REQUEST_GET_RUNDOWNS,
  REQUEST_GET_RUNDOWN,
  REQUEST_CREATE_RUNDOWN,
  REQUEST_DELETE_RUNDOWN,
  REQUEST_UPDATE_RUNDOWN,
  RundownsResponseType,
  RundownResponseType,
  REQUEST_GO_LIVE_RUNDOWN,
  REQUEST_GET_OUTPUTS_RUNDOWN,
  REQUEST_GO_OFFLINE_RUNDOWN,
  REQUEST_UPDATE_RUNDOWN_OPERATOR,
  UPDATE_CURRENT_RUNDOWN,
} from '../reducers/rundown';
import { PayloadAction } from '@reduxjs/toolkit';
import { REQUEST_SET_SELECTED_PLAYLIST } from '../reducers/playlist';
import { StateType } from '../reducers';
import { NavigateFunction } from 'react-router';
import { NOTIFY } from '../reducers/notification';
import { REQUEST_NOTIFY_GO_LIVE_RUNDOWN } from '../reducers/desktopApp';

function* createRundown(
  action: PayloadAction<{ data: Partial<Rundown>; navigate: NavigateFunction }>
): Generator<CallEffect | PutEffect, void, RundownApiResponse> {
  try {
    const { navigate, data } = action.payload;
    yield delay(500);
    const response = yield call(api.post, `/rundowns`, data);
    const apiResponse = response as RundownApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveCreateRundown());
      navigate(`/rundown/${apiResponse.data.id}`);
    }
  } catch (error) {
    yield put(actions.failedCreateRundown('Something went wrong. Could not create rundown'));
  }
}

function* deleteRundown(
  action: PayloadAction<{ id: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    yield delay(500);
    const response = yield call(api.delete, `/rundowns/${action.payload.id}`);
    const apiResponse = response as RundownApiResponse;

    if (apiResponse?.status === 200) {
      yield put(actions.receiveDeleteRundown(action.payload.id));
      yield put({
        type: NOTIFY,
        payload: {
          variant: 'info',
          title: 'Rundown deleted',
        },
      });
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(actions.failedDeleteRundown());
  }
}

function* updateRundown(
  action: PayloadAction<{ data: Rundown }>
): Generator<CallEffect | PutEffect, void, RundownApiResponse> {
  try {
    const { id, ...payload } = action.payload.data;
    yield delay(500);
    const response = yield call(api.patch, `/rundowns/${id}`, payload);
    const apiResponse = response as RundownApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveUpdateRundown(action.payload.data));
      yield put({
        type: NOTIFY,
        payload: {
          variant: 'success',
          title: 'Your rundown has been updated',
        },
      });
      yield put({ type: REQUEST_GET_OUTPUTS_RUNDOWN });
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(actions.failedUpdateRundown());
  }
}

type RundownsApiResponse = void | { data: RundownsResponseType; status: number };
function* getRundowns(
  action: PayloadAction<{ date?: string; persistent?: boolean }>
): Generator<PutEffect | CallEffect<RundownsApiResponse>, void, RundownsApiResponse> {
  try {
    const filters: { [key: string]: string | boolean } = {
      ...(action?.payload?.date ? { date: action.payload.date } : {}),
      ...(action?.payload?.persistent ? { persistent: action.payload.persistent } : {}),
    };
    const makeFilterString = () =>
      Object.keys(filters)
        .map((filter) => `filter[${filter}]=${filters[filter]}`)
        .join('&');

    const response = yield call(api.get, `/rundowns?${makeFilterString()}`);
    const apiResponse = response as RundownsApiResponse;
    if (apiResponse?.data) {
      yield put(actions.receiveGetRundowns(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetRundowns((error as Error)?.message as string) || 'Something went wrong'
    );
  }
}

type RundownApiResponse = void | { data: RundownResponseType; status: number };
function* getRundown(
  action: PayloadAction<{ id: string | null }>
): Generator<PutEffect | CallEffect<RundownApiResponse>, void, RundownApiResponse> {
  try {
    const id = action.payload.id;
    if (!id) {
      yield put(actions.receiveGetRundown(null));
      return;
    }
    const response = yield call(api.get, `/rundowns/${id}`);
    const apiResponse = response as RundownApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetRundown(apiResponse.data));
      yield put({ type: REQUEST_SET_SELECTED_PLAYLIST, payload: null });
      yield put({ type: REQUEST_GET_OUTPUTS_RUNDOWN });
    }
  } catch (error) {
    yield put(
      actions.failedGetRundown((error as Error)?.message as string) || 'Something went wrong'
    );
  }
}

function* updateCurrentRundown(
  action: PayloadAction<{ id: string | null }>
): Generator<PutEffect | CallEffect<RundownApiResponse>, void, RundownApiResponse> {
  try {
    const { id } = action.payload;
    if (!id) return;

    const response = yield call(api.get, `/rundowns/${id}`);
    const apiResponse = response as RundownApiResponse;

    if (apiResponse?.data) {
      yield put(actions.updateCurrentRundown(apiResponse.data));
      yield put({ type: REQUEST_GET_OUTPUTS_RUNDOWN });
    }
  } catch (error) {}
}

function* goLiveRundown(): Generator<PutEffect | CallEffect | SelectEffect> {
  try {
    const state = yield select((state: StateType) => state.rundown.detail.data);
    const rundown = state as Rundown;

    const response = yield call(api.post, `/rundowns/${rundown.id}/outputs`);
    const apiResponse = response as any;

    if (apiResponse?.data) {
      yield put(actions.receiveGoLiveRundown(apiResponse.data));
      yield put({ type: REQUEST_NOTIFY_GO_LIVE_RUNDOWN, payload: { data: rundown } });
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

function* goOfflineRundown(): Generator<PutEffect | CallEffect | SelectEffect> {
  try {
    const state = yield select((state: StateType) => state.rundown.detail.data);
    const rundown = state as Rundown;

    const response = yield call(api.delete, `/rundowns/${rundown.id}/outputs`);
    const apiResponse = response as any;

    if (apiResponse?.data) {
      yield put(actions.receiveGoOfflineRundown(apiResponse.data));
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

function* updateRundownOperator(
  action: PayloadAction<{ id: string | null }>
): Generator<PutEffect | CallEffect | SelectEffect> {
  try {
    const response = yield call(api.post, `/rundowns/${action.payload.id}/takeover`);
    const apiResponse = response as RundownApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveUpdateRundownOperator());
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

type OutputsApiResponse = void | { data: RundownOutput[]; status: number };
function* getOutputsRundown(): Generator<PutEffect | CallEffect | SelectEffect> {
  try {
    const state = yield select((state: StateType) => state.rundown.detail.data);
    const rundown = state as Rundown;

    const response = yield call(api.get, `/rundowns/${rundown.id}/outputs`);
    const apiResponse = response as OutputsApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetOutputsRundown(apiResponse.data));
    }
  } catch (error) {}
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_RUNDOWN, createRundown);
  yield takeLatest(REQUEST_DELETE_RUNDOWN, deleteRundown);
  yield takeLatest(REQUEST_UPDATE_RUNDOWN, updateRundown);
  yield takeLatest(REQUEST_GET_RUNDOWNS, getRundowns);
  yield takeLatest(REQUEST_GET_RUNDOWN, getRundown);
  yield takeLatest(REQUEST_GET_OUTPUTS_RUNDOWN, getOutputsRundown);
  yield takeLatest(REQUEST_GO_LIVE_RUNDOWN, goLiveRundown);
  yield takeLatest(REQUEST_GO_OFFLINE_RUNDOWN, goOfflineRundown);
  yield takeLeading(UPDATE_CURRENT_RUNDOWN, updateCurrentRundown);
  yield takeLeading(REQUEST_UPDATE_RUNDOWN_OPERATOR, updateRundownOperator);
}
