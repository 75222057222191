import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SportDataStateType, SPORT_DATA } from './types';
import { FixturesResponse } from 'src/types/statsperform/fixtures';

const userInitialState: SportDataStateType = {
  tournamentCalendar: {
    data: null,
    loading: true,
    error: null,
  },
  standings: {
    data: {
      tournamentCalendarId: null,
      standingsData: null,
    },
    loading: false,
    error: null,
  },
  schedule: {
    data: null,
    loading: true,
    error: null,
  },
  matchData: {
    data: {
      fixtureId: null,
      fixtureData: null,
    },
    loading: true,
    error: null,
  },
  fixtures: {
    data: null,
    loading: true,
    error: null,
  },
};

export const sportDataSlice = createSlice({
  name: SPORT_DATA,
  initialState: userInitialState,
  reducers: {
    requestStandings: (state: SportDataStateType) => {
      state.standings.loading = true;
      state.standings.error = null;
    },
    receiveStandings: (
      state: SportDataStateType,
      action: PayloadAction<SportDataStateType['standings']['data']>
    ) => {
      state.standings.data = action.payload;
      state.standings.loading = false;
      state.standings.error = null;
    },
    failedStandings: (state: SportDataStateType, action: PayloadAction<string>) => {
      state.standings.loading = false;
      state.standings.error = action.payload;
    },
    requestFixtures: (state: SportDataStateType) => {
      state.fixtures.loading = true;
      state.fixtures.error = null;
    },
    receiveFixtures: (state: SportDataStateType, action: PayloadAction<FixturesResponse>) => {
      state.fixtures.data = action.payload;
      state.fixtures.loading = false;
      state.fixtures.error = null;
    },
    failedFixtures: (state: SportDataStateType, action: PayloadAction<string>) => {
      state.fixtures.loading = false;
      state.fixtures.error = action.payload;
    },
    requestSchedule: (state: SportDataStateType) => {
      state.schedule.loading = true;
      state.schedule.error = null;
    },
    receiveSchedule: (
      state: SportDataStateType,
      action: PayloadAction<SportDataStateType['schedule']['data']>
    ) => {
      state.schedule.data = action.payload;
      state.schedule.loading = false;
      state.schedule.error = null;
    },
    failedSchedule: (state: SportDataStateType, action: PayloadAction<string>) => {
      state.schedule.loading = false;
      state.schedule.error = action.payload;
    },
    requestMatchData: (state: SportDataStateType) => {
      state.matchData.loading = true;
      state.matchData.error = null;
    },
    receiveMatchData: (
      state: SportDataStateType,
      action: PayloadAction<SportDataStateType['matchData']['data']>
    ) => {
      state.matchData.data = action.payload;
      state.matchData.loading = false;
    },
    failedMatchData: (state: SportDataStateType, action: PayloadAction<string>) => {
      state.matchData.loading = false;
      state.matchData.error = action.payload;
    },
    requestTournamentCalendar: (state: SportDataStateType) => {
      state.tournamentCalendar.loading = true;
      state.tournamentCalendar.error = null;
    },
    receiveTournamentCalendar: (
      state: SportDataStateType,
      action: PayloadAction<SportDataStateType['tournamentCalendar']['data']>
    ) => {
      state.tournamentCalendar.data = action.payload;
      state.tournamentCalendar.loading = false;
      state.tournamentCalendar.error = null;
    },
    failedTournamentCalendar: (state: SportDataStateType, action: PayloadAction<string>) => {
      state.tournamentCalendar.loading = false;
      state.tournamentCalendar.error = action.payload;
    },
  },
});

const actions = sportDataSlice.actions;
const sportDataReducer = sportDataSlice.reducer;

export { actions, sportDataReducer };
