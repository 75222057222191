import startOfWeek from 'date-fns/startOfWeek';
import addDays from 'date-fns/addDays';
import nl from 'date-fns/locale/nl';

function getStartDate(date: Date) {
  return startOfWeek(date, { locale: nl });
}

function getCalendarWeek(date: Date) {
  const startOfWeek = getStartDate(date);
  return Array.from({ length: 7 }, (_, i) => addDays(startOfWeek, i));
}

export { getStartDate, getCalendarWeek };
