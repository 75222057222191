import { Id } from 'react-toastify';

export type NotificationStateType = {
  toastId: Id | null;
};

export const NOTIFICATION = 'notification';
export type NOTIFICATION = typeof NOTIFICATION;

export const NOTIFY = `${NOTIFICATION}/notify`;
export type NOTIFY = typeof NOTIFY;

export const UPDATE_NOTIFY = `${NOTIFICATION}/updateNotify`;
export type UPDATE_NOTIFY = typeof UPDATE_NOTIFY;

export const SET_TOAST_ID = `${NOTIFICATION}/setToastId`;
export type SET_TOAST_ID = typeof SET_TOAST_ID;

export const RESET_TOAST_ID = `${NOTIFICATION}/resetToastId`;
export type RESET_TOAST_ID = typeof RESET_TOAST_ID;
