import { forwardRef } from 'react';
import { OptionProps, SelectProps } from './types';

import styles from './Select.module.scss';

const Option = ({ value, label, ...props }: OptionProps) => {
  return (
    <option className={styles.Option} value={value} {...props}>
      {label ?? value}
    </option>
  );
};

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ value, options, onChange, className, ...selectProps }, ref) => {
    return (
      <select
        ref={ref}
        className={styles.Select}
        value={value}
        onChange={onChange}
        {...selectProps}
      >
        {options?.map((option) => (
          <Option key={option.value} {...option} />
        ))}
      </select>
    );
  }
);

export default Select;
