import axios, { type AxiosResponse, type AxiosError, type Method } from 'axios';
import { ApiError } from './errors';
import { HTTP_REQUEST_HEADER_CSRF_PROTECTION } from 'src/utils';

const getUrl = (path: string) => {
  try {
    new window.URL(path);
    return decodeURIComponent(path);
  } catch {
    return `${import.meta.env.VITE_SERVICE_AUTH_API_URL}${decodeURIComponent(path)}`;
  }
};

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common[HTTP_REQUEST_HEADER_CSRF_PROTECTION] = '1';

const executeApiCall = (method: Method, path: string, data: object | null, params: object) => {
  const endpoint = getUrl(path);

  return axios(endpoint, {
    method,
    data,
    paramsSerializer: (params) =>
      Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&'),
    withCredentials: true,
    ...params,
  })
    .then(
      (response: AxiosResponse): Pick<AxiosResponse, 'data' | 'status'> => ({
        data: response?.data,
        status: response?.status,
      })
    )
    .catch((error: AxiosError) => ApiError.handleError(error));
};

export default {
  delete: (path: string, data: object = {}, params: object = {}) =>
    executeApiCall('DELETE', path, data, params),
  get: (path: string, params: object = {}) => executeApiCall('GET', path, null, params),
  post: (path: string, data: object = {}, params: object = {}) =>
    executeApiCall('POST', path, data, params),
  patch: (path: string, data: object = {}, params: object = {}) =>
    executeApiCall('PATCH', path, data, params),
};
