import { call, cancel, delay, fork, put, takeLatest } from 'redux-saga/effects';
import type { CancelEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import type { Task } from 'redux-saga';
import { actions } from '../reducers/user';
import api from '../../services/authApi';
import {
  REQUEST_USER_AUTH_STATUS,
  REQUEST_USER_LOGOUT,
  type UserAuthResponseType,
} from '../reducers/user';

type UserAuthApiResponse = void | { data: UserAuthResponseType; status: number };

const refreshTokensTaskQueue: Task[] = [];

function* autoRefreshTokens(): Generator<CallEffect<void | Task> | ForkEffect<Task>, Task, never> {
  const minutes = 1;
  yield delay(minutes * 60 * 1000);
  refreshTokensTaskQueue.pop(); // take task from queue so it can not be cancelled
  return yield fork(authStatus);
}

function* authStatus(): Generator<
  CallEffect | CallEffect<UserAuthApiResponse> | CancelEffect | PutEffect | ForkEffect<Task>,
  Task,
  UserAuthApiResponse | Task
> {
  // Needs to be union to match generator next types
  let cancellableTask: Task | UserAuthApiResponse;

  try {
    yield delay(500);

    // Prevent browser caching of this API call (see headers) to mitigate session issues after logout
    const response = yield call(api.get, `/user/me`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    const apiResponse = response as UserAuthApiResponse;

    if (apiResponse?.status === 401) {
      throw new Error('Token expired.');
    }

    if (apiResponse?.data) {
      yield put(actions.receiveUserAuthStatus(apiResponse?.data));
    }
  } catch (error) {
    yield put(
      actions.failedUserAuthStatus((error as Error)?.message as string) || 'Something went wrong'
    );
  } finally {
    const pendingRefreshTokensTask: Task | undefined = refreshTokensTaskQueue.pop();

    if (pendingRefreshTokensTask) {
      yield cancel(pendingRefreshTokensTask);
    }

    cancellableTask = yield fork(autoRefreshTokens);
    refreshTokensTaskQueue.push(cancellableTask as Task); // Asserting Task
  }

  return cancellableTask as Task; // Asserting Task
}

function* logout() {
  try {
    const { data } = yield call(api.post, '/user/logout', {});
    yield put(actions.receiveUserLogout());

    const logoutRedirectUrl =
      data.redirect ?? `${import.meta.env.VITE_SERVICE_AUTH_FRONTEND_URL}/login`;

    window.location.replace(logoutRedirectUrl);
  } catch (error) {
    yield put(actions.failedUserLogout());
  }
}

export default function* root() {
  yield takeLatest(REQUEST_USER_AUTH_STATUS, authStatus);
  yield takeLatest(REQUEST_USER_LOGOUT, logout);
}
