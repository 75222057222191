import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

export default function NotFound() {
  useEffect(() => {
    Bugsnag.notify('404 - Page not found', (event) => {
      event.severity = 'info';
    });
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center text-center text-white gap-4">
      <strong>Oops! Page Not Found</strong>
      <div className="flex flex-col gap-1">
        <p>
          Sorry, we couldn't find the page you're looking for. It may have been moved or deleted.
        </p>
        <p>
          You can return to the{' '}
          <Link to="/rundown" className="font-bold">
            homepage
          </Link>{' '}
          or contact us if you need assistance.
        </p>
      </div>
      <p>Thank you for your patience.</p>
    </div>
  );
}
