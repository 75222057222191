import { Card, Stat2 } from 'src/types/statsperform/matchStats';

const STAT_TYPES = [
  'goals',
  'possessionPercentage',
  'totalScoringAtt',
  'ontargetScoringAtt',
  'cornerTaken',
] as const;
type StatType = (typeof STAT_TYPES)[number];

const isStatType = (type: string): type is StatType => {
  return STAT_TYPES.includes(type as StatType);
};

const getMatchStatsFromStat2 = (stats?: Stat2[]) => {
  if (!stats) return {} as { [K in StatType]: string };
  return stats.reduce<{ [K in StatType]: string }>((acc, curr) => {
    if (!isStatType(curr.type)) return acc;
    acc[curr.type as StatType] = curr.value;
    return acc;
  }, {} as { [K in StatType]: string });
};

const getCards = (cards: Card[] | undefined, contestantId: string) => {
  if (!cards) return [undefined, undefined];

  const yellowCards = cards.filter(
    (card) => card.contestantId === contestantId && card.type === 'YC'
  );
  const redCards = cards.filter((card) => card.contestantId === contestantId && card.type === 'RC');

  return [yellowCards.length, redCards.length];
};

export { getMatchStatsFromStat2, getCards };
