export type SandboxShowStateType = {
  submitting: boolean;
  error: string | null;
};
export type PreviewShowStateType = {
  submitting: boolean;
  error: string | null;
};
export type LiveShowStateType = {
  submitting: boolean;
  error: string | null;
};

export type ControlStateType = {
  sandbox: SandboxShowStateType;
  preview: PreviewShowStateType;
  live: LiveShowStateType;
};

export const CONTROL = 'control';
export type CONTROL = typeof CONTROL;

export const REQUEST_SHOW_GRAPHIC = `${CONTROL}/requestShowGraphic`;
export type REQUEST_SHOW_GRAPHIC = typeof REQUEST_SHOW_GRAPHIC;

export const REQUEST_HIDE_ALL_GRAPHICS = `${CONTROL}/requestHideAllGraphics`;
export type REQUEST_HIDE_ALL_GRAPHICS = typeof REQUEST_HIDE_ALL_GRAPHICS;

export const REQUEST_ANIMATE_IN_LIVE_GRAPHIC = `${CONTROL}/requestAnimateInLiveGraphic`;
export type REQUEST_ANIMATE_IN_LIVE_GRAPHIC = typeof REQUEST_ANIMATE_IN_LIVE_GRAPHIC;

export const REQUEST_ANIMATE_OUT_LIVE_GRAPHIC = `${CONTROL}/requestAnimateOutLiveGraphic`;
export type REQUEST_ANIMATE_OUT_LIVE_GRAPHIC = typeof REQUEST_ANIMATE_OUT_LIVE_GRAPHIC;

export const REQUEST_UPDATE_LIVE_GRAPHIC = `${CONTROL}/requestUpdateLiveGraphic`;
export type REQUEST_UPDATE_LIVE_GRAPHIC = typeof REQUEST_UPDATE_LIVE_GRAPHIC;

export const REQUEST_ANIMATE_IN_PREVIEW_GRAPHIC = `${CONTROL}/requestAnimateInPreviewGraphic`;
export type REQUEST_ANIMATE_IN_PREVIEW_GRAPHIC = typeof REQUEST_ANIMATE_IN_PREVIEW_GRAPHIC;

export const REQUEST_ANIMATE_OUT_PREVIEW_GRAPHIC = `${CONTROL}/requestAnimateOutPreviewGraphic`;
export type REQUEST_ANIMATE_OUT_PREVIEW_GRAPHIC = typeof REQUEST_ANIMATE_OUT_PREVIEW_GRAPHIC;

export const REQUEST_UPDATE_PREVIEW_GRAPHIC = `${CONTROL}/requestUpdatePreviewGraphic`;
export type REQUEST_UPDATE_PREVIEW_GRAPHIC = typeof REQUEST_UPDATE_PREVIEW_GRAPHIC;
