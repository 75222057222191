import ShortName from './ShortName';

type IProps = {
  shortName: string;
  logoUrl?: string;
  handleChangeShortName: (name: string) => void;
};

const ExtendedContestant = ({ shortName, logoUrl, handleChangeShortName }: IProps) => (
  <div className="flex flex-col w-full max-w-[100px] gap-2">
    <div className="flex justify-center align-center basis-[min-content] aspect-square h-full p-2 bg-elevated-2">
      <img className="object-contain" src={logoUrl} />
    </div>
    <ShortName shortName={shortName} handleChangeShortName={handleChangeShortName} />
  </div>
);

export default ExtendedContestant;
