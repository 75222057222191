export type PlaylistType = Playlist;
export type PlaylistsType = Playlist[];

export type PlaylistResponseType = PlaylistType;
export type PlaylistsResponseType = PlaylistsType;

export type PlaylistCreateStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type PlaylistUpdateStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type PlaylistDeleteStateType = {
  data: null;
  deleting: boolean;
  error: string | null;
};

export type PlaylistGetStateType = {
  data: Playlist | null;
  loading: boolean;
  error: string | null;
};

export type PlaylistGetAllStateType = {
  data: Playlist[] | null;
  selectedId: string | null;
  loading: boolean;
  error: string | null;
};

export type PlaylistDuplicateStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type PlaylistStateType = {
  create: PlaylistCreateStateType;
  update: PlaylistUpdateStateType;
  delete: PlaylistDeleteStateType;
  get: PlaylistGetStateType;
  getAll: PlaylistGetAllStateType;
  duplicate: PlaylistDuplicateStateType;
};

export const PLAYLIST = 'playlist';
export type PLAYLIST = typeof PLAYLIST;

export const REQUEST_SET_SELECTED_PLAYLIST = `${PLAYLIST}/requestSetSelectedPlaylist`;
export type REQUEST_SET_SELECTED_PLAYLIST = typeof REQUEST_SET_SELECTED_PLAYLIST;

export const REQUEST_CREATE_PLAYLIST = `${PLAYLIST}/requestCreatePlaylist`;
export type REQUEST_CREATE_PLAYLIST = typeof REQUEST_CREATE_PLAYLIST;

export const REQUEST_DELETE_PLAYLIST = `${PLAYLIST}/requestDeletePlaylist`;
export type REQUEST_DELETE_PLAYLIST = typeof REQUEST_DELETE_PLAYLIST;

export const REQUEST_DUPLICATE_PLAYLIST = `${PLAYLIST}/requestDuplicatePlaylist`;
export type REQUEST_DUPLICATE_PLAYLIST = typeof REQUEST_DUPLICATE_PLAYLIST;

export const REQUEST_UPDATE_PLAYLIST = `${PLAYLIST}/requestUpdatePlaylist`;
export type REQUEST_UPDATE_PLAYLIST = typeof REQUEST_UPDATE_PLAYLIST;

export const REQUEST_GET_PLAYLIST_BY_ID = `${PLAYLIST}/requestGetPlaylistById`;
export type REQUEST_GET_PLAYLIST_BY_ID = typeof REQUEST_GET_PLAYLIST_BY_ID;

export const REQUEST_TOGGLE_AUTO_PLAY_PLAYLIST = `${PLAYLIST}/requestToggleAutoPlayPlaylist`;
export type REQUEST_TOGGLE_AUTO_PLAY_PLAYLIST = typeof REQUEST_TOGGLE_AUTO_PLAY_PLAYLIST;

export const REQUEST_START_AUTO_PLAY_PLAYLIST = `${PLAYLIST}/requestStartAutoPlayPlaylist`;
export type REQUEST_START_AUTO_PLAY_PLAYLIST = typeof REQUEST_START_AUTO_PLAY_PLAYLIST;

export const REQUEST_STOP_AUTO_PLAY_PLAYLIST = `${PLAYLIST}/requestStopAutoPlayPlaylist`;
export type REQUEST_STOP_AUTO_PLAY_PLAYLIST = typeof REQUEST_STOP_AUTO_PLAY_PLAYLIST;

export const REQUEST_REORDER_PLAYLISTS = `${PLAYLIST}/requestReorderPlaylists`;
export type REQUEST_REORDER_PLAYLISTS = typeof REQUEST_REORDER_PLAYLISTS;

export const REQUEST_INCREASE_PLAYLIST_GRAPHICS_COUNT = `${PLAYLIST}/requestIncreasePlaylistGraphicsCount`;
export type REQUEST_INCREASE_PLAYLIST_GRAPHICS_COUNT =
  typeof REQUEST_INCREASE_PLAYLIST_GRAPHICS_COUNT;

export const REQUEST_DECREASE_PLAYLIST_GRAPHICS_COUNT = `${PLAYLIST}/requestDecreasePlaylistGraphicsCount`;
export type REQUEST_DECREASE_PLAYLIST_GRAPHICS_COUNT =
  typeof REQUEST_DECREASE_PLAYLIST_GRAPHICS_COUNT;

export const REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID = `${PLAYLIST}/requestGetAllPlaylistsByRundownId`;
export type REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID =
  typeof REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID;
