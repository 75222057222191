import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from 'src/redux/reducers';
import { useSearchParams } from 'react-router-dom';

import ShortKeysDialog from '../ShortKeysDialog';
import RundownOutputDialog from 'src/components/RundownOutputDialog';
import { Icon, Button, Popover, Text } from '@southfields-digital/mpxlive-components';

import styles from './RundownSettings.module.scss';

const LAYOUT_PARAM = 'layout';
export default function RundownSettings() {
  const { data: rundown } = useSelector((state: StateType) => state.rundown.detail);
  const [showRundownOutputs, setShowRundownOutputs] = useState(false);
  const [showShortKeys, setShowShortKeys] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const layout = searchParams.get(LAYOUT_PARAM);

  const handleToggleLayout = () => {
    if (!layout) {
      searchParams.set(LAYOUT_PARAM, 'simplified');
    } else {
      searchParams.delete(LAYOUT_PARAM);
    }

    setSearchParams(searchParams);
  };

  const handleToggleMatchControlLayout = () => {
    if (!layout) {
      searchParams.set(LAYOUT_PARAM, 'matchcontrol');
    } else {
      searchParams.delete(LAYOUT_PARAM);
    }

    setSearchParams(searchParams);
  };

  return (
    <div className={styles.Container}>
      <Popover className={styles.Popover} position="right">
        <Popover.Button className={styles.PopoverButton}>
          <Icon icon="cog" weight="regular" size={24}></Icon>
        </Popover.Button>
        <Popover.Panel className={styles.Panel}>
          {({ close }) => (
            <ul className={styles.Content}>
              <li>
                <Button
                  as="a"
                  to={`/rundowns/edit/${rundown?.id}`}
                  iconProps={{ size: 24, icon: 'exit-link', weight: 'regular' }}
                  variant="tertiary"
                  className={styles.Button}
                >
                  <Text as="span">Edit rundown</Text>
                </Button>
              </li>
              <li>
                <Button
                  as="button"
                  iconProps={{ size: 24, icon: 'browser', weight: 'regular' }}
                  variant="tertiary"
                  onClick={() => {
                    setShowRundownOutputs(true);
                    close();
                  }}
                  className={styles.Button}
                >
                  <Text as="span">Configure outputs</Text>
                </Button>
              </li>
              {layout ? (
                <li>
                  <Button
                    as="button"
                    iconProps={{ size: 24, icon: 'repeat', weight: 'regular' }}
                    variant="tertiary"
                    onClick={() => {
                      handleToggleMatchControlLayout();
                      close();
                    }}
                    className={styles.Button}
                  >
                    <Text as="span">{layout ? 'Default layout' : 'Match control layout'}</Text>
                  </Button>
                </li>
              ) : (
                <>
                  <li>
                    <Button
                      as="button"
                      iconProps={{ size: 24, icon: 'repeat', weight: 'regular' }}
                      variant="tertiary"
                      onClick={() => {
                        handleToggleMatchControlLayout();
                        close();
                      }}
                      className={styles.Button}
                    >
                      <Text as="span">{layout ? 'Default layout' : 'Match control layout'}</Text>
                    </Button>
                  </li>
                  <li>
                    <Button
                      as="button"
                      iconProps={{ size: 24, icon: 'repeat', weight: 'regular' }}
                      variant="tertiary"
                      onClick={() => {
                        handleToggleLayout();
                        close();
                      }}
                      className={styles.Button}
                    >
                      <Text as="span">{layout ? 'Default layout' : 'Simplified layout'}</Text>
                    </Button>
                  </li>
                </>
              )}

              <li>
                <Button
                  as="button"
                  iconProps={{ size: 24, icon: 'info', weight: 'regular' }}
                  variant="tertiary"
                  onClick={() => {
                    setShowShortKeys(true);
                    close();
                  }}
                  className={styles.Button}
                >
                  <Text as="span">View shortkeys</Text>
                </Button>
              </li>
            </ul>
          )}
        </Popover.Panel>
      </Popover>
      <RundownOutputDialog show={showRundownOutputs} onClose={() => setShowRundownOutputs(false)} />
      <ShortKeysDialog isOpen={showShortKeys} onClose={() => setShowShortKeys(false)} />
    </div>
  );
}
