import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';

type Props = {
  points: number;
  handleAddPoint: () => void;
  handleRemovePoint: () => void;
};

const Points = ({ points, handleAddPoint, handleRemovePoint }: Props) => (
  <div className="flex flex-row gap-2 items-center">
    <MatchControlButton variant="icon" onClick={handleRemovePoint}>
      <MatchControlIcon iconProps={{ icon: 'minus' }} />
    </MatchControlButton>
    {points || '0'}
    <MatchControlButton variant="icon" onClick={handleAddPoint}>
      <MatchControlIcon iconProps={{ icon: 'plus' }} />
    </MatchControlButton>
  </div>
);

export default Points;
