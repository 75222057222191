import { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { getFormattedTime } from '../utils';
import type { MatchControlVariant, Precision } from '../types';

import MatchControlIcon from './MatchControlIcon';
import MatchControlButton from './MatchControlButton';
import { Text } from '@southfields-digital/mpxlive-components';

import styles from '../MatchControl.module.scss';

type Props = {
  time: number;
  shotClockTime?: number;
  extraTime?: number;
  showExtraTime?: boolean;
  showControls?: boolean;
  disabled?: boolean;
  precision?: Precision;
  handleChangeTime?: (time: number) => void;
  variant?: MatchControlVariant;
};

const TimeIn = ({
  variant = 'default',
  time,
  extraTime,
  shotClockTime,
  showExtraTime = true,
  showControls = true,
  disabled = false,
  precision = 'minutes',
  handleChangeTime,
}: Props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [minutesInput, setMinutesInput] = useState<number | undefined>(0);
  const [secondsInput, setSecondsInput] = useState<number | undefined>(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const formatted = useMemo(() => getFormattedTime(time, precision), [time, precision]);

  const handleAddSeconds = (amount: number) => {
    if (editing) {
      if (secondsInput! + amount >= 60) {
        setMinutesInput(minutesInput! + 1);
        setSecondsInput((secondsInput! + amount) % 60);
      } else {
        setSecondsInput(secondsInput! + amount);
      }
      return;
    }
    handleChangeTime?.(amount * 1000);
  };

  const handleSubtractSeconds = (amount: number) => {
    if (editing) {
      if (secondsInput! - amount < 0) {
        setMinutesInput(minutesInput! - 1);
        setSecondsInput(60 - (amount - secondsInput!));
      } else {
        setSecondsInput(secondsInput! - amount);
      }
      return;
    }
    handleChangeTime?.(-amount * 1000);
  };

  const handleToggleEditingTime = () => {
    if (!editing) {
      setMinutesInput(Math.floor(time / 60000));
      setSecondsInput(Math.floor((time % 60000) / 1000));
    } else {
      const timeDifference = minutesInput! * 60000 + secondsInput! * 1000 - time;
      handleChangeTime?.(timeDifference);
    }
    setEditing(!editing);
  };

  useOutsideClick(containerRef, () => {
    if (editing) {
      handleToggleEditingTime();
    }
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setInput: React.Dispatch<React.SetStateAction<number | undefined>>
  ) => {
    const { value } = e.currentTarget;

    if (!value.length) {
      setInput(undefined);
      return;
    }

    setInput(+value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        handleToggleEditingTime();
        break;
      case 'Escape':
        setEditing(false);
        break;
      default:
        break;
    }
  };

  const getButtonContent = (variant: MatchControlVariant, num: number, add: boolean) => {
    const actionText = add ? 'Add' : 'Subtract';
    const operator = add ? '+' : '-';
    const timeText = `${num} ${num === 1 ? 'second' : 'seconds'}`;

    if (variant === 'default') {
      return `${operator}${num}`;
    }

    return (
      <>
        {actionText}
        <br />
        {timeText}
      </>
    );
  };

  return (
    <div className={styles.TimeInWrapper}>
      <div className={styles.TimeInHeaderWrapper}>
        {editing ? (
          <MatchControlIcon
            iconProps={{ icon: 'x', color: '#5B5D70' }}
            onClick={handleToggleEditingTime}
          />
        ) : (
          <MatchControlIcon
            iconProps={{ icon: 'pencil', color: '#5B5D70', weight: 'fill' }}
            onClick={handleToggleEditingTime}
          />
        )}

        {editing ? (
          <div ref={containerRef} className="flex w-[90px] mx-auto">
            <input
              type="number"
              value={minutesInput}
              min={0}
              onChange={(e) => handleInputChange(e, setMinutesInput)}
              onKeyDown={handleInputKeyDown}
              className="flex-1 w-[45px] bg-transparent text-white font-bold text-xl"
            />
            <input
              type="number"
              value={secondsInput}
              maxLength={2}
              onChange={(e) => handleInputChange(e, setSecondsInput)}
              onKeyDown={handleInputKeyDown}
              min={0}
              className="flex-1 w-[45px] bg-transparent text-white font-bold text-xl"
            />
          </div>
        ) : (
          <Text as="h2" color="light" className="mx-auto">
            {formatted}
          </Text>
        )}

        {shotClockTime && (
          <>
            <div className="w-[2px] h-[24px] bg-[#4D4D5B]" />
            <Text as="h2" color="light" className="mx-auto">
              {getFormattedTime(shotClockTime, 'seconds')}
            </Text>
          </>
        )}

        {showExtraTime && (
          <>
            <div className="w-[2px] h-[24px] bg-[#4D4D5B]" />
            <Text as="h2" color="light" className="mx-auto">
              + {extraTime ? extraTime / 1000 : 0}
            </Text>
          </>
        )}
      </div>
      {showControls && (
        <div className={styles.TimeInBodyWrapper}>
          <div className={classNames(styles.TimeInItemWrapper, styles.ActionsWrapper)}>
            <MatchControlButton
              variant={variant === 'extended' ? 'default' : 'icon'}
              disabled={disabled}
              onClick={() => handleSubtractSeconds(5)}
            >
              {getButtonContent(variant, 5, false)}
            </MatchControlButton>

            <MatchControlButton
              variant={variant === 'extended' ? 'default' : 'icon'}
              disabled={disabled}
              onClick={() => handleAddSeconds(5)}
            >
              {getButtonContent(variant, 5, true)}
            </MatchControlButton>
          </div>
          <div className={classNames(styles.TimeInItemWrapper, styles.ActionsWrapper)}>
            <MatchControlButton
              variant={variant === 'extended' ? 'default' : 'icon'}
              disabled={disabled}
              onClick={() => handleSubtractSeconds(1)}
            >
              {getButtonContent(variant, 1, false)}
            </MatchControlButton>
            <MatchControlButton
              variant={variant === 'extended' ? 'default' : 'icon'}
              disabled={disabled}
              onClick={() => handleAddSeconds(1)}
            >
              {getButtonContent(variant, 1, true)}
            </MatchControlButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeIn;
