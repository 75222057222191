import { intervalToDuration } from 'date-fns';
import type { Precision } from '../types';

const getInterval = (precision: Precision) => {
  switch (precision) {
    case 'minutes':
      return 60000;
    case 'seconds':
      return 1000;
    case 'milliseconds':
      return 1;
    default:
      return 1000;
  }
};

const getFormattedTime = (time: number, precision: Precision, fillZeros = true) => {
  // Endtime cannont be negative
  const duration = intervalToDuration({ start: 0, end: time < 0 ? 0 : time });
  const zeroPad = (num: number | undefined): string =>
    fillZeros ? String(num).padStart(2, '0') : String(num);

  switch (precision) {
    case 'minutes':
      const days = duration.days ? duration.days : 0;
      const hours = days
        ? days * 24 + (duration.hours ? duration.hours : 0)
        : duration.hours
        ? duration.hours
        : 0;
      const minutes = hours * 60 + (duration.minutes ? duration.minutes : 0);
      const seconds = duration.seconds ? duration.seconds : 0;

      return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
    case 'seconds':
      return zeroPad(duration.seconds);
    case 'milliseconds':
      return time;
    default:
      return `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;
  }
};

export { getInterval, getFormattedTime };
