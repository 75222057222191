import { MappedCanvas, Status } from './types';
import { tailwindPreset } from '@southfields-digital/mpxlive-components';
import { IconProps } from '@southfields-digital/mpxlive-components/build/components/Icon';

const isLive = (rundownId: string, canvases: MappedCanvas[]): boolean => {
  for (const canvas of canvases) {
    if (canvas.output && canvas.output.inUseByRundown?.id === rundownId) return true;
  }
  return false;
};

const getStatusMappedCanvases = (rundownId: string, canvases: MappedCanvas[]): Status => {
  if (isLive(rundownId, canvases)) return 'live';

  let countAvailable = 0;
  for (const canvas of canvases) {
    if (canvas.output && !canvas.output.inUseByRundown) countAvailable++;
  }

  if (countAvailable === 0) return 'error';
  if (countAvailable < canvases.length) return 'warning';
  return 'ready';
};

const getMappedCanvases = (
  canvases: RundownTemplateCanvas[] | null,
  outputs: RundownOutput[] | null
): MappedCanvas[] => {
  if (!canvases || !canvases.length) return [];

  return canvases.reduce<MappedCanvas[]>((acc, canvas) => {
    if (!canvas.graphics.length) return acc;

    const output = outputs?.find((output) => output.id && output.id === canvas.output?.id);
    if (output) {
      acc.push({ ...canvas, output });
    }

    return acc;
  }, []);
};

const getAvailableOutputs = (mappedCanvases: MappedCanvas[]) => {
  return mappedCanvases
    .filter((canvas) => canvas.output && !canvas.output.inUseByRundown)
    .map((canvas) => canvas.output);
};

const getUnavailableOutputs = (mappedCanvases: MappedCanvas[], rundownId: string) => {
  return mappedCanvases
    .filter(
      (canvas) =>
        canvas.output &&
        canvas.output.inUseByRundown &&
        canvas.output.inUseByRundown.id !== rundownId
    )
    .map((canvas) => canvas.output);
};

const getLiveOutputs = (mappedCanvases: MappedCanvas[], rundownId: string) => {
  return mappedCanvases
    .filter((canvas) => canvas.output && canvas.output.inUseByRundown?.id === rundownId)
    .map((canvas) => canvas.output);
};

const getCanvasesWithoutOutput = (mappedCanvases: MappedCanvas[]) => {
  return mappedCanvases.filter((canvas) => !canvas.output);
};

const getStatusDescription = (status: Status) => {
  switch (status) {
    case 'ready':
      return 'Ready to go live';
    case 'error':
      return 'Error';
    case 'warning':
      return 'Warning';
    case 'live':
    default:
      return 'Live';
  }
};

const getIconProps = (status: Status): IconProps | null => {
  switch (status) {
    case 'ready':
      return { icon: 'check-circle', color: tailwindPreset.theme.extend.colors.success };
    case 'error':
      return { icon: 'x-circle', color: tailwindPreset.theme.extend.colors.danger };
    case 'warning':
      return { icon: 'warning', color: tailwindPreset.theme.extend.colors.warning };
    case 'live':
    default:
      return null;
  }
};

const reduceCanvasList = (canvases: (RundownOutput | MappedCanvas | undefined)[]) => {
  return canvases.reduce((acc, canvas, index) => {
    if (!canvas) {
      return acc;
    }
    const { name } = canvas;
    const isLast = index === canvases.length - 1;

    if (isLast && canvases.length > 1) {
      return `${acc} and ${name}`;
    }

    if (index === 0) {
      return `${name}`;
    }

    return `${acc}, ${name}`;
  }, '');
};

export {
  getMappedCanvases,
  getStatusMappedCanvases,
  getAvailableOutputs,
  getUnavailableOutputs,
  getCanvasesWithoutOutput,
  getLiveOutputs,
  getStatusDescription,
  getIconProps,
  reduceCanvasList,
};
