import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router';
import { Params } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import { REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID } from 'src/redux/reducers/graphic';
import { REQUEST_GET_CANVASES, REQUEST_GET_CANVAS_BACKGROUNDS } from 'src/redux/reducers/canvas';
import {
  REQUEST_GET_RUNDOWN,
  REQUEST_CREATE_RUNDOWN,
  REQUEST_UPDATE_RUNDOWN,
} from 'src/redux/reducers/rundown';
import { REQUEST_GET_GRAPHIC_PACKAGES } from 'src/redux/reducers/graphicPackage';
import { REQUEST_GET_OUTPUTS } from 'src/redux/reducers/output';
import { REQUEST_GET_RUNDOWN_TEMPLATES } from 'src/redux/reducers/rundownTemplate';
import RundownForm from 'src/pages/Rundowns/Rundown/Form';
import { StateType } from 'src/redux/reducers';
import {
  REQUEST_GET_MATCH_CONTROL_SKELETON,
  REQUEST_GET_MATCH_CONTROL_TYPES,
} from 'src/redux/reducers/matchControls';

const mapStateToProps = (state: StateType, { params }: { params?: Readonly<Params<string>> }) => ({
  rundownId: params?.rundownId,
  matchControlTypesData: state.matchControls.categories.data || [],
  matchControlSkeletonData: state.matchControls.skeleton.data,
  canvasesData: state.canvas.collection.data || [],
  canvasBackgroundsData: state.canvas.backgroundCollection.data || [],
  graphicsData: state.graphic.allFromPackage.data || [],
  graphicPackagesData: state.graphicPackage.collection.data || [],
  outputsData: state.output.collection.data || [],
  rundownData: state.rundown.detail.data || {},
  rundownTemplatesData: state.rundownTemplate.overview.data || [],
  submitting: state.rundown.edit.submitting,
  loadingRundownData: state.rundown.detail.loading,
  loadingRundownTemplatesData: state.rundownTemplate.overview.loading,
  error: state.rundown.detail.error,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params }: { params?: Readonly<Params<string>> }
) => ({
  handleSubmit: (payload: Partial<Rundown>, navigate?: NavigateFunction) => {
    dispatch({
      type: params?.rundownId ? REQUEST_UPDATE_RUNDOWN : REQUEST_CREATE_RUNDOWN,
      payload: { data: payload, navigate: navigate },
    });
  },
  getCanvases: () => dispatch({ type: REQUEST_GET_CANVASES }),
  getCanvasBackgrounds: () => dispatch({ type: REQUEST_GET_CANVAS_BACKGROUNDS }),
  getGraphicPackages: (deprecated: boolean) =>
    dispatch({ type: REQUEST_GET_GRAPHIC_PACKAGES, payload: { deprecated } }),
  getGraphicsByGraphicPackageId: (graphicPackageId: string) =>
    dispatch({
      type: REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID,
      payload: { graphicPackageId },
    }),
  getOutputs: () => dispatch({ type: REQUEST_GET_OUTPUTS }),
  getRundown: () => dispatch({ type: REQUEST_GET_RUNDOWN, payload: { id: params?.rundownId } }),
  getRundownTemplates: () => dispatch({ type: REQUEST_GET_RUNDOWN_TEMPLATES }),
  getMatchControlTypes: () => dispatch({ type: REQUEST_GET_MATCH_CONTROL_TYPES }),
  getMatchControl: (payload: string) =>
    dispatch({ type: REQUEST_GET_MATCH_CONTROL_SKELETON, payload: { category: payload } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RundownForm);
