import { RepeaterRow, Row, Items } from './types';

export const isRepeater = (row: Row): row is RepeaterRow => row.hasOwnProperty('items');

export const createNewItemGroup = (items: Items): Items =>
  Object.values(items).reduce<Items>((acc, item) => {
    if (isRepeater(item)) {
      acc[item.id] = {
        ...item,
        items: [createNewItemGroup(item.items[0])],
      };
    } else {
      acc[item.id] = {
        ...item,
        value: '',
      };
    }
    return acc;
  }, {});
