import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router';
import { SportCategory } from 'src/types/matchControl/sport';

import { Loader, Text, AccordionForm, Button } from '@southfields-digital/mpxlive-components';
import { Section } from '@southfields-digital/mpxlive-components/build/components/AccordionForm/types';
import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import RundownTemplateForm from 'src/components/RundownTemplateForm';
import CanvasGraphicsForm from 'src/components/CanvasGraphicsForm';
import CanvasForm from 'src/components/CanvasForm';
import RundownMatchControlForm from 'src/components/RundownMatchControl/RundownMatchControl';

import styles from './RundownTemplateForm.module.scss';

type RundownTemplateFormPageProps = {
  canvasesData: Canvas[];
  canvasBackgroundsData: CanvasBackground[];
  error: string | null;
  getCanvases: () => void;
  getCanvasBackgrounds: () => void;
  getGraphicPackages: (deprecated: boolean) => void;
  getGraphicsByGraphicPackageId: (graphicPackageId: string) => void;
  getRundownTemplate: () => void;
  getMatchControlTypes: () => void;
  graphicPackagesData: GraphicPackage[];
  graphicsData: Graphic[];
  matchControlTypesData: SportCategory[];
  handleSubmit: (payload: Partial<RundownTemplate>, navigate?: NavigateFunction) => void;
  loadingRundownTemplateData?: boolean;
  rundownTemplateData: Partial<RundownTemplate>;
  rundownTemplateId?: string;
  submitting: boolean;
};

const SECTION_IDS = {
  TEMPLATE: 'template',
  GRAPHICS: 'graphics',
  BUNDLE: 'bundle',
  MATCH_CONTROL: 'matchControl',
  CANVAS: 'canvas',
} as const;

export default function RundownTemplateFormPage({
  canvasesData,
  canvasBackgroundsData,
  error,
  getCanvases,
  getCanvasBackgrounds,
  getGraphicPackages,
  getGraphicsByGraphicPackageId,
  getRundownTemplate,
  getMatchControlTypes,
  graphicPackagesData,
  graphicsData,
  matchControlTypesData,
  handleSubmit,
  loadingRundownTemplateData,
  rundownTemplateData,
  rundownTemplateId,
  submitting,
}: RundownTemplateFormPageProps) {
  const { setWithContainer } = useContext<BaseLayoutContextType | null>(
    BaseLayoutContext
  ) as BaseLayoutContextType;

  const createOrUpdate = rundownTemplateId ? 'Update' : 'Create';

  const [state, setState] = useState<Partial<RundownTemplate>>({});
  const [selectedSectionId, setSelectedSectionId] = useState<string>(SECTION_IDS.TEMPLATE);
  const [visitedSections, setVisitedSections] = useState<string[]>(
    createOrUpdate === 'Update' ? [...Object.values(SECTION_IDS)] : [SECTION_IDS.TEMPLATE]
  );

  const handleChangeSection = (id: string) => {
    if (id === selectedSectionId) {
      setSelectedSectionId('');
      return;
    }

    const section = sections.find((section) => section.id === id);
    if (section?.locked) return;

    setVisitedSections((prevVisitedSections) => {
      if (!prevVisitedSections.includes(id)) {
        return [...prevVisitedSections, id];
      }

      return prevVisitedSections;
    });
    setSelectedSectionId(id);
  };
  const handleClickNext = () => {
    const currentSectionIndex = sections.findIndex((section) => section.id === selectedSectionId);
    const nextSection = sections[currentSectionIndex + 1];
    handleChangeSection(nextSection.id);
  };

  const updateState = (update: Partial<RundownTemplate>) => {
    setState((prevState: Partial<RundownTemplate>) => ({ ...prevState, ...update }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    setWithContainer(true);
    getRundownTemplate();
    getGraphicPackages(!!rundownTemplateId);
    getMatchControlTypes();

    return () => {
      setWithContainer(false);
    };
  }, []);

  useEffect(() => {
    if (state.graphicPackageId) {
      getCanvases();
      getCanvasBackgrounds();
      getGraphicsByGraphicPackageId(state.graphicPackageId);
    }
  }, [state.graphicPackageId]);

  useEffect(() => {
    if (rundownTemplateId) {
      setState(rundownTemplateData);
    }
  }, [rundownTemplateId, rundownTemplateData]);

  if (rundownTemplateId && loadingRundownTemplateData) return <Loader centeredFullscreen />;
  if (error) return <Text as="h1">Error: {error}</Text>;
  if (rundownTemplateId && !rundownTemplateData)
    return <Text as="h1">Could not get rundown data</Text>;

  const canSubmit = state.name && state.graphicPackageId;
  const formTypeLabel = `${createOrUpdate} template`;

  const templateSectionCompleted = Boolean(
    state.name && state.graphicPackageId && visitedSections.includes(SECTION_IDS.TEMPLATE)
  );
  const graphicsSectionCompleted = Boolean(
    state.canvases && templateSectionCompleted && visitedSections.includes(SECTION_IDS.GRAPHICS)
  );
  const bundleSectionCompleted = Boolean(
    state.canvases && graphicsSectionCompleted && visitedSections.includes(SECTION_IDS.BUNDLE)
  );
  const matchControlSectionCompleted = Boolean(
    bundleSectionCompleted && visitedSections.includes(SECTION_IDS.MATCH_CONTROL)
  );
  const canvasSectionCompleted = Boolean(
    state.canvases && matchControlSectionCompleted && visitedSections.includes(SECTION_IDS.CANVAS)
  );

  const templateSectionLocked = false;
  const graphicsSectionLocked = !templateSectionCompleted;
  const bundleSectionLocked = !graphicsSectionCompleted;
  const matchControlSectionLocked = !bundleSectionCompleted;
  const canvasSectionLocked = !matchControlSectionCompleted;

  const sections: Section[] = [
    {
      id: SECTION_IDS.TEMPLATE,
      type: 'disclosure',
      title: 'Template',
      completed: templateSectionCompleted,
      locked: templateSectionLocked,
      form: (
        <RundownTemplateForm
          graphicsPackages={graphicPackagesData.map(({ id, name, version, deprecated }) => ({
            label: `${name} (${version})`,
            value: id,
            disabled: deprecated,
          }))}
          state={state}
          stateUpdated={(update) => updateState(update)}
        />
      ),
      info: (
        <>
          <Text as="h2">Template details</Text>

          <Text as="p">
            Create a template for your desired setup. When creating a rundown you can select the
            rundowns you have saved as a template.
          </Text>
        </>
      ),
    },
    {
      id: SECTION_IDS.GRAPHICS,
      type: 'disclosure',
      title: 'Graphics',
      completed: graphicsSectionCompleted,
      locked: graphicsSectionLocked,
      form: (
        <CanvasGraphicsForm
          title="Graphic"
          canvases={canvasesData}
          graphics={graphicsData.filter((g) =>
            g.types?.find((c) => ['general'].includes(c) && !g.parentId)
          )}
          state={state.canvases || []}
          stateUpdated={(update) => updateState({ canvases: update })}
        />
      ),
      info: (
        <>
          <Text as="h2">Configure your canvas</Text>
          <Text as="p">
            Select your graphics package and assign them to the appropriate canvas.
          </Text>
          <Text as="p">
            Currently your workspace supports {2} canvasses. When creating a rundown you can assign
            multiple canvasses to an output.
          </Text>
        </>
      ),
    },
    {
      id: SECTION_IDS.BUNDLE,
      type: 'disclosure',
      title: 'Bundles',
      completed: bundleSectionCompleted,
      locked: bundleSectionLocked,
      form: (
        <CanvasGraphicsForm
          title="Bundle"
          showToggleAll={false}
          canvases={canvasesData}
          state={state.canvases || []}
          stateUpdated={(canvases) => updateState({ canvases })}
          graphics={graphicsData.filter(
            (g) =>
              !g.types ||
              g.types.length == 0 ||
              g.types.find((c) => ['bundle'].includes(c)) ||
              g.parentId
          )}
        />
      ),
      info: <></>,
    },
    {
      id: SECTION_IDS.MATCH_CONTROL,
      type: 'disclosure',
      title: 'Match control',
      completed: matchControlSectionCompleted,
      locked: matchControlSectionLocked,
      form: (
        <div>
          <RundownMatchControlForm
            categories={matchControlTypesData}
            handleChangeCategory={(sport) => {
              if (sport === '') {
                updateState({ matchControlType: undefined });
                return;
              }
              updateState({ matchControlType: sport });
            }}
            matchControlState={state.matchControlType ? { sport: state.matchControlType } : null}
            matchControlStateUpdated={() => {}}
          />
          {state.matchControlType && (
            <CanvasGraphicsForm
              title="Graphic"
              canvases={canvasesData}
              state={state.canvases || []}
              stateUpdated={(canvases) => updateState({ canvases })}
              graphics={graphicsData.filter((g) => g.types?.find((c) => c === 'matchControl'))}
            />
          )}
        </div>
      ),
      info: <></>,
    },
    {
      id: SECTION_IDS.CANVAS,
      type: 'disclosure',
      title: 'Canvas',
      completed: canvasSectionCompleted,
      locked: canvasSectionLocked,
      form: (
        <CanvasForm
          state={state.canvases || []}
          canvases={canvasesData}
          canvasBackgrounds={canvasBackgroundsData}
          stateUpdated={(update) => updateState({ canvases: update })}
        />
      ),
      info: (
        <>
          <Text as="h2">Configure your canvas</Text>
          <Text as="p">
            Select a background for the canvas. Graphics will be served over the selected
            background.
          </Text>
        </>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <AccordionForm
        title={formTypeLabel}
        handleChangeSection={handleChangeSection}
        handleClickNext={handleClickNext}
        openId={selectedSectionId}
        sections={sections}
      />
      <div className="grid grid-cols-12 gap-x-6">
        <Button
          className={styles.RundownTemplateFormSubmitButton}
          disabled={submitting || !canSubmit}
          onClick={() => handleSubmit(state, navigate)}
        >
          <>
            {submitting && <Loader className={styles.Spinner} />} {formTypeLabel}
          </>
        </Button>
      </div>
    </div>
  );
}
