import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CanvasStateType, CANVAS } from './types';

const canvasInitialState: CanvasStateType = {
  collection: {
    data: null,
    loading: true, // start with loading state to avoid flash
    error: null,
  },
  backgroundCollection: {
    data: null,
    loading: true, // start with loading state to avoid flash
    error: null,
  },
};

export const canvasSlice = createSlice({
  name: CANVAS,
  initialState: canvasInitialState,
  // Apply state changes
  reducers: {
    requestGetCanvases: (canvas: CanvasStateType) => {
      canvas.collection.loading = true;
    },
    receiveGetCanvases: (canvas: CanvasStateType, { payload }: PayloadAction<Canvas[]>) => {
      canvas.collection.loading = false;
      canvas.collection.data = payload;
    },
    failedGetCanvases: (canvas: CanvasStateType, { payload: error }: PayloadAction<string>) => {
      canvas.collection.loading = false;
      canvas.collection.error = error;
    },
    requestGetCanvasBackgrounds: (canvas: CanvasStateType) => {
      canvas.backgroundCollection.loading = true;
    },
    receiveGetCanvasBackgrounds: (
      canvas: CanvasStateType,
      { payload }: PayloadAction<CanvasBackground[]>
    ) => {
      canvas.backgroundCollection.loading = false;
      canvas.backgroundCollection.data = payload;
    },
    failedGetCanvasBackgrounds: (
      canvas: CanvasStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      canvas.backgroundCollection.loading = false;
      canvas.backgroundCollection.error = error;
    },
  },
});

const actions = canvasSlice.actions;
const canvasReducer = canvasSlice.reducer;

export { actions, canvasReducer };
